/*--font-file-import--*/
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url(linear-icons.css);


/*--global-css--*/
*,*:before,*:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}
html,body{scroll-behavior:smooth;padding:0;margin:0;height:100%;-webkit-font-smoothing: antialiased;}
body{background: #F8F7FA; margin:0; line-height: 1; font-family: 'Nunito', sans-serif; font-weight: 400; color: #000;}
a, a:hover {text-decoration: none;}
a,button,.btn{-webkit-transition:all .25s ease-in-out;-moz-transition:all .25s ease-in-out;-ms-transition:all .25s ease-in-out;transition:all .25s ease-in-out;}
img{border:0;max-width:100%;vertical-align:middle;-webkit-transition:.25s ease-in-out;-moz-transition:.25s ease-in-out;-ms-transition:.25s ease-in-out;transition:.25s ease-in-out;}
hr{height:0;-webkit-box-sizing:content-box;-moz-box-sizing:content-box;box-sizing:content-box;}
button{cursor:pointer;}
button,html input[type="button"],input[type="reset"],input[type="submit"]{-webkit-appearance:button;cursor:pointer;}
button,input,select,textarea{font-size:inherit;line-height:inherit;}
input[type="checkbox"],input[type="radio"]{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;padding:0;}
input[type="search"]{-webkit-appearance:textfield;}
input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration{-webkit-appearance:none;}
:focus,.btn:active:focus,.btn:focus,a:active,a:focus,button:focus,input:focus,textarea:focus{outline:0;}
input, select {transition: 0.25s all ease;}
.btn:focus {box-shadow: inherit;}
textarea{-webkit-appearance:none;overflow:auto;}
h1,h2,h3,h4,h5,h6,p,pre{margin:0;font-family: 'Nunito', sans-serif;}
ul, ol {list-style-type:none;margin:0;padding:0;}
article,aside,figcaption,figure,footer,header,nav,section{display:block;}
b, strong {font-weight: bold;}

.bg-light {background: #F8F7FA !important;}

.t-black {color: #000 !important;}
.t-black2 {color: #1e1e1e !important;}
.t-grey {color: #c5c5c5 !important;}
.t-grey2 {color: #8e8e8e !important;}
.t-grey-dark {color: #656565 !important;}
.t-yellow {color: #FFAB00 !important;}
.t-red, .text-danger {color: #EC5453 !important;}
.t-primary {color: #6259CE !important;}
.t-green, text-success {color: #28C66F !important;}
.t-cyan {color: #63E2F1 !important;}
.text-red {color: #f00;}

.min-width-auto {min-width: auto !important;}
.max-width-auto {max-width: auto !important;}

.f-300 {font-weight: 300;}
.f-400 {font-weight: 400;}
.f-500 {font-weight: 500;}
.f-600 {font-weight: 600;}
.f-700, .f-bold {font-weight: 700;}

.bg-dark-blue {background: #191e38 !important;}
.bg-primary {background: #6259CE;}

.t-bg-light-primary {background: rgba(115, 103, 240, 0.25);}
.t-bg-light-green {background: rgba(40, 199, 111, 0.25);}
.t-bg-light-grey {background: rgba(168, 170, 174, 0.25);}

.btn {font-family: 'Nunito', sans-serif;border-radius: 10px;font-size: 14px;font-weight: bold;text-transform: uppercase;line-height: 1; display: inline-flex; align-items: center;justify-content: center;}
.t-primary-btn {background: #6259CE;color: #fff;padding: 11px 15px;border: 0;}
.t-primary-btn:hover {background: #191e38;color: #fff;}
.t-primary-btn-outline {border-color: #6259CE;background: transparent;color: #6259CE;padding: 11px 15px;border: 1px solid #6259CE;}
.t-primary-btn-outline:hover {border-color: #6259CE;background: #6259CE;color: #fff;}
.t-grey-btn-outline {border-color: #8e8e8e;background: #fff;color: #707070;padding: 11px 15px;border: 1px solid #8e8e8e;font-weight: 400;}
.t-grey-btn-outline:hover {border-color: #8e8e8e;background: #8e8e8e;color: #fff;}
.t-danger-btn-outline {border-color: #EC5453;background: transparent;color: #EC5453;padding: 11px 15px;border: 1px solid #EC5453;}
.t-danger-btn-outline:hover {border-color: #EC5453;background: #EC5453;color: #fff;}

.t-warning-btn-outline {border-color: #f1b44c;background: rgba(255, 161, 0, 0.1);color: #f1b44c;padding: 11px 15px;border: 1px solid #f1b44c;}

.t-white-btn {background: #fff;color: #6259CE;padding: 11px 15px;border: 0;}
.t-white-btn:hover {background: #6259CE;color: #fff;}
.t-white-btn:hover .icon, .t-white-btn:hover img, .t-grey-btn-outline:hover img, .t-primary-btn-outline:hover img {-webkit-filter: brightness(0) invert(1);-moz-filter: brightness(0) invert(1);-ms-filter: brightness(0) invert(1);filter: brightness(0) invert(1);}

.t-grey-btn-outline.white {background: #fff;}
.t-grey-btn-outline.white:hover{background: #8e8e8e;}

.btn-block {display: block !important;}

.btn-link {font-family: 'Nunito', sans-serif;font-weight: bold;font-size: 16px;line-height: 22px;text-align: right;color: #8e8e8e; padding: 12px 15px; text-decoration: none !important; display: inline-flex;}
.btn-link:hover {color: #1e1e1e;}

.t-grey-btn {background: #dfe2e6; color: #000; font-weight: 400;}
.t-grey-btn:hover {background: #8e8e8e;color: #fff;}

.t-primary-light {color: #6259CE;border-radius: 10px;background: #edebfd;}
.t-primary-light:hover {background: #6259CE;color: #fff;}

.text-transform-none {text-transform: none !important;}

h1 {font-size: 34px; font-weight: bold;}
h2 {font-size: 30px; font-weight: 600;}
h3 {font-size: 24px; font-weight: 600;}
h4 {font-size: 20px; font-weight: 600;}
h5 {font-size: 18px; font-weight: 600;}
h6 {font-size: 16px; font-weight: 600;}

.form-control {display: block;width: 100%;height: auto;color: #000;font-size: 14px;line-height: 1;border-radius: 10px;background: transparent;border: 1px solid #e5e5e5;padding: 9px 16px;-webkit-transition: 0.2s all ease-in-out;-moz-transition: 0.2s all ease-in-out;-ms-transition: 0.2s all ease-in-out;transition: 0.2s all ease-in-out;min-height: 40px;}
.form-control:focus {color: inherit;outline: 0;border-color: #6259CE;box-shadow: none;}
.form-control::-webkit-input-placeholder {color: #8e8e8e;}
.form-control:-moz-placeholder {color: #8e8e8e;}
.form-control:-ms-input-placeholder {color: #8e8e8e;}
.form-control::placeholder {color: #8e8e8e;}

.form-control:disabled, .form-control[readonly] {background: #f6f6f6; border-color: #e5e5e5; color: #8e8e8e;}

.c-checkbox {position: relative;padding-left: 28px;}
.c-checkbox input[type="checkbox"] {position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 100%;height: 100%;opacity: 0;visibility: hidden;display: none;}
.c-checkbox label {margin: 0;font-size: 16px;line-height: 1;cursor: pointer;}
.c-checkbox label span {color: #1e1e1e;font-weight: bold;}
.c-checkbox label::before {content: '';position: absolute;top: 0;left: 0;display: inline-block;width: 18px;height: 18px;border: 2px solid #6259CE;border-radius: 4px;background-color: #fff;box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);-webkit-transition: 0.2s all ease-in-out;-moz-transition: 0.2s all ease-in-out;-ms-transition: 0.2s all ease-in-out;transition: 0.2s all ease-in-out;}
.c-checkbox label::after {content: '';display: inline-block;position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 18px;height: 18px;background-image: url('../images/checkmark-white.svg');background-repeat: no-repeat;background-position: center;background-size: 14px;z-index: 1;opacity: 0;-webkit-transition: 0.2s all ease-in-out;-moz-transition: 0.2s all ease-in-out;-ms-transition: 0.2s all ease-in-out;transition: 0.2s all ease-in-out;}
.c-checkbox input[type="checkbox"]:checked ~ label::before {background-color: #6259CE;border-color: #6259CE;}
.c-checkbox input[type="checkbox"]:checked ~ label::after {opacity: 1;}

.c-radiobox {position: relative;padding-left: 28px;}
.c-radiobox input[type="radio"] {position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 100%;height: 100%;opacity: 0;visibility: hidden;display: none;}
.c-radiobox label {margin: 0;font-size: 16px;line-height: 1;cursor: pointer;}
.c-radiobox label span {color: #1e1e1e;font-weight: bold;}
.c-radiobox label::before {content: '';position: absolute;top: 0;left: 0;display: inline-block;width: 18px;height: 18px;border: 2px solid #6259CE;border-radius: 45px;background-color: #fff;box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);-webkit-transition: 0.2s all ease-in-out;-moz-transition: 0.2s all ease-in-out;-ms-transition: 0.2s all ease-in-out;transition: 0.2s all ease-in-out;}
.c-radiobox label::after {content: '';display: inline-block;position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 18px;height: 18px;background-image: url('../images/checkmark-white.svg');background-repeat: no-repeat;background-position: center;background-size: 14px;z-index: 1;opacity: 0;-webkit-transition: 0.2s all ease-in-out;-moz-transition: 0.2s all ease-in-out;-ms-transition: 0.2s all ease-in-out;transition: 0.2s all ease-in-out;}
.c-radiobox input[type="radio"]:checked ~ label::before {background-color: #6259CE;border-color: #6259CE;}
.c-radiobox input[type="radio"]:checked ~ label::after {opacity: 1;}
.c-radiobox.circle-radio input[type="radio"]:checked ~ label::before {background: transparent;}
.c-radiobox.circle-radio label::after{background-image: none;width: 10px;height: 10px;background: #6259CE;border-radius: 100%;top: 4px;left: 4px;}

.custom-switch {min-height: 20px;padding-left: 0;}
.custom-switch .custom-control-label {width: 40px; height: 20px;}
.custom-switch .custom-control-label::before {width: 100%;height: 100%;border-radius: 16px;background: #b5b5b5;border: 0 !important;top:0;left: 0;cursor: pointer;}
.custom-control-input:checked~.custom-control-label::before {background: #6259CE;}
.custom-switch .custom-control-label::after {width: 16px;height: 16px;left: 2px;top: 2px;border-radius: 45px;display: inline-block;right: 0;background: #fff;cursor: pointer;}
.custom-switch .custom-control-input:checked~.custom-control-label::after {background-color: #fff;-webkit-transform: translateX(20px);transform: translateX(20px);box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);}
.custom-control-input:focus~.custom-control-label::before {box-shadow: none;}
.custom-control-input:not(:disabled):active~.custom-control-label::before {box-shadow: none; outline: none; background: #F8F7FA;}

.card-box {position: relative; border-radius: 10px;background: #fff;box-shadow: 0 2px 4px rgba(36,41,70,0.05);margin-bottom: 30px;}
.card-box-header {display: flex;flex-direction: row;align-items: center;justify-content: space-between;flex-wrap: wrap;border-radius: 0; padding: 9px 30px;}
.card-box-body {padding: 30px;}

/*---Auth-Pages---*/

.auth-content-wrapper {position: relative;background-position: top;background-size: cover;background-repeat: no-repeat;height: 100%;width: 100%;display: flex;flex-direction: column;min-height: 100vh;overflow: hidden;justify-content: center;align-items: flex-end;background-image: url('../images/bg-pwd-img.jpg');}
.auth-content-box {padding: 30px 15px;width: 100%;overflow-y: auto;}
.auth-content-box .card-box {padding: 30px 50px;display: block;width: 100%;max-width: 520px;margin: 0 auto;box-shadow: 0px 20px 24px rgba(141, 141, 141, 0.16);}
.auth-content-box .card-box .site-logo {text-align: center; margin-bottom: 26px;}
.auth-content-box .card-box .sec-main-title {margin: 40px 0 25px 0;text-align: center; font-weight: bold; font-size: 30px;}
.auth-content-box .card-box .note {color: #1e1e1e; font-size: 14px; text-align: center; line-height: 21px; margin-bottom: 40px;}

.auth-form-group .form-group {margin-bottom: 30px;}
.auth-form-group .form-group .form-control {padding: 12px 24px;}
.auth-form-group .check-fgot-group {margin-bottom: 44px;}
.auth-content-box .t-primary-btn {font-size: 16px; min-height: 45px; display: flex !important; width: 100%;}

.required-note {font-size: 14px; font-weight: bold;}
.auth-form-group .form-group + .required-note {margin-top: 0;}
.auth-form-group .form-group .required-note {margin-top: 20px;}
.form-group + .required-note {margin: 5px 0 15px 0;}
.form-group .required-note {margin: 15px 0;}
.form-group.error-field .form-control {border-color: #EC5453 !important; background: rgba(255,0,0,0.03);}
.form-group.error-field .form-control + label {color: #ec5453 !important;}
.required {color: #EC5453 !important;}

.password-group {position: relative;}
.password-group .form-control {padding-right: 46px;}
.password-group .show-pwd {position: absolute;top: 50%;right: 16px;width: 20px;height: 20px;margin-top: -10px;cursor: pointer;display: inline-block;}

.check-fgot-group .c-checkbox label {font-size: 14px;color: #1e1e1e;}
.check-fgot-group .c-checkbox label > a {color: #6259CE;}
.check-fgot-group .c-checkbox label::before {width: 18px;height: 18px;border-radius: 4px;border: 0; background: #e5e5e5;background-image: url(../images/checkmark-white.svg);background-repeat: no-repeat;background-position: center;background-size: 12px;}
.check-fgot-group .c-checkbox label::after {background-size: 4px; background-image: none;}

.forgot-pwd-link {margin: 16px 0 50px 0;display: block;font-family: 'Nunito', sans-serif;font-weight: 400;font-size: 16px;line-height: 1;text-align: center;color: #1e1e1e;}
.forgot-pwd-link:hover {color: #6259CE;}

.acc-link-name {margin: 13px 0 0 0;display: block;font-family: 'Nunito', sans-serif;font-weight: 400;font-size: 16px;line-height: 21px;text-align: center;color: #1e1e1e;}
.acc-link-name > a {color: #6259CE;}
.acc-link-name > a:hover {color: #1e1e1e;}

.seprator-title {position: relative;display: block;font-size: 12px;line-height: 14px;text-align: center;color: #1e1e1e;margin: 25px 0;z-index: 10;}
.seprator-title::after {content: '';display: block;width: 100%;height: 1px;background: #f5f5f5;position: absolute;top: 6px;left: 0;right: 0;z-index: -1;}
.seprator-title span {display: inline-block;padding: 0 31px;background-color: #fff;}

.social-login-btn {display: block;font-family: 'Nunito', sans-serif;font-weight: 400;font-size: 14px;line-height: 1;text-align: center;color: #1e1e1e;padding: 12px 20px 12px 40px;border: 1px solid #c5c5c5;border-radius: 10px;background: #fff;}
.social-login-btn:hover {background: #191e38;color: #fff;border-color: #191e38;}
.social-login-btn.google {background-image: url('../images/google-icon.svg');background-repeat: no-repeat;background-size: 15px;background-position: center left 14px;}

.reset-pwd-img {text-align: center;margin-bottom: 50px;}
/* .reset-account-block .form-group > label {color: #000;font-weight: bold;font-size: 16px;margin-bottom: 8px;} */
.reset-account-block .btn {margin: 47px 0 15px 0;}

/* .verify-account-block .card-box {padding: 54px 30px 30px;} */
.verify-email-block {text-align: center;}
.verify-email-block p {color: #000;font-size: 16px;font-weight: 400;line-height: 20px;margin-bottom: 24px;}
.verify-email-block p > span {display: block;}
.otp-form-group {margin-bottom: 32px;}
.otp-input-control {display: flex;flex-wrap: wrap;align-items: stretch;justify-content: space-around;gap: 0 15px;margin-bottom: 24px;}
.otp-input-control .form-control {text-align: center;border-radius: 100%;display: inline-flex;align-items: center;width: 66px;height: 66px;min-height: auto;padding: 4px; font-size: 30px;}
.otp-form-group .resend-otp {color: #808080;font-size: 14px;font-weight: 300;text-decoration: underline;display: flex;justify-content: flex-end;text-align: right;}
.otp-form-group .resend-otp:hover {color: #6259CE;}
.otp-input-control .form-control:focus, .otp-input-control .form-control:active {background: rgba(97, 89, 206, 0.1) !important; color: #6259CE;}

.otp-input-control .form-control.success {border-color: #62a830;}

.form-floating {position: relative;}
.form-floating  label {color: #8e8e8e;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 400;line-height: 1;position: absolute;display: inline-block;top: 12px;left: 16px;border: 1px solid transparent;padding: 0 5px;transform-origin: 0 0;margin: 0;height: auto;pointer-events: none;background: #fff;transition: opacity .1s ease-in-out,transform .1s ease-in-out;}
.form-floating .form-control::-webkit-input-placeholder {opacity: 0; visibility: hidden; display: none;}
.form-floating .form-control:-moz-placeholder {opacity: 0; visibility: hidden; display: none;}
.form-floating .form-control:-ms-input-placeholder{opacity: 0; visibility: hidden; display: none;}
.form-floating .form-control::placeholder {opacity: 0; visibility: hidden; display: none;}
.form-floating .form-control:focus~label, .form-floating .form-control:not(:placeholder-shown)~label, .form-floating .form-select~label {opacity: 1;-webkit-transform: translateY(-21px) translateX(0);-moz-transform: translateY(-21px) translateX(0);-ms-transform: translateY(-21px) translateX(0);transform: translateY(-21px) translateX(0);height: auto;}
.form-group.form-floating .required-note, .form-group.form-floating .cross-validation-error-message .required-note, .form-group.form-floating + .cross-validation-error-message .required-note {font-size: 12px;line-height: 14px;text-align: left;color: #f00;margin: 7.5px 0 0 0;font-weight: 400;padding: 0 24px;}

.form-floating.error .form-control, .form-floating.error .form-control:focus {border-color: #f00;background: rgba(255,0,0,0.03);}
.form-floating.error .form-control~label, .form-floating.error .form-control:focus~label, .form-floating.error .form-control:not(:placeholder-shown)~label, .form-floating.error .form-select~label  {color: #f00;}

.cross-validation-error-message .form-text {font-size: 12px;line-height: 14px;text-align: left;margin: 7.5px 0 0 0;font-weight: 400;padding: 0 16px;}
.cross-validation-error-message .form-text.text-danger {color: #f00;}

.form-floating.success .form-control:focus {border-color: #62a830;}
.form-floating.success .form-control:focus~label, .form-floating.success .form-control:not(:placeholder-shown)~label, .form-floating.success .form-select~label  {color: #62a830;}

.auth-content-wrapper .password-checklist ul > li::before {background-image: url('../images/icon-check-grey-bg.svg');}
.auth-content-wrapper .password-checklist ul > li.checked::before {background-image: url('../images/icon-check-green-bg.svg');}
.auth-content-wrapper .password-checklist ul > li {padding-left: 25px;}
.auth-content-wrapper .password-checklist ul > li:last-child {padding-bottom: 0;}

.auth-form-group .form-floating label {top: 15px; left: 19px;}
.auth-form-group .c-checkbox input[type="checkbox"]:checked ~ label::before {background-color: #62A830; border-color: #62A830;}

.ob-content-box .auth-form-group .form-group {margin-bottom: 16px;}
.ob-content-box .auth-form-group .form-control {padding: 10px 16px;}
.tnc-text {font-family: 'Nunito', sans-serif;font-weight: normal;font-size: 14px;line-height: 20px;color: #8e8e8e;margin: 30px 0;}
.tnc-text > a {color: #8e8e8e;text-decoration: underline;}
.tnc-text > a:hover {color: #6259CE;}
.ob-content-box .mobile-number-control .form-control {border-radius: 10px;padding-left: 85px;}
.ob-content-box .mobile-number-control .mobile-prefix {background: transparent;position: absolute;top: 0;left: 0;height: 100%;display: flex;align-items: center;justify-content: center;min-width: auto;font-size: 14px;}

.mt-32 {margin-top: 32px;}
.mb-32 {margin-bottom: 32px;}
/*-----*/
.auth-content-wrapper.register-content-wrapper {background-image: none;}
.register-content-wrapper::before {content: '';background-image: url('../images/bg-pwd-img.jpg');background-position: top;background-size: cover;background-repeat: no-repeat;display: block;position: absolute;top: 0;left: 0;right: 0;bottom: 0;height: 100%;width: 100%;opacity: 0.3;z-index: -1;}
.register-onboard-container {position: relative;padding: 30px 15px;width: 100%;overflow-y: auto;}
.register-onboard-container .card-box {background: #fff;border-radius: 20px;box-shadow: none;overflow: hidden;max-width: 1416px;margin: 0 auto;}
.onboarding-step-block {display: flex;flex-wrap: wrap;flex-direction: row;align-items: stretch;height: 100%;}
.onboarding-step-block .steps-left {background: #191e38;border-radius: 0 20px 20px 0;width: 406px;padding: 43px 55px 43px 59px;}
.onboarding-step-block .content-right {background: #fff;width: calc(100% - 406px);padding: 122px 103px 80px 52px;}
.onboarding-step-block .steps-left .main-logo {margin-bottom: 59px;}
.onboarding-step-block .steps-left .main-logo > a {display: block;max-width: 232px;}
.onboarding-step-block .steps-list ul {counter-reset: my-sec-counter;}
.onboarding-step-block .steps-list ul > li {padding: 23px 30px;position: relative;background: transparent;border-radius: 10px;margin-bottom: 37px;}
.onboarding-step-block .steps-list ul > li:last-child {margin-bottom: 0;}
.onboarding-step-block .steps-list ul > li:after {content: '';display: block;background: #fff;width: 1px;height: 100%;position: absolute;left: 42px;top: auto;margin-top: 6px;z-index: 10;}
.onboarding-step-block .steps-list ul > li:last-child::after {display: none;}
.onboarding-step-block .steps-list ul > li.active {color: #fff;background: #3c425c;}
.onboarding-step-block .steps-list ul > li > span {color: #FFFFFF;font-family: 'Nunito', sans-serif;font-size: 16px;font-weight: 300;display: flex;flex-direction: column;align-items: center;justify-content: center;width: 25px;height: 25px;z-index: 1;border-radius: 45px;position: absolute;top: 50%;margin-top: -12.5px;background: transparent;}
.onboarding-step-block .steps-list ul > li.active > span {background: -webkit-linear-gradient(#c2d500 0%, #1ecad3 100%);background: -moz-linear-gradient(#c2d500 0%, #1ecad3 100%);background: -o-linear-gradient(#c2d500 0%, #1ecad3 100%);background: linear-gradient(#c2d500 0%, #1ecad3 100%);}
.onboarding-step-block .steps-list ul > li > span:before {counter-increment: my-sec-counter;content:counter(my-sec-counter);border-radius: inherit;display: flex;flex-direction: column;align-items: center;justify-content: center;text-align: center;width: 21px;height: 21px;background: transparent;z-index: -1;}
.onboarding-step-block .steps-list ul > li.active > span:before {background: #3c425c;font-weight: bold;}
.onboarding-step-block .steps-list ul > li > a {position: relative;display: block;color: #fff;font-size: 18px;font-weight: 300;line-height: 24px;padding-left: 45px;}
.onboarding-step-block .steps-list ul > li.active > a {font-weight: bold;}
.onboarding-step-block .steps-list ul > li.completed > span {background: -webkit-linear-gradient(#c2d500 0%, #1ecad3 100%);background: -moz-linear-gradient(#c2d500 0%, #1ecad3 100%);background: -o-linear-gradient(#c2d500 0%, #1ecad3 100%);background: linear-gradient(#c2d500 0%, #1ecad3 100%);}
.onboarding-step-block .steps-list ul > li.completed:after {background: -webkit-linear-gradient(#c2d500 0%, #1ecad3 100%);background: -moz-linear-gradient(#c2d500 0%, #1ecad3 100%);background: -o-linear-gradient(#c2d500 0%, #1ecad3 100%);background: linear-gradient(#c2d500 0%, #1ecad3 100%);}
.onboarding-step-block .steps-list ul > li.completed > span:before {content: '';background-image: url('../images/check-icon-black.svg');background-size: 12px;background-position: center center; background-repeat: no-repeat;width: 25px;height: 25px;}
.onboarding-step-block .steps-list ul > li.completed > a {font-weight: bold;}


.onboarding-step-block .top-text {margin-bottom: 45px;max-width: 700px;}
.onboarding-step-block .top-text h2 {color: #1e1e1e;font-family: 'Nunito', sans-serif;font-weight: bold;line-height: 41px;margin-bottom: 14px;}
.onboarding-step-block .top-text p {color: #000;font-family: 'Nunito', sans-serif;font-size: 16px;font-weight: 300;line-height: 25px;}
.ob-step-form {padding-left: 48px;}
.ob-step-form .field-row {display: flex;flex-wrap: wrap;flex-direction: row;margin-bottom: 40px;}
.ob-step-form .field-row .form-group {margin-bottom: 0;}
.ob-step-form .field-row .left-title {width: 208px;padding-right: 15px; margin: 10px 0;}
.ob-step-form .field-row .left-title h6 {color: #000;font-family: 'Nunito', sans-serif;font-size: 16px;font-weight: bold;text-align: left;}
.ob-step-form .profile-pic {margin-bottom: 62px;}
.ob-step-form .profile-pic .text {width: 50%;padding-right: 40px; position: relative;}
.ob-step-form .profile-pic .text h6 {color: #000;font-family: 'Nunito', sans-serif;font-size: 16px;line-height: 22px;font-weight: bold;text-align: left;margin-bottom: 16px;}
.ob-step-form .profile-pic .text p {color: #000;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 300;line-height: 20px;}
.ob-step-form .profile-pic .upload-view {width: 140px;}
.ob-step-form .profile-pic .upload-view .profile-picture {width: 140px;height: 140px;position: relative;background-image: url(../images/profile-avatar-img.svg);background-repeat: no-repeat;background-position: center;background-size: cover;}
.ob-step-form .profile-pic .upload-view .profile-picture > img {width: 100%;height: 100%;object-fit: cover;object-position: center;border-radius: 100%;}
.ob-step-form .profile-pic .upload-view .profile-picture .profile-edit {width: 27px;height: 27px;background-color: #28C66F;position: absolute;right: 19px;bottom: 0;background-image: url(../images/profile-edit-icon.svg);background-position: center center;background-repeat: no-repeat;border-radius: 50px;cursor: pointer;margin: 0;display: inline-flex;align-items: center;justify-content: center;}
.ob-step-form .profile-pic .upload-view .profile-picture .profile-edit.remove-img {background: #EC5453;}
.ob-step-form .profile-pic .upload-view .profile-picture .profile-edit.remove-img img {width: 13px;}
.ob-step-form .profile-pic .upload-view .profile-picture .profile-edit input[type="file"] {display: none;position: absolute;left: 0;right: 0;bottom: 0;top: 0;width: 100%;height: 100%;opacity: 0;visibility: hidden;}
.ob-step-form .profile-pic .text .btn-action-group {position: absolute;bottom: 0;right: 25px;}
.ob-step-form .profile-pic .text .btn-action-group .t-primary-btn {min-height: 40px;padding: 12px 15px; margin-left: 7px;}

.ob-step-form .profile-pic .drag-box {width: calc(50% - 13px); text-align: center;}
.ob-step-form .profile-pic .drag-box .fm-upload-block .upload-files-box {background: rgba(98, 89, 206, 0.13);border: 1px dashed #6259ce;border-radius: 10px;min-height: 212px;padding: 0 15px;}
.ob-step-form .profile-pic .drag-box .fm-upload-block h6 {font-weight: bold;}
.ob-step-form .profile-pic .drag-box .fm-upload-block h6 > span {color: #6259CE;}
.ob-step-form .profile-pic .drag-box .fm-upload-block .upload-files-box > img {width: 211px; height: 100%; object-fit: cover; object-position: top;}
.ob-step-form .profile-pic .drag-box .fm-upload-block .upload-files-box image-cropper {background: transparent;padding: 0;}
.ob-step-form .profile-pic .drag-box .fm-upload-block .upload-files-box image-cropper  img {width: 211px;height: 100%;object-fit: cover;object-position: top;}
.ob-step-form .profile-pic .drag-box .fm-upload-block .upload-files-box image-cropper .ngx-ic-overlay {outline: none !important;}

.btn.delete-btn {background: #EC5453; color: #fff; padding: 12px 14px; border: 0; min-height: 40px;}
.btn.delete-btn img {margin-right: 0;}

.ob-step-form .field-row .right-fields {width: calc(100% - 208px);}
.ob-step-form .field-row .right-fields .field-two-col {display: flex;flex-direction: row;flex-wrap: wrap;margin: 0 -5px;}
.ob-step-form .field-row .right-fields .field-two-col .form-group {padding: 0 5px;flex: 0 0 50%;max-width: 50%;}

.mobile-number-control {position: relative;z-index: 1; display: flex;}
.mobile-number-control .mobile-prefix {border-radius: 10px 0 0 10px;background: #e5e5e5;border: 0;min-width: 94px;padding: 8px 10px;}
.mobile-number-control .form-control {border-radius: 0 10px 10px 0;}

.submit-row.bottom-btnrow {margin-top: 50px;}
.submit-row.bottom-btnrow .btn {margin-left: 33px;}
.submit-row.bottom-btnrow .btn:first-child {margin-left: 0;}

.fm-upload-block {position: relative;}
.fm-upload-block .upload-files-box {position: relative;border: 1px dashed #707070;border-radius: 10px;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;margin: 0;min-height: 100px;padding: 15px;background: #FAFAFA;}
.fm-upload-block h6 {margin-bottom: 10px;color: #191919;font-size: 18px;font-weight: bold;line-height: 22px;}
.fm-upload-block .upload-files-box > p {font-size: 14px;color: #8b8b8b;}
.fm-upload-block .upload-files-box input[type="file"] {position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 100%;height: 100%;opacity: 0;visibility: hidden;}
.onboarding-step-block .drag-box.error .require-note {margin: 10px 0 22px;font-size: 14px;}
.drag-box.error .fm-upload-block .upload-files-box {background: rgba(255, 0, 0, 0.03);border-color: #f00000;}
.drag-box.error .fm-upload-block h6, .drag-box.error .fm-upload-block p {color: #f00000;}
.drag-box.error .require-note {color: #f00000; font-size: 12px; line-height: 14px; margin: 14px 20px;}
.drag-box.success .fm-upload-block .upload-files-box {border-color: #34c38f;}

.onboarding-step-block .drag-box + .uploaded-documents-list {margin: 15px 0 0 0;}
.onboarding-step-block .uploaded-documents-list > li {display: block;margin-bottom: 15px;}
.onboarding-step-block .uploaded-documents-list > li:last-child {margin-bottom: 0;}
.onboarding-step-block .uploaded-documents-list > li > .uploaded-doc-info {position: relative;padding: 15px;display: flex;flex-direction: row;flex-wrap: wrap;align-items: center;justify-content: space-between;border-radius: 10px;background: rgba(98, 89, 206, 0.19);color: #6259ce;font-size: 16px;min-height: 100px;border: 1px solid transparent;}
.onboarding-step-block .uploaded-documents-list > li > .uploaded-doc-info .file-name {width: calc(100% - 30px); padding-left: 9px;}
.onboarding-step-block .uploaded-documents-list > li > .uploaded-doc-info a {color: #191919; font-size: 14px; font-weight: 600;display: inline-flex; align-items: center;}
.onboarding-step-block .uploaded-documents-list > li > .uploaded-doc-info .file-name a > img {display: inline-flex; align-items: center; margin-right: 16px;}
.onboarding-step-block .uploaded-documents-list > li > .uploaded-doc-info a:hover {color: #00518c;}
.onboarding-step-block .uploaded-documents-list > li > .uploaded-doc-info a span {font-weight: 400; display: inline-block; margin-left: 5px;}
.onboarding-step-block .uploaded-documents-list > li > .uploaded-doc-info .file-action a img {opacity: 0.43;}

.onboarding-step-block .uploaded-documents-list > li > .uploaded-doc-info.error-upload {border-color: #e20000;background: #fafafa;}
.onboarding-step-block .uploaded-documents-list > li > .uploaded-doc-info.error-upload a {color: #e20000;}
.uploaded-documents-list .required-note {color: #e20000 !important;margin-top: 12px;font-weight: 400;}

.drag-box + .upload-doc-wrapper, .upload-doc-wrapper + .upload-doc-wrapper {margin-top: 30px;}
.upload-doc-wrapper {position: relative;border-radius: 10px;background: rgba(98, 89, 206, 0.06);padding: 11px 15px;border: 1px solid transparent;}
.upload-doc-wrapper .remove-item {position: absolute;top: 8px;right: 15px;display: inline-flex;align-items: center;justify-content: center;text-align: center;background: #fff;border-radius: 100%;width: 15px;height: 15px;z-index: 10;}
.upload-doc-wrapper .remove-item:hover {background: #6259CE;}
.upload-doc-wrapper .remove-item:hover > img {filter: brightness(0) invert(1);}
.upload-doc-wrapper .doc-info-wrap {position: relative;display: flex;align-items: center;padding-left: 8px;}
.upload-doc-wrapper .doc-info-wrap .attached-item-icon {width: 34px;margin-right: 15px;}
.upload-doc-wrapper .doc-info-wrap .doc-info {width: calc(100% - 49px);padding-right: 40px;}
.upload-doc-wrapper .doc-info-wrap .doc-info h6 {font-size: 14px;font-weight: bold;line-height: 16px;color: #191919;margin-bottom: 5px;}
.upload-doc-wrapper .doc-info-wrap .doc-info ul {display: flex;flex-wrap: wrap;}
.upload-doc-wrapper .doc-info-wrap .doc-info ul > li {padding-right: 20px;margin-right: 20px;border-right: 1px solid #8b8b8b;font-size: 12px;line-height: 14px;color: #8b8b8b;}
.upload-doc-wrapper .doc-info-wrap .doc-info ul > li:last-child {padding-right: 0;border-right: 0;margin-right: 0;}
.upload-doc-wrapper .progress-block {position: relative;margin-top: 8px;}
.upload-doc-wrapper .progress-block .progress {height: 4px;}
.upload-doc-wrapper .progress-block .progress-percent {position: absolute;right: 0;font-size: 12px;line-height: 14px;color: #000;bottom: 100%;margin-bottom: 5px;}
.upload-doc-wrapper.error {border: 1px dashed #e00;background: rgba(255, 0, 0, 0.03);}
.upload-doc-wrapper.error .remove-item:hover {background-color: #e00;}

.pwd-strength {display: flex;flex-direction: row;justify-content: flex-end;width: 100%;margin: 12px 0 0 0;}
.pwd-strength ul {position: relative;display: flex;flex-direction: row;align-items: stretch;}
.pwd-strength ul::before {content: 'Strength';color: #8b8b8b;font-family: 'Nunito', sans-serif;font-size: 12px;font-weight: 400;line-height: 1;margin-right: 10px;display: inline-flex;align-items: center;margin-top: -3px;}
.pwd-strength ul > li {width: 30px;height: 6px;border-radius: 45px;background: #eee;transition: 0.2s all ease-in-out;margin-right: 3px;display: inline-flex;align-items: center;}
.pwd-strength ul > li.fill {background: #2ad82a;}
.pwd-strength ul > li:last-child {margin-right: 0;}


.password-checklist {
  margin-top: 28px;
  position: relative;
  background: #fff;
  overflow: hidden;
}

.password-checklist h6 {
  color: #191919;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 12px;
}

.password-checklist ul > li {
  position: relative;
  color: #8b8b8b;
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: block;
  padding-bottom: 12px;
  padding-left: 20px;
}

.password-checklist ul > li.checked {
  color: #62A830;
}

.password-checklist ul > li::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 0;
  background: url('../images/check-round-icon.svg') no-repeat;
  width: 14px;
  height: 14px;
}

.password-checklist ul > li.checked::before {
  background-image: url('../images/check-round-icon-checked.svg') !important;
}

.password-checklist ul > li.not-checked {
  color: #e74c3c; /* Red color for not checked items */
}

.password-checklist ul > li.not-checked::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 0;
  background: url('../images/cross-red-new.svg') no-repeat; /* Red cross icon */
  width: 14px;
  height: 14px;
}

.password-checklist ul > li.gray {
  color: #8b8b8b;
}

.password-checklist ul > li.gray::before {
  background: url('../images/check-round-icon.svg') no-repeat;
}

.c-checkbox.c-checkbox.tnc-checkbox {padding-left: 32px;}
.c-checkbox.tnc-checkbox label {color: #191919;font-size: 16px;line-height: 25px;margin-top: -5px;}
.c-checkbox.tnc-checkbox label::before {border: 0;background: #e5e5e5;width: 16px;height: 16px;background-image: url(../images/checkmark-white.svg);background-repeat: no-repeat;background-position: center;background-size: 10px;}
.c-checkbox.tnc-checkbox label::after {width: 16px;height: 16px;background-size: 10px;}
.c-checkbox.tnc-checkbox label > a {color: #191919;font-weight: bold;}
.c-checkbox.tnc-checkbox label > a:hover {color: #6259CE;}

.auth-content-box .password-reset-done {margin: 45px 0 58px 0;}
.auth-content-box .password-reset-done .sec-main-title {margin: 25px 0;}
.auth-content-box .password-reset-done p {color: #1e1e1e;font-size: 14px;line-height: 21px;}
/*--Navbar--*/

.navbar-toggler{position:relative;border:0;padding:0;margin-left:auto;text-align:center;background:0 0;border-radius:0;flex-direction: column; margin: 0 15px; display: none;}
.navbar-toggler>span{display:block;width:25px;height:2px;margin-bottom:6px;background-color:#6259CE;border-radius:10px;transition:.2s all ease-in-out}
.navbar-toggler>span:last-child{margin-bottom:0}
.navbar-toggler.open>span:first-child{opacity:1;-webkit-transform:rotate(-45deg) translate(-6px,6px);transform:rotate(-45deg) translate(-6px,6px)}
.navbar-toggler.open>span{opacity:0;background-color: #6259CE;}
.navbar-toggler.open>span:last-child{opacity:1;-webkit-transform:rotate(45deg) translate(-5px,-6px);transform:rotate(45deg) translate(-5px,-6px)}

/*--Aside-Nav--*/

.aside-navbar {width: 311px;background-color: #191e38;position: fixed;top: 0;left: 0;bottom: 0;height: 100%;z-index: 1020;overflow-y: auto;-webkit-transition: 0.2s all ease-in-out;-moz-transition: 0.2s all ease-in-out;-ms-transition: 0.2s all ease-in-out;transition: 0.2s all ease-in-out;padding: 23px 36px 32px 36px; display: flex;flex-direction: column;justify-content: space-between;}
.aside-navbar .aside-navbar-box {position: relative;}
.aside-navbar .aside-navbar-box .close-aside {display: none;}
.aside-navbar::-webkit-scrollbar {width: 6px;border-radius: 3px;background: #EEEEEE;}
.aside-navbar::-webkit-scrollbar-track {border-radius: 3px;background: #EEEEEE;}
.aside-navbar::-webkit-scrollbar-thumb {width: 6px;border-radius: 3px;background: #8B8B8B;}
.aside-navbar-box .site-logo {margin-bottom: 45px; text-align: center;}
.aside-navbar-box .site-logo a {display: block; max-width: 200px; margin: 0 auto;}
.user-name-welcome {color: #fff;font-family: 'Nunito', sans-serif;font-size: 16px;font-weight: 400;line-height: 28px;margin-bottom: 30px; padding: 0 7px;}
.user-name-welcome h6.username {font-family: 'Nunito', sans-serif; font-weight: 700; font-size: 16px;}
.aside-navigation-list > ul > li, .bottom-nav .logout-link {position: relative;display: block;padding: 0; margin-bottom: 10px;}
.aside-navigation-list > ul > li:last-child {margin-bottom: 0;}
.aside-navigation-list > ul > li > a, .bottom-nav .logout-link > a {color: #fff;font-family: 'Nunito', sans-serif;font-size: 17px;font-weight: 400;line-height: 21px;position: relative;padding: 12px 20px;border-radius: 10px;display: flex;flex-direction: row;align-items: center;}
.aside-navigation-list > ul > li.active > a, .aside-navigation-list > ul > li:hover > a, .bottom-nav .logout-link:hover > a {font-weight: 600; background: #393e58;}
.aside-navigation-list > ul > li > a .icon, .bottom-nav .logout-link > a .icon {width: 15px;height: 15px;display: inline-flex;flex-direction: column;align-items: center;justify-content: center;margin-right: 23px;}
.aside-navigation-list > ul > li > a .icon img, .bottom-nav .logout-link > a .icon img {-webkit-filter: brightness(0) invert(1);-moz-filter: brightness(0) invert(1);-ms-filter: brightness(0) invert(1);filter: brightness(0) invert(1);}
.aside-navigation-list > ul > li.active > a .icon img, .aside-navigation-list > ul > li:hover > a .icon img, .bottom-nav .logout-link:hover > a .icon img {-webkit-filter: brightness(0) invert(1);-moz-filter: brightness(0) invert(1);-ms-filter: brightness(0) invert(1);filter: brightness(0) invert(1);}
.aside-navigation-list > ul > li > a .icon svg * {-webkit-transition: 0.2s all ease-in-out;-moz-transition: 0.2s all ease-in-out;-ms-transition: 0.2s all ease-in-out;transition: 0.2s all ease-in-out; fill: #fff;}
.aside-navigation-list > ul > li.active > a .icon svg *, .aside-navigation-list > ul > li:hover > a .icon svg * {fill: #fff;}

.bottom-nav .logout-link {margin-bottom: 0; margin-top: 50px;}
.bottom-nav .logout-link .icon {margin-right: 30px;}

/* .aside-navigation-list > ul > li.has-sublist > a::after {content: '';display: inline-flex;align-items: center;background-image: url('../images/icon-right-angle-white.svg');background-repeat: no-repeat;background-position: center right;width: 8px;height: 14px;position: absolute;right: 15px; transition: 0.3s all ease;}
.aside-navigation-list > ul > li.has-sublist > a {padding-right: 30px;}
.aside-navigation-list > ul > li.has-sublist.active > a::after {transform: rotate(90deg);} */

body.aside-open {overflow: hidden;}
body.aside-open::after {content: '';display: block;width: 100%;height: 100%;position: fixed;top: 0;left: 0;right: 0;bottom: 0;z-index: 1021;background-color: rgba(25,25,25,0.4);}

.has-sublist .sublist {margin-top: 5px;display: none;}
.has-sublist.active .sublist {display: block;}
.has-sublist .sublist > li {position: relative;margin-bottom: 5px;}
.has-sublist .sublist > li:last-child {margin-bottom: 0;}
.has-sublist .sublist > li > a {position: relative;display: block;color: #fff;font-family: 'Nunito', sans-serif;font-size: 16px;font-weight: 400;line-height: 21px;padding: 12px 15px 12px 80px;border-radius: 10px;}
.has-sublist .sublist > li > a::before {content: '';position: absolute;top: 50%;left: 59px;width: 10px;height: 10px;margin-top: -5px;border-radius: 45px;background: transparent;border: 1px solid #fff;-webkit-transition: 0.2s all ease-in-out;-moz-transition: 0.2s all ease-in-out;-ms-transition: 0.2s all ease-in-out;transition: 0.2s all ease-in-out;}
.has-sublist .sublist > li.active > a, .has-sublist .sublist > li:hover > a {color: #fff;font-weight: 400;background-color: #7367F0;}
.has-sublist .sublist > li.active > a::before, .has-sublist .sublist > li:hover > a::before {background: #fff;}
.close-aside {cursor: pointer;position: absolute;top: 15px;right: 0;color: #fff;width: 25px;height: 25px;text-align: center;border-radius: 100%;font-size: 24px;}

.help-needed {display: flex;flex-direction: row;align-items: center;flex-wrap: wrap;padding: 20px 10px 0 10px;}
.help-needed .icon {width: 30px;margin-right: 18px;}
.help-needed .content {width: calc(100% - 48px);}
.help-needed .content h5 {color: #fff;font-size: 18px;font-weight: 700;line-height: 20px;}
.help-needed .content p {color: #fff;font-size: 16px;font-weight: 300;line-height: 20px;}

/*-- Header - Page-content-wrapper--*/

.page-content-wrapper {padding: 25px 50px;margin-left: 311px;-webkit-transition: 0.2s all ease-in-out;-moz-transition: 0.2s all ease-in-out;-ms-transition: 0.2s all ease-in-out;transition: 0.2s all ease-in-out;}

.header-wrapper {position: -webkit-sticky;position: sticky;top: 0; transition: 0.25s top linear; z-index: 1030;}
.navigation-bar {position: relative;padding: 10px 32px 10px 17px;border-radius: 15px;background: #fff;box-shadow: 0px 2px 4px rgba(36, 41, 70, 0.05);z-index: 1020;margin-bottom: 50px;}
.navigation-bar .navbar-container {position: relative;display: flex;flex-flow: row wrap;align-items: center;justify-content: space-between;margin: 0 -10px;}
.navigation-bar .navbar-container .left-side {display: flex; flex-direction: row; align-items: center;}
.navigation-bar .navbar-container .left-side, .navigation-bar .navbar-container .right-side {flex: auto;padding: 0 10px;}
.navigation-bar .navbar-container .right-side {margin-left: auto;}

.head-search-box {position: relative;max-width: 557px;width: 100%;}
.head-search-box .form-control {height: 50px;border-radius: 45px;background: #f4f4f4;color: #000;border: 0;box-shadow: none;font-size: 16px;padding: 12px 75px 12px 24px;}
.head-search-box .form-control::-webkit-input-placeholder {color: #707070;}
.head-search-box .form-control:-moz-placeholder {color: #707070;}
.head-search-box .form-control:-ms-input-placeholder{color: #707070;}
.head-search-box .form-control::placeholder {color: #707070;}
.head-search-box .form-control:focus {outline: #6259CE solid 1px;}
.head-search-box .search-btn {position: absolute;top: 0;right: 0;height: 100%;border-radius: 25px;width: 67px;text-align: center;justify-content: center;}
.head-search-box .search-btn > img {margin: 0;}

.head-search-box .search-result-box {position: absolute;top: 100%;left: 0;right: 0;width: 100%;margin-top: 12px;padding: 4px;border-radius: 10px;background: #fff;box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);opacity: 0;visibility: hidden;transition: .3s all ease-in-out;z-index: 1000;}
.head-search-box .search-result-box > ul {max-height: 275px; overflow-y: auto;}
.head-search-box .search-result-box > ul > li {position: relative;display: block;border-bottom: 1px solid #f4f4f4;}
.head-search-box .search-result-box > ul > li:last-child {border-bottom: 0;}
.head-search-box .search-result-box > ul > li > a {font-family: 'Nunito', sans-serif;font-size: 16px;font-weight: 400;line-height: 20px;text-align: left;color: #000;padding: 16px;display: block;}
.head-search-box .search-result-box > ul > li > a > span {font-weight: bold; display: inline-flex;}
.head-search-box .search-result-box > ul > li > a > span::before {content: '"';}
.head-search-box .search-result-box > ul > li > a > span::after {content: '"';}
.head-search-box .search-result-box > ul > li > a:hover {background: rgba(115, 103, 240, 0.10);}
.head-search-box.active .search-result-box {opacity: 1; visibility: visible;}

.notification-list-group {display: flex;flex-direction: row;align-items: center;justify-content: flex-end;}
.notification-list-group > li {position: relative;display: inline-flex;padding: 0 20px;}
.notification-list-group > li > a {position: relative;}
.notification-badge {width: 15px;height: 15px;background: #6259CE;border: 2px solid #fefefe;position: absolute;top: 0;right: 0;border-radius: 100%;}
.notification-item.bell .notification-badge {margin: -6px -5px;}
.notification-item.email .notification-badge {margin: -6px;}
.notification-list-group .user-type {padding-right: 0;padding-left: 57px;display: flex;flex-direction: row;align-items: center;}

.notification-item > a.active {z-index: 1025;}
.notification-item > a.active > img {filter: brightness(0) invert(1);}
.notification-item > a.active .notification-badge {display: none;}
.notification-item > a.active::after {content: '';display: block;width: 44px;height: 44px;background: #6259CE;border: 2px solid #fff;position: absolute;top: -11px;left: -11px;border-radius: 45px;z-index: -1;transition: 0.3s all ease;}
.notification-item.email a.active::after {left: -9px;}

.notification-item .notify-menu {position: absolute;top: 100%;max-width: 100%;min-width: 475px;right: 0;border-radius: 10px;background: #fff;padding: 12px 22px;opacity: 0;visibility: hidden;z-index: 1022;margin: 20px 0 0 0;display: none;}
.notification-item .notify-menu.open {opacity: 1;visibility: visible;}
.notification-item .notify-menu.open::before {content: '';display: inline-block;position: absolute;top: -24px;right: 16px;width: 28px;height: 14px;border: 14px solid #fff;border-top: 14px solid transparent;border-left: 14px solid transparent;border-right: 14px solid transparent;z-index: -1;}

.notify-menu .notify-menu-head {margin: 0 -22px;border-bottom: 1px solid #e5e5e5;padding: 0 13px;position: relative;display: flex;flex-direction: row;align-items: center;justify-content: space-between;}
.notify-menu .notify-menu-head .clear > a {font-family: 'Nunito', sans-serif;font-size: 12px;font-weight: 400;line-height: 16px;color: #000;}
.notify-menu .notify-menu-head .tabs {display: flex;flex-direction: row;align-items: stretch;border-bottom: 1px solid #f1f1f1;margin-bottom: 0;}
.notify-menu .notify-menu-head .tabs > li {flex: 1;transition: 0.3s all ease;margin-bottom: -1px;}
.notify-menu .notify-menu-head .tabs > li > a {display: block;position: relative;font-family: 'Nunito', sans-serif;font-size: 16px;font-weight: 300;text-align: center;color: #8e8e8e;line-height: 19px;padding: 7px 13px;border-radius: 10px 10px 0 0;border-bottom: 2px solid transparent;transition: 0.3s all ease;text-transform: capitalize;white-space: nowrap;}
.notify-menu .notify-menu-head .tabs > li.active > a {color: #000;background: rgba(98, 89, 206, 0.05);border-bottom-color: #6259ce;transition: 0.3s all ease; font-weight: bold;}
.notify-menu .notify-menu-head .tabs > li > a .count {display: inline-flex;align-items: center;justify-content: center;color: #707070;background: rgba(229, 229, 229, 0.64);font-size: 14px;width: 22px;height: 22px;border-radius: 4px;text-align: center;margin-left: 8px;transition: 0.3s all ease-in-out;font-weight: 400;}
.notify-menu .notify-menu-head .tabs > li.active > a .count {background: #fff; color: #000; }
.notify-menu .notify-menu-head .clear {margin-top: -5px;}
.notify-menu .notify-menu-head .clear > a:hover {color: #6259CE;}
.notify-menu .tab-info .tab-content {display: none;-webkit-transition: 0.3s all ease;-moz-transition: 0.3s all ease;transition: 0.3s all ease;}
.notify-menu .tab-info .tab-content.current {display: block;margin: 26px 0 0 0;}
.body-layer::after {content: '';position: fixed;display: block;top: 0;left: 0;right: 0;bottom: 0;width: 100%;height: 100%;background: #000;border: 1px solid #707070;opacity: 0.7;transition: 0.3s all ease-in-out;z-index: 1021;}

.tab-info .tab-content {display: none;}
.tab-info .tab-content.current {display: block;}

.for-disabled:disabled { cursor: default; }

.notification-listing {max-height: 365px;overflow-y: auto;}
.notification-listing > li {position: relative;display: flex;flex-direction: row;align-items: center;flex-wrap: wrap;margin-bottom: 20px;padding-bottom: 16px;border-bottom: 1px solid #e5e5e5;}
.notification-listing > li:last-child {margin-bottom: 0;}
.notification-listing > li .image {width: 40px;height: 40px;background-color: #e5e5e5;border-radius: 100%;overflow: hidden;position: relative;z-index: 1;}
.notification-listing > li .image::after {content: '';display: inline-flex;align-items: center;justify-content: center;position: absolute;top: 50%;left: 0;right: 0;text-align: center;background: url('../images/picture-tiny.svg');background-repeat: no-repeat;background-position: center;width: 16px;height: 16px;margin: -8px auto 0 auto;z-index: -1;}
.notification-listing > li .image > img {width: 100%;height: 100%;object-fit: cover;object-position: center;}
.notification-listing > li .content {width: calc(100% - 40px);padding-left: 16px;display: flex;flex-direction: row;justify-content: space-between;align-items: flex-start;}
.notification-listing > li .content .info {padding-right: 10px;}
.notification-listing > li .content .info h6 {color: #000;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 600;line-height: 20px;margin-bottom: 2px;}
.notification-listing > li .content .info p {color: #000;font-family: 'Nunito', sans-serif;font-size: 12px;font-weight: 400;line-height: 16px;max-width: 270px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.notification-listing > li.new-notification .content .info p > a {color: #6259CE;}
.notification-listing > li .content .info .date {color: #8e8e8e;font-family: 'Nunito', sans-serif;font-weight: 300;font-size: 12px;line-height: 14px;}
.notification-listing > li .content .btn {font-size: 12px;font-weight: 400;line-height: 16px;padding: 7px 13px;border-radius: 5px; margin-top: 5px;}
.notification-listing > li .content .dt-info .dt {font-family: 'Nunito', sans-serif;font-size: 12px;font-weight: 600;line-height: 14px;color: #000; margin-bottom: 4px;}
.notification-listing > li .content .dt-info .dt:last-child {margin-bottom: 0;}

.notification-listing > li.new-notification::after {content: '';display: inline-flex;width: 12px;height: 12px;background: #34c38f;border-radius: 100%;position: absolute;right: 88px;}

#all.tab-content .notification-listing > li:not(.new-notification) .content .info h6,#all.tab-content .notification-listing > li:not(.new-notification) .content .info p, #all.tab-content .notification-listing > li:not(.new-notification) .content .info p > a {color: #8e8e8e;}
#all.tab-content .notification-listing > li:not(.new-notification) .content .dt-info .dt {color: #8e8e8e; font-weight: 400;}

.notify-menu .view-all-list {display: flex;align-items: center;justify-content: center;padding: 24px 12px 12px;}
.notify-menu .view-all-list > a {font-weight: bold;font-size: 14px;line-height: 20px;text-align: left;color: #6259ce;}

.with-timeline .info {width: 100%;padding: 0;}
.timeline-request {position: relative;}
.timeline-request ul > li {position: relative;display: flex;flex-direction: row;justify-content: space-between;align-items: center;padding-left: 17px;padding-bottom: 14px;}
.timeline-request ul > li:last-child {padding-bottom: 0;}
.timeline-request ul > li::before {content: '';display: block;width: 8px;height: 8px;background: #707070;border: 2px solid #fff;border-radius: 100%;position: absolute;top: 6px;left: 0;}
.timeline-request ul > li::after {content: '';display: block;width: 1px;height: calC(100% - 13px);background-color: #707070;position: absolute;top: 16px;left: 3px;}
.timeline-request ul > li:last-child::after {display: none;}
.timeline-request ul > li.completed::before {background-image: url('../images/icon-check-light.svg');background-repeat: no-repeat;background-position: center;background-size: 100%;background-color: #fff;border: 0;}
.timeline-request ul > li .date {color: #8e8e8e;font-family: 'Nunito', sans-serif;font-weight: 300;font-size: 12px;text-align: right;}

.user-type .content {margin-right: 22px;text-align: right;}
.user-type .content .name {color: #1e1e1e;font-family: 'Nunito', sans-serif;font-size: 18px;font-weight: bold;line-height: 22px;}
.user-type .content .acc-type {color: #656565;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 400;line-height: 1;}
.user-type .avatar-img {position: relative;width: 60px;height: 60px;border-radius: 100%;}
.user-type .avatar-img img {width: 100%;height: 100%;border-radius: 100%;object-fit: cover;object-position: center;}
.user-type .avatar-img .notification-badge {background: #28c66f;bottom: 4px;top: auto;}

.head-search-filter {display: flex;flex-direction: row;align-items: center;margin: 0 0 40px 0;}
.head-search-filter .label-title {color: #000;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: bold;line-height: 16px;margin: 0 0 10px 0;padding-right: 24px;}
.head-search-filter .filter-tag > li {display: inline-flex;margin: 0 10px 10px 0;}
.head-search-filter .filter-tag > li .filter-item {display: flex;flex-flow: row wrap;align-items: center;justify-content: space-between;border-radius: 45px;background: #e5e5e5;position: relative;padding: 9px 24px;}
.head-search-filter .filter-tag > li .filter-item .name {padding-right: 15px;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 400;line-height: 19px;color: #000;}
.head-search-filter .filter-tag > li .filter-item > a {display: inline-flex;width: 9px;height: 9px;}

/*------*/

.form-control.date-time-control {background-image: url(../images/calendar-icon-bg.svg);background-size: auto;background-position: center right -1px;background-repeat: no-repeat;text-transform: uppercase;padding-bottom: 0;padding-top: 0;min-height: 40px;}
input[type="date"].date-time-control::-webkit-calendar-picker-indicator { color: #1e1e1e; opacity: 1; display: block; background: transparent; width: 25px; height: 25px; border: 0; }
.type-time.date-time-control {background-image: url(../images/timer-icon-bg.svg);}
.type-time.date-time-control::-webkit-calendar-picker-indicator { color: #1e1e1e; opacity: 1; display: block; background: transparent; width: 25px; height: 25px; border: 0; }

.card-box-table  { position: relative; padding: 30px; border-radius: 15px; background: #fff; box-shadow: 0 2px 4px rgba(36,41,70,0.05); }
.card-box-table .card-box-header { padding: 0; margin-bottom: 30px; }
.card-box-table .card-box-header .card-title { font-weight: 700; margin-bottom: 0; padding-right: 15px;}
.card-box-table .head-search { display: flex; justify-content: flex-end; margin-bottom: 30px; }
.card-box-table .card-box-body { padding: 0;}

.t-primary-btn.t-primary-btn-small { font-size: 14px; font-weight: 700; padding: 13px 15px; }
.t-primary-btn img, .t-primary-btn-outline img, .t-grey-btn-outline img { margin-right: 10px;display: inline-flex;align-items: center;}

.custom-table .table, .table, table.mat-table {font-family: 'Nunito', sans-serif !important;}
.custom-table .table thead th { border: none; text-transform: uppercase; background-color: #F5F6F8; font-size: 16px; font-weight: 700; padding: 22px 20px; color: #000000; vertical-align: middle;}
.custom-table .table td { border-bottom: 1px solid #F6F6F6; padding: 14px 20px; font-size: 16px; font-weight: 600; color: #1E1E1E; border-top: none; vertical-align: middle; }
.custom-table .table td .name-col { display: flex; align-items: center;}
.custom-table .table td .name-col .image { margin-right: 14px; width: 40px; height: 40px; background-color: #F4F4F4; border-radius: 100%; overflow: hidden; }
.custom-table .table td .name-col .image img { width: 100%; height: 100%; object-fit: cover; object-position: center; }
.custom-table .table td a { color: #1E1E1E; }
.custom-table .table th sub, .custom-table .table td sub { bottom: 0; font-size: 8px; margin-right: 2px;}
.custom-table .table td .thumb-img { width: 68px; height: 48px; }
.custom-table .table td .thumb-img img { width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 5px; }

.custom-table .table thead th > .c-checkbox label {font-weight: 400;font-size: 14px;color: #c3c3c3;}
.custom-table .table thead th > .c-checkbox input[type="checkbox"]:checked + label {color: #000;}
.custom-table .table td > .c-checkbox {padding-left: 0; display: inline-block;}

.custom-table .table td  .commision-unit {display: inline-flex;align-items: center;justify-content: center;border-radius: 6px;background: #f8f7fa;padding: 12px 22px;}
.custom-table .table td .revenue {display: inline-flex;align-items: center;justify-content: center;border-radius: 6px;background: rgba(98, 89, 206, 0.13);padding: 12px 22px;min-width: 144px; font-weight: bold;}


.custom-table .table .error-tr td, .table .error-tr td {background: rgba(255, 0, 0, 0.03);}
.custom-table .table .warning-tr td, .table .warning-tr td {background: rgba(241, 180, 76, 0.04);}

.empty-record-td {text-align: center;padding: 35px 0 0 0;}
.empty-record-td p {color: #b5b5b5;font-size: 14px;font-weight: 400;line-height: 20px;text-align: center;margin: 10px 0 0 0;}
.empty-record-td p strong {display: block;}

.more-action-dd {position: relative;text-align: center;}
.more-action-dd .action-more-btn {position: relative;background: transparent;color: #8B8B8B;border: 0;padding: 0;margin: 0;box-shadow: none;text-align: center;display: inline-flex;align-items: center;justify-content: center;width: 35px;height: 35px;border-radius: 45px;}
.more-action-dd .action-more-btn:hover {background:rgba(98, 89, 206, 0.10);}
.more-action-dd .dropdown-menu {background: #fff;border-radius: 8px;border: 1px solid #eee;box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);margin: 0;padding: 0;min-width: 140px;}
.more-action-dd .dropdown-menu > li {display: block;}
.more-action-dd .dropdown-menu > li > a {display: block;color: #1E1E1E;font-size: 14px;font-weight: 400;line-height: 18px;padding: 15px;}
.more-action-dd .dropdown-menu > li > a:hover, .more-action-dd .dropdown-menu > li.active > a, .more-action-dd .dropdown-menu > li.active > a:focus {background: rgba(115, 103, 240, 0.1);font-weight: 700;color: #6259CE;}

.mat-column-action .more-action-dd .action-more-btn {width: auto;height: auto;background: transparent;border-radius: 0;color: #000000;vertical-align: middle;font-size: 16px;font-weight: 700;}

.select2-dropdown { border: 1px solid #e5e5e5; width: auto;}
.select2-search--dropdown { display: block; }
.select2-container--default .select2-search--dropdown .select2-search__field {border: 1px solid #e5e5e5; color: #000; font-size: 14px; line-height: 1; padding: 5px; border-radius: 5px;}
.select2-container--default .select2-search--dropdown .select2-search__field:focus {border-color: #6259CE;}
.select2-container--default .select2-selection--single { border: 1px solid #e5e5e5; border-radius: 10px; height: auto; }
.select2-container--default .select2-selection--single .select2-selection__rendered { color: #000; font-size: 14px; padding: 5px 40px 5px 16px; }
.select2-container--default .select2-selection--single .select2-selection__arrow { height: 100%; background-image: url(../images/select-down-arrow.svg); background-position: center center; background-repeat: no-repeat; width: 15px; top: 0; right: 13px; }
.select2-container--default .select2-selection--single .select2-selection__arrow b { display: none;}
.select2-results__option { padding: 10px 16px; font-size: 14px; color: #000; }
.select2-container--default .select2-results__option--highlighted[aria-selected] {background-color: #F8F7FA; color: #6259CE; font-weight: 600;}
.select2-container--default .select2-results__option[aria-selected=true] { background-color: #6259ce; color: #fff; font-weight: 600;}
ng-select2 .select2-container {width: 100% !important;}

ng-select2.multiSelect2 {width: 100% !important;}
.select2-container--default.select2-container--focus .select2-selection--multiple {border-color: #e5e5e5;}
.select2-container--default .select2-selection--multiple {border: 1px solid #e5e5e5;border-radius: 10px;}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {padding: 4px 16px 5px 16px;}
.select2-container--default .select2-selection--multiple .select2-selection__choice {background: #f8f7fa;color: #6259ce;font-weight: 600;padding: 6px;border: 0;border-radius: 10px;font-size: 14px;}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {margin-right: 6px;color: #3E3E3E;}

.sort-control.multiple-sort-dropdown { display: flex; align-items: stretch;}
.sort-control.multiple-sort-dropdown .sortby-name { font-size: 14px; margin-right: 20px; margin-top: 15px; align-items: flex-start;}
.sort-control.multiple-sort-dropdown .select2-container--default .select2-selection--single {margin: 0;}
.sort-control.multiple-sort-dropdown .select2-container--default .select2-selection--single .select2-selection__rendered { padding: 13px 25px 13px 10px; color: #1E1E1E; font-size: 14px; background-color: rgba(115,103,240,0.10); border-radius: 10px; }
.sort-control.multiple-sort-dropdown .select2-container--default .select2-selection--single .select2-selection__arrow { right: 6px; }
.sort-control.multiple-sort-dropdown .dropdown-row {display: flex;flex-wrap: wrap;}
.sort-control.multiple-sort-dropdown .dropdown-row .dropdown-col { padding-right: 10px; min-width: 110px; margin: 5px 0; }
.sort-control.multiple-sort-dropdown .dropdown-row .dropdown-col.category-col { min-width: 195px;}

.sort-control .sort-scroll {display: inline-flex;align-items: center;justify-content: center;padding-left: 10px;}

.pagination-row { display: flex; justify-content: flex-end; }
.paginations { border: 1px solid #F3F3F3; display: flex; flex-wrap: wrap; border-radius: 10px; }
.paginations li a,
.paginations li span { font-size: 14px; color: #1E1E1E; display: inline-block; min-width: 38px; text-align: center; padding: 12px 0; line-height: 14px; border-radius: 10px; }
.paginations li a:hover,
.paginations li.active a { background-color: #6259CE; color: #fff;}

.progress {height: 6px;border-radius: 3px;background: rgba(115, 103, 240, 0.1);}
.progress .progress-bar {background: #7367f0;border-radius: 3px;}
.progress.purple {background: rgba(111, 66, 193, 0.25);}
.progress.purple .progress-bar {background: #6f42c1;}
.progress.blue {background: rgba(80, 165, 241, 0.25);}
.progress.blue .progress-bar {background: #50a5f1;}
.progress.yellow {background: rgba(241, 180, 76, 0.25);}
.progress.yellow .progress-bar {background: #f1b44c;}
.progress.red {background: rgba(244, 106, 106, 0.25);}
.progress.red .progress-bar {background: #f46a6a;}
.progress.green {background: rgba(111, 66, 193, 0.25);}
.progress.green .progress-bar {background: #6f42c1;}

.card-states-box {position: relative;padding: 15px 20px;display: flex;flex-direction: column;justify-content: space-between;box-shadow: 0px 2px 6px rgba(36, 41, 70, 0.05);}
.card-states-box .states-info {display: flex;flex-flow: row;align-items: flex-end;justify-content: space-between;margin-bottom: 32px;overflow: hidden;}
.card-states-box .states-info .count {color: #000;font-size: 30px;font-weight: bold;line-height: 41px;align-items: center;}
.card-states-box .states-info .count span {color: #000;font-weight: 400;font-size: 15px;display: inline-flex;align-items: flex-end;margin-right: 5px; line-height: 1;}
.card-states-box .states-info .states-name {color: #8e8e8e;font-size: 16px;font-weight: 300;line-height: 22px;}
.card-states-box .states-progress-info {font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 400;line-height: 1;text-align: left;color: #8e8e8e;}
.card-states-box .states-progress-info span {display: inline-flex;align-items: flex-start;margin-right: 7px;}
.card-states-box .states-progress-info span.up {color: #34c38f;}
.card-states-box .states-progress-info span .icon {display: inline-flex;align-items: center;margin-right: 10px;}
.card-states-box .states-progress-info span.down {color: #f46a6a;}

.card-states-box .states-info .states-digit {width: calc(100% - 100px); padding-right: 10px;}
.card-states-box .states-info .states-graph {width: 100px;}

.card-block-box {padding: 28px 20px 20px 20px;}
.card-block-box .card-box-header {padding: 0;}
.card-block-box .title-desc p {color: #8e8e8e;font-size: 16px;line-height: 22px;}
.card-block-box .card-title {font-weight: bold;margin-bottom: 0;padding-right: 15px;}
.card-block-box .desc-info {font-size: 16px; font-weight: 400; color: #8E8E8E;}
.card-block-box .card-box-body {padding: 20px 0 0 0;}
.card-block-box .sub-title-content {font-size: 16px; color: #8E8E8E;}
.card-block-box .sub-title-content > span {font-weight: 700; color: #000;}

.sales-analytics-data ul {display: flex;flex-direction: row;margin: 5px 0 40px 0;flex-wrap: wrap;}
.sales-analytics-data ul > li {position: relative;display: flex;flex: 0 0 auto;align-items: flex-end;line-height: 30px;padding: 0 18px;}
.sales-analytics-data ul > li::after {content: '';display: block;width: 1.5px;height: 100%;position: absolute;right: 0;top: 0;bottom: 0;background: #f6f6f6;}
.sales-analytics-data ul > li:first-child {padding-left: 0;}
.sales-analytics-data ul > li:last-child {padding-right: 0;}
.sales-analytics-data ul > li:last-child::after {display: none;}
.sales-analytics-data ul > li .amount {color: #7367f0;font-size: 25px;font-weight: bold;}
.sales-analytics-data ul > li .amount span {font-size: 14px;font-weight: 400;display: inline-block;margin-right: 8px;vertical-align: middle;}
.sales-analytics-data ul > li .data-type {color: #8e8e8e;font-size: 14px;font-weight: bold;margin-left: 8px;display: inline-flex; align-items: center; vertical-align: middle;}

.sort-control {display: flex;flex-direction: row;align-items: center;}
.sort-control .sortby-name {color: #000;font-weight: bold;font-size: 16px;text-align: left;display: inline-flex;align-items: center;margin-right: 3px;white-space: nowrap;}
.sort-control .select2-container--default .select2-selection--single {border: 0; margin-left: 5px;}
.sort-control .select2-container--default .select2-selection--single .select2-selection__rendered {padding: 0 25px 0 0;border: 0;line-height: 1;color: #8e8e8e;font-size: 16px;}
.sort-control .select2-container--default .select2-selection--single .select2-selection__arrow {width: 15px;right: 0;}

.product-list-table {max-height: 565px;overflow-y: auto;}
.product-list-table .table {margin-bottom: 0;}
.product-list-table .table td {padding: 15px 20px;vertical-align: middle;border-bottom: 1px dashed #f1f1f1;font-family: 'Nunito', sans-serif;font-size: 16px;font-weight: 500;text-align: left;color: #000;border-top: 0;}
.product-list-table .table tr:first-child td {padding-top: 10px;}
.product-list-table .table tr:last-child td {padding-bottom: 0;border-bottom: 0;}
.product-list-table .table td span {color: #74788d;font-size: 12px;font-weight: 400;display: inline-block;}
.product-list-table .table .product-img {width: 44px;height: 44px;text-align: center; display: inline-flex; align-items: center; justify-content: center; overflow: hidden;}
.product-list-table .table .product-img > img {width: 100%; height: 100%; object-fit: contain;}
.product-list-table .table .product-name span {display: block;margin-top: 5px;}
.product-list-table .table .product-digit {text-align: right;}
.product-list-table .table .product-digit .sold-item {display: inline-block;padding-left: 30px;font-size: 12px;font-weight: 400;}

.report-states-info {margin-bottom: 35px;}
.reports-list-item {display: flex;flex-direction: row;align-items: center;justify-content: space-between;margin-bottom: 23px;}
.reports-list-item .left {display: flex;flex-direction: row;flex-wrap: wrap;padding-right: 15px;}

.reports-list-item .left .icon {width: 35px;height: 35px;display: flex;flex-direction: column;align-items: center;justify-content: center;border-radius: 45px;text-align: center;}
.reports-list-item .left .content {padding-left: 21px;width: calc(100% - 35px);}
.reports-list-item .left .content h6 {font-size: 16px;font-weight: 500;line-height: 1;}
.reports-list-item .left .content p {color: #74788d;font-size: 12px;font-weight: 400;margin-top: 5px;}
.reports-list-item:last-child {margin-bottom: 0;}

.earning-stat-info {display: flex;flex-direction: row;align-items: center;}
.earning-stat-info .amount {font-size: 12px;line-height: 16px;text-align: right;color: #74788d;}
.earning-stat-info .earning-graph {padding: 0 15px;}

.orders-progress-tabs .tabs {display: flex;flex-direction: row;align-items: stretch;border-bottom: 1px solid #f1f1f1;margin-bottom: 30px;}
.orders-progress-tabs .tabs > li {flex: 1;transition: 0.3s all ease;margin-bottom: -1px;}
.orders-progress-tabs .tabs > li > a {display: block;position: relative;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 400;text-align: center;color: #8e8e8e;line-height: 19px;padding: 7px 10px;border-radius: 5px 5px 0 0;border-bottom: 2px solid transparent;transition: 0.3s all ease;text-transform: uppercase;}
.orders-progress-tabs .tabs > li.active > a {color: #7367f0;font-weight: bold;background: rgba(115, 103, 240, 0.1);border-bottom-color: #7367f0;transition: 0.3s all ease;}
.orders-progress-tabs .tab-info .tab-content {display: none;-webkit-transition: 0.3s all ease;-moz-transition: 0.3s all ease;transition: 0.3s all ease;}
.orders-progress-tabs .tab-info .tab-content.current {display: block;margin: 30px 0 0 0;}

.orders-track-list .order-track-block {position: relative;border-bottom: 1px dashed #f1f1f1;padding-bottom: 16px;margin-bottom: 22px;}
.orders-track-list .order-track-block:last-child {margin-bottom: 0;}
.orders-track-list .order-track-block .order-stats-item {position: relative;padding: 0 0 0 26px;margin-bottom: 30px;}
.orders-track-list .order-track-block .order-stats-item:last-child {margin-bottom: 0;}
.orders-track-list .order-track-block .order-stats-item::before {content: '';display: block;width: 1px;height: 100%;position: absolute;left: 0;top: 24px;margin-left: 8px;background: #f1f1f1;}
.orders-track-list .order-track-block .order-stats-item:last-child::before {display: none;}
.orders-track-list .order-track-block .order-stats-item .icon {position: absolute;top: 2px;left: 0;width: 16px;height: 16px;display: flex;flex-direction: column;align-items: center;justify-content: center;}
.orders-track-list .order-track-block .order-stats-item .type {font-family: 'Nunito', sans-serif;font-weight: 400;font-size: 14px;line-height: 19px;text-align: left;color: #34c38f;text-transform: uppercase;}
.orders-track-list .order-track-block .order-stats-item:nth-of-type(even) .type {color: #6259CE;}
.orders-track-list .order-track-block .order-stats-item .info .name {color: #000;font-weight: 500;line-height: 22px;}
.orders-track-list .order-track-block .order-stats-item .info .add {color: #74788d;font-size: 12px;font-weight: 400;line-height: 22px;}

.sales-transaction-table.custom-table .table td {padding: 20px;}
.custom-table .table .c-checkbox label::before {border: 1px solid #dfe2e6;width: 14px;height: 14px;border-radius: 3px;box-shadow: none;}
.custom-table .table .c-checkbox label::after {width: 14px;height: 14px;background-size: 10px;}
.custom-table .table td .date-range-pick {margin: 0;}
.custom-table .table td .date-range-pick .range-btn {min-height: 40px; min-width: 100%;}
table strong, table b {font-weight: bold;}

.t-badge {border-radius: 5px;color: #6259CE;background: rgba(115, 103, 240, 0.1);text-align: center;font-size: 12px;font-weight: bold;display: inline-flex;align-items: center;justify-content: center;padding: 5px;min-width: 60px; text-transform: uppercase;line-height: 15px;}
.t-badge.green {color: #34c38f;background: rgba(52, 195, 143, 0.25);}
.t-badge.yellow {color: #f1b44c;background: rgba(241, 180, 76, 0.25);}
.t-badge.yellow2 {color: #ffab00;background: rgba(255, 171, 0, 0.07);}
.t-badge.gray {color: #8E8E8E;background: rgba(229, 229, 229, 1);}
.t-badge.red {color: #f00;background: rgba(234, 84, 85, 0.25);}

.t-badge.dispute { background: #F88379 !important;}
.t-badge.query { background: #f1b44c !important;}

.t-badge-o {color: #6259CE;background: transparent;text-align: center;font-size: 12px;font-weight: bold;display: inline-flex;align-items: center;justify-content: center;padding: 5px 17px;min-width: 60px;text-transform: uppercase;border-radius: 90px;border: 1px solid #6259CE;}
.t-badge-o.green {color: #34c38f; background: transparent; border-color: #34c38f;}
.t-badge-o.yellow {color: #f1b44c;background: transparent; border-color: #f1b44c;}
.t-badge-o.yellow2 {color: #ffab00;background: transparent; border-color: #ffab00;}
.t-badge-o.gray {color: #8E8E8E;background: transparent; border-color: #8E8E8E;}
.t-badge-o.red {color: #f00;background: transparent; border-color: #f00;}
.t-badge-o.skyblue {color: #63e2f1;background: transparent; border-color: #63e2f1;}

.sales-transaction-table .t-primary-btn {font-size: 10px;font-weight: bold;line-height: 1;border-radius: 45px;min-width: 111px;padding: 8px 10px;}
.sales-transaction-table .pay-card-img {width: 28px;display: inline-block;vertical-align: middle;margin-right: 13px;}
.sales-transaction-table .t-badge {min-width: 70px !important;}

.store-analytics-card .card-box {background: #7367f0;}
.store-analytics-card .card-title {color: #fff;}
.store-analytics-card .card-block-box .title-desc p {color: #fff;}
.store-analytics-card .card-block-box .card-box-body {padding: 25px 0 0 0;}

.traffic-analytics {color: #fff;}
.traffic-analytics h6 {color: #fff;font-weight: bold;line-height: 22px;}
.traffic-analytics ul {display: flex;flex-direction: row;align-content: center;flex-wrap: wrap;margin: 0 -15px;}
.traffic-analytics ul > li {display: inline-flex;align-items: center;flex: 0 0 40%;max-width: 40%;padding: 0 15px;margin: 16px 0 0 0;color: #fff;font-size: 16px;font-weight: 300;}
.traffic-analytics ul > li > span {padding: 5px;display: flex;flex-direction: column;align-items: center;justify-content: center;margin-right: 18px;border-radius: 5px;background: rgba(255, 255, 255, 0.22);min-width: 54px;min-height: 30px;}

.sales-overview .card-box-header {align-items: flex-start;}
.sales-overview .title-desc .card-title span {font-size: 15px;font-weight: 400;}
.sales-overview .card-block-box .desc-info {font-weight: bold;color: #8e8e8e;}
.sales-overview .card-block-box .card-box-body {padding: 30px 0 0 0;}

.order-visit-block {display: flex;flex-direction: row;flex-wrap: wrap;align-items: stretch;margin-bottom: 12px;}
.order-visit-block .col-block {flex: 0 0 50%;max-width: calc(50% - 1px);display: flex;flex-direction: column;align-items: flex-start;}
.order-visit-block .col-block .title {display: flex;flex-direction: row;flex-wrap: wrap;align-items: center;margin-bottom: 17px;}
.order-visit-block .col-block .title .icon {width: 32px;height: 32px;border-radius: 100%;}
.order-visit-block .col-block .title h6 {color: #8e8e8e;font-size: 16px;font-weight: 300;padding-left: 10px;width: calc(100% - 32px);}
.order-visit-block .col-block h4 {color: #000;font-size: 25px;font-weight: bold;}
.order-visit-block .col-block p {color: #74788d;font-size: 14px;font-weight: 400;}
.order-visit-block .col-block.visits-block {align-items: flex-end;}
.order-visit-block .col-block.visits-block .title {flex-direction: row-reverse;}
.order-visit-block .col-block.visits-block .title h6 {padding-left: 0;padding-right: 10px;}
.order-visit-block .seprator-block {max-width: 1px;min-height: 100%;position: relative;}
.order-visit-block .seprator-block .vs {width: 32px;height: 32px;font-size: 10px;text-align: center;color: #74788d;background: #dfe2e6;display: flex;flex-direction: column;align-items: center;justify-content: center;border-radius: 100%;border: 4px solid #fff;position: absolute;top: 50%;left: 50%;-webkit-transform: translate(-50%, -50%);-moz-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);}
.order-visit-block .seprator-block:before {content: '';position: absolute;top: 0;left: 0;right: 0;width: 1px;height: 100%;background-color: rgba(75, 70, 92, 0.12);text-align: center;}

.review-generated-graph .card-block-box .title-desc .icon {margin-bottom: 10px;}
.review-generated-graph .wave-chart {position: absolute;bottom: 0;left: 0;right: 0;}
.review-generated-graph .wave-chart img {width: 100%; object-fit: cover;}

.earning-reports-graph {display: flex;flex-direction: row;align-items: flex-end;flex-wrap: wrap; margin-top: -20px;}
.earning-reports-graph .details {width: calc(100% - 314px); padding-right: 24px; padding-bottom: 15px;}
.earning-reports-graph .details .amount {display: flex; align-items: flex-end;}
.earning-reports-graph .details .amount h2 {line-height: 1; font-weight: bold;}
.earning-reports-graph .details .amount h2 span {font-size: 15px; font-weight: 400; line-height: 20px;}
.earning-reports-graph .details .amount .t-badge {margin-left: 5px; min-width: auto; padding: 5px;}
.earning-reports-graph .details p {font-weight: 300;font-size: 16px;line-height: 20px;text-align: left;color: #8e8e8e; margin: 10px 0 0 0;}

.earning-reports-graph .chart {width: 314px; margin-top: -15px;}

.earning-report-stats {border-radius: 5px;background: #fcfcfc;padding: 28px 10px 23px;margin: 20px 0 0 0;}
.earning-report-stats-block {position: relative;display: flex;flex-direction: row;flex-wrap: wrap;margin: 0 -25px;}
.earning-report-stats-block .state-box {flex: 0 0 33.33%;max-width: 33.33%;padding: 0 25px;}
.earning-report-stats-block .state-box .state-type {display: flex;flex-direction: row;align-items: center;flex-wrap: wrap;margin-bottom: 20px;}
.earning-report-stats-block .state-box .state-type .icon {width: 32px;height: 32px;border-radius: 100%;}
.earning-report-stats-block .state-box .state-type h6 {display: block;width: calc(100% - 32px);padding-left: 10px;}
.earning-report-stats-block .state-box h3 {color: #000;font-size: 25px;line-height: 30px;font-weight: bold;margin-bottom: 16px;}
.earning-report-stats-block .state-box h3 span {font-size: 15px;font-weight: 400;}

.tracker-reports-graph {display: flex;flex-direction: row;align-items: flex-end;justify-content: space-between;margin-top: -20px; margin-bottom: 50px;}
.tracker-reports-graph .details {padding-right: 15px;}
.tracker-reports-graph .details h2 {line-height: 1; font-weight: bold;}
.tracker-reports-graph .details h2 span {font-size: 15px; font-weight: 400; line-height: 20px;}
.tracker-reports-graph .details p {font-weight: 300;font-size: 16px;line-height: 20px;text-align: left;color: #8e8e8e;}

.tracker-reports-graph .chart {margin-top: -50px;}
.tracker-report-list .reports-list-item {margin-bottom: 20px;}
.tracker-report-list .reports-list-item:last-child {margin-bottom: 0;}
.tracker-report-list .reports-list-item .icon {width: 32px; height: 32px;}
.tracker-report-list .reports-list-item .content {width: calc(100% - 32px); padding-left: 17px;}

.campaign-report-list .reports-list-item .left {align-items: center;}
.campaign-report-list .reports-list-item .icon {width: 32px; height: 32px;}
.campaign-report-list .reports-list-item .content {width: calc(100% - 32px); padding-left: 21px;}
.campaign-report-list .right .earning-stat-info {font-size: 16px; color: #000;}
.campaign-report-list .right .earning-stat-info .amount {color: #000; font-size: 16px; font-weight: 400; padding-right: 22px;}
.campaign-report-list .right .earning-stat-info .percentage {font-weight: 500; text-align: right; min-width: 47px;}
.earning-project-chart {margin-bottom: 40px;}
.earning-report-list .reports-list-item .icon {width: 32px; height: 32px;}
.earning-report-list .reports-list-item .content {width: calc(100% - 32px); padding-left: 15px;}
.earning-report-list .earning-stat-info .amount {font-weight: 500;}
.earning-report-list .reports-list-item {margin-bottom: 28px;}
.earning-report-list .reports-list-item:last-child {margin-bottom: 0;}

.earn-percentage {display: flex;width: 100%;align-items: flex-start;margin: 10px 0 0;}
.earn-percentage h3 {font-size: 25px;font-weight: bold;line-height: 28px;display: inline-flex;}
.earn-percentage h3 span {display: inline-flex;align-items: center;font-size: 12px;margin-left: 10px;}
.earn-percentage h3 span > img {display: inline-flex;width: 14px;margin-right: 6px;}
.earn-percentage h3 span.up {color: #34c38f;}
.earn-percentage h3 span.down {color: #ececec;}

.search-filter {max-width: 173px;}
.search-filter .form-control {border-radius: 4px;background: transparent;border: 1px solid #e5e5e5;padding: 7px 14px;font-size: 16px;}
.project-table .table thead th {padding: 22px 12px;}
.project-table .table thead tr th:first-child,.project-table .table tbody tr td:first-child {width: 4% !important; padding-right: 0;}
.project-table .table td {color: #000;font-weight: 400; padding: 12px;}
.project-table .c-checkbox label::before {border: 1px solid #dfe2e6;width: 14px;height: 14px;border-radius: 3px;box-shadow: none;}
.project-table .c-checkbox label::after {width: 14px;height: 14px;background-size: 10px;}
.project-table .table td .name-col .image {background: rgba(115, 103, 240, 0.1);width: 34px;height: 34px;margin: 0;}
.project-table .table td .name-col .content {width: calc(100% - 34px);padding-left: 15px;}
.project-table .table td .name-col .content h6 {font-weight: 500;}
.project-table .table td .name-col .content p {font-size: 12px; color: #74788d; font-weight: 400;}
.name-avatar-list {display: flex;flex-direction: row;align-items: center;flex-wrap: wrap;}
.name-avatar-list p {padding-right: 35px;}
.avatar-list {display: inline-flex;flex-direction: row;}
.avatar-list > li {position: relative;width: 34px;height: 34px;white-space: nowrap;border-radius: 100%;border: 2px solid #fff;display: inline-flex;-webkit-transition: all .25s ease;-moz-transition: all .25s ease;-ms-transition: all .25s ease;transition: all .25s ease;cursor: pointer;}
.avatar-list > li img {width: 100%;height: 100%;border-radius: 100%;object-fit: cover;object-position: center;}
.avatar-list li+li {margin-left: -16px;}
.avatar-list > li:hover {z-index: 999;-webkit-transform: translateY(-4px) scale(1.02);-moz-transform: translateY(-4px) scale(1.02);-ms-transform: translateY(-4px) scale(1.02);transform: translateY(-4px) scale(1.02);box-shadow: 0 14px 24px rgba(62,57,107,.2);}
.project-table .progress {max-width: 55px; margin: 0 auto;}

.best-seller-box {min-height: 200px;display: flex;flex-direction: column;justify-content: space-between;}
.best-seller-box .card-title {font-size: 26px;}
.best-seller-box .total-best-sell {display: flex;flex-direction: row;align-items: center;justify-content: space-between;}
.total-best-sell .amount {font-family: 'Nunito', sans-serif;font-weight: bold;font-size: 25px;color: #7367f0;padding-right: 10px;}
.total-best-sell .amount span {font-size: 15px;}
.best-seller-box .t-primary-btn {padding: 12px 15px;min-width: 168px;}

.crm-statistics .card-block-box .card-box-header {align-items: flex-start;}
.crm-statistics .card-block-box .card-box-body {padding: 40px 30px 0 30px;}
.crm-stat-box {display: flex;flex-direction: row;flex-wrap: wrap;align-items: center;}
.crm-stat-box .icon {width: 35px;height: 35px;}
.crm-stat-box .content {width: calc(100% - 35px);padding-left: 13px;}
.crm-stat-box .content h4 {color: #000;font-size: 22px;font-weight: 500;line-height: 30px;}
.crm-stat-box .content p {color: #74788d;font-size: 12px;font-weight: 400;line-height: 16px;text-transform: capitalize;}

.expense-graph-chart {text-align: center;}
.expense-graph-chart p {color: #8e8e8e;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 300;line-height: 18px;margin: 35px 0 0 0;}

.profit-graph-chart .total-growth {margin: 16px 0 0 0;display: flex;flex-direction: row;align-items: flex-start;justify-content: space-between;}
.profit-graph-chart .total-growth h4 {font-size: 22px;font-weight: 700;padding-right: 10px;}
.profit-graph-chart .total-growth .growth-per {color: #1E1E1E;font-weight: bold;font-size: 16px;}
.profit-graph-chart .total-growth .growth-per.up,.lead-graph-chart .total-growth .growth-per.up {color: #34c38f !important;}
.profit-graph-chart .total-growth .growth-per.down,.lead-graph-chart .total-growth .growth-per.down {color: #f46a6a !important;}

.lead-graph-chart {position: relative;    display: flex;align-items: flex-end;flex-direction: row;justify-content: space-between;}
.lead-graph-chart .total-growth h1 {font-size: 35px;line-height: 30px;font-weight: 700;margin-bottom: 5px;}
.lead-graph-chart .total-growth .growth-per {color: #1E1E1E;font-size: 14px;font-weight: 500;line-height: 30px;}
.lead-graph-chart .total-growth .growth-per > img {margin-right: 5px;display: inline-block;vertical-align: middle;}
.lead-graph-chart .graphic-report {margin: -45px 0 0 0;}

.statistic-budget-report {display: flex;flex-direction: row;flex-wrap: wrap;align-items: stretch;}
.statistic-budget-report .stat-chart-area {width: 600px;border-right: 1px solid rgba(75, 70, 92, 0.12);padding-right: 43px;}
.statistic-budget-report .budget-chart-area {width: calc(100% - 600px);padding-left: 46px;display: flex;flex-direction: column;align-items: center;justify-content: center;}
.budget-chart-area .year {max-width: 83px; margin: 0 auto; margin-bottom: 22px;}
.budget-chart-area .year .select2-container--default .select2-selection--single {border-color: #6259ce; border-radius: 4px;}
.budget-chart-area .year .select2-container--default .select2-selection--single .select2-selection__rendered {padding: 10px 31px 10px 14px; color: #6259CE; line-height: 1;}
.budget-chart-area .year .select2-container--default .select2-selection--single .select2-selection__arrow {background-image: url('../images/select-down-arrow-grey.svg'); width: 8px; right: 12px;}
.budget-chart-area .budget-total-amount .total-amount {font-size: 25px; line-height: 30px; font-weight: 500; color: #000; margin-bottom: 10px;}
.budget-chart-area .budget-total-amount .total-amount span {font-size: 16px;}
.budget-chart-area .budget-total-amount .budget-amount {font-size: 15px; line-height: 1; font-weight: 500; color: #74788d;}
.budget-chart-area .budget-chart-graph {margin: 49px 0;}
.budget-chart-area .t-primary-btn {font-size: 14px; margin-bottom: 57px;}

.crm-transaction-block .report-states-info {margin: 20px 0 0 0;}
.crm-transaction-block .earning-stat-info .amount {font-size: 14px;}
.crm-transaction-block .card-box-body {padding: 30px 0 0 0;}
.crm-transaction-block .sort-control {align-items: stretch;}
.crm-transaction-block .sort-control .select2-container {width: 74px !important;}
.crm-transaction-block .sort-control .select2-container--default .select2-selection--single {border: 1px solid #dfe2e6;}
.crm-transaction-block .sort-control .select2-container--default .select2-selection--single .select2-selection__rendered {padding: 11px 20px 11px 13px;font-weight: 500;}
.crm-transaction-block .sort-control .select2-container--default .select2-selection--single .select2-selection__arrow {width: 14px;right: 10px;background-image: url('../images/select-down-arrow-grey.svg');}
.crm-transaction-block .sort-control .t-primary-btn {font-size: 14px;margin-left: 40px;}
.crm-transaction-block .sort-control .t-primary-btn img {margin-right: 12px;}

.sales-invoice-table.custom-table .table td {font-weight: 400;padding: 15px 20px;}
.sales-invoice-table .c-checkbox label::before {border: 1px solid #dfe2e6;width: 14px;height: 14px;border-radius: 3px;box-shadow: none;}
.sales-invoice-table .c-checkbox label::after {width: 14px;height: 14px;background-size: 10px;}

.sales-invoice-table .client-name {color: #000;font-size: 14px;font-weight: 300;line-height: 19px;text-align: left;}
.sales-invoice-table .client-name span.email {color: #74788d; font-size: 12px; line-height: 16px; display: block;}

.table-action {display: flex;flex-direction: row;align-items: center;}
.table-action > li {display: inline-flex; align-items: center;}
.table-action > li > a {display: inline-flex;align-items: center;padding: 0 6px;}
.table-action > li:first-child > a {padding-left: 0;}

.dashboard-section .page-main-title {margin-bottom: 40px;}
.date-range-pick {position: relative; margin-left: 15px;}
.date-range-pick > div {display: inline-flex;}
.date-range-pick .range-btn {position: relative;display: inline-block;min-width: 161px;border-radius: 10px;background: #fff;border: 1px solid #f3f3f3;font-family: 'Nunito', sans-serif;font-size: 16px;font-weight: bold;line-height: 20px;text-align: left;color: #000;padding: 8px 15px 8px 50px;overflow: hidden;text-transform: initial;}
.date-range-pick .range-btn .icon {position: absolute;top: 0;left: 0;height: 100%;width: 40px;display: flex;flex-direction: column;align-items: center;justify-content: center;background: #6259ce;}
.date-range-pick .filter-btn-modal {display: inline-flex;align-items: center;justify-content: center;margin-left: 15px; position: relative;}
.date-range-pick .filter-btn-modal .filter-btn {width: 40px;height: 40px;display: inline-flex;align-items: center;justify-content: center;background: #6259CE;color: #fff;}

.date-range-pick .date-range-field {position: relative;}
.date-range-pick .date-range-field .icon {position: absolute;top: 0;left: 0;height: 100%;width: 40px;display: flex;flex-direction: column;align-items: center;justify-content: center;background: #6259ce;border-radius: 10px 0 0 10px;}
.date-range-pick .date-range-field .form-control {padding: 8px 10px 8px 50px;font-weight: 700;}

.ticket-date-picker { width: 265px;}

.date-range-field ngx-daterangepicker-material .md-drppicker .buttons .buttons_input .clear svg { display: none !important; }
.template-btn-group {margin-right: 15px; display: inline-flex; position: relative;}
.template-btn-group > .t-primary-btn-outline {border: 1px solid #6259CE; background: #fff; padding-left: 31px; padding-right: 31px;}
.template-btn-group > .t-primary-btn-outline:hover {background: #6259CE;}

.filter-dd-menu {right: 0;left: auto;min-width: 415px;padding: 28px 17px 22px;border-radius: 10px;background: #fff;box-shadow: 0px 2px 6px rgba(36, 41, 70, 0.05);border: 0;margin: 17px 0 0 0;}
.filter-dd-menu::before {content: '';display: inline-block;position: absolute;top: -20px;right: 18px;width: 20px;height: 14px;border: 10px solid #fff;border-top: 10px solid transparent;border-left: 10px solid transparent;border-right: 10px solid transparent;z-index: -1;}
.filter-dd-menu .list-of-filter-option {padding: 15px 20px 0 20px;}
.list-of-filter-option ul > li {display: block;padding: 9px 0;}
.list-of-filter-option ul > li .c-radiobox label::before,.list-of-filter-option ul > li .c-checkbox label::before {width: 15px;height: 15px;border: 1px solid #707070;}
.list-of-filter-option ul > li .c-radiobox label::after,.list-of-filter-option ul > li .c-checkbox label::after {width: 15px;height: 15px;background-size: 11px;}
.list-of-filter-option ul > li .c-checkbox label::before,.list-of-filter-option ul > li .c-checkbox label::after {border-radius: 0;}
.list-of-filter-option ul > li .c-checkbox label::after {background-position: center 3px;}
.filter-dd-menu hr {border-top: 0;border-bottom: 1px solid #e5e5e5;margin: 20px 0;}
.filter-dd-menu .bottom-btnrow {justify-content: flex-start;flex-wrap: nowrap;}
.filter-dd-menu .bottom-btnrow .btn {flex: auto;}
.filter-dd-menu .bottom-btnrow .btn:first-child {margin-left: 0;}
.page-main-title .filter-dd-menu .head-search-box .form-control {background: #f4f4f4;}

.welcome-user-content .card-block-box .card-title {font-size: 32px;line-height: 1;margin-bottom: 30px;}
.welcome-user-content .card-block-box h4 {color: #000;font-family: 'Nunito', sans-serif;font-size: 26px;font-weight: 400;line-height: 30px;margin-bottom: 15px;}
.welcome-user-content .card-block-box p {color: #000;font-family: 'Nunito', sans-serif;font-size: 18px;font-weight: 300;line-height: 30px;}

.account-application-status .card-block-box .card-title {font-size: 32px;line-height: 1;margin-bottom: 11px;}
.account-application-status .card-block-box .card-box-header p {color: #000;font-family: 'Nunito', sans-serif;font-size: 16px;font-weight: 300;line-height: 30px;}

.application-status-list {position: relative;border-radius: 15px;background: rgba(196,196,196,0.07);box-shadow: 0px 2px 4px rgba(36, 41, 70, 0.05);padding: 20px 15px 20px 24px;}
.application-status-list .more-less {display: flex; margin-left: auto;text-align: right; justify-content: flex-end;margin-bottom: 30px; color: #000; font-size: 14px; font-weight: 300; text-decoration: underline; }
.application-status-list .more-less:hover {color: #6259CE;}
.application-status-list ul > li {position: relative;display: block;z-index: 10;margin-bottom: 29px;transition: 0.2s all ease;}
.application-status-list ul > li .app-status-info::before {content: '';display: block;width: 1px;height: 100%;background: #e8e8e8;position: absolute;top: 22px;left: 7px;z-index: -1;transition: 0.2s all ease;}
.application-status-list ul > li .app-status-info.active::before, .application-status-list ul > li .app-status-info.completed::before {background: #6259CE;}
.application-status-list ul > li .app-status-info.rejected::before {background: #FF0000;}
.application-status-list ul > li .app-status-info.approved::before {background: #28C66F;}
.application-status-list ul > li:last-child {margin-bottom: 0;}
.application-status-list ul > li:last-child .app-status-info::before {display: none;}
.application-status-list ul > li .app-status-info::after {content: '\e834';font-family: 'Material Icons';font-size: 16px;font-weight: normal;font-style: normal;color: #E5E5E5;width: 16px;height: 16px;position: absolute;top: 0;left: 0;transition: 0.2s all ease;}
.application-status-list ul > li .app-status-info.active::after, .application-status-list ul > li .app-status-info.completed::after {color: #6259CE;}
.application-status-list ul > li .app-status-info.rejected::after {color: #FF0000;}
.application-status-list ul > li .app-status-info.approved::after {color: #28C66F;}

.application-status-list ul.more-list > li:first-child {display: block !important;}
.application-status-list ul.more-list > li .app-status-info::before {display: none !important;}

.application-status-list .app-status-info {position: relative;padding-left: 30px;}
.application-status-list .app-status-info .date {color: #000;font-family: 'Nunito', sans-serif;font-size: 12px;font-weight: 300;line-height: 16px;margin-bottom: 3px;}
.application-status-list .app-status-info h5 {color: #000;font-size: 16px;font-weight: bold;line-height: 24px;}
.application-status-list .app-status-info p {color: #000;font-family: 'Nunito', sans-serif;font-size: 12px;font-weight: 300;line-height: 16px; margin-top: 2px;}
.application-status-list .app-status-info p > a {color: #000;text-decoration: underline;}
.application-status-list .app-status-info p > a:hover {color: #6259CE;}
.application-status-list ul > li.disabled {cursor: not-allowed;}
.application-status-list ul > li.disabled .app-status-info h5 {color: #e5e5e5;}
.application-status-list ul > li.disabled .app-status-info::before {display: none;}


.app-status-info .reject-reason {margin-top: 15px;}
.app-status-info .reject-reason > ol {counter-reset: my-sec-counter;}
.app-status-info .reject-reason > ol > li {position: relative;padding-left: 15px;}
.app-status-info .reject-reason > ol > li::before {counter-increment: my-sec-counter;content: counter(my-sec-counter)'.';font-size: 12px;line-height: 16px;font-weight: 300;position: absolute;top: 0;left: 0;height: 100%;}

.account-application-status .bottom-btnrow {margin-top: 20px;}
.account-application-status .bottom-btnrow .btn {min-width: 222px; margin: 0;}

.account-dashboard-box .card-box {overflow: hidden;}
.account-dashboard-box .card-box-img {height: 254px; overflow: hidden;}
.account-dashboard-box .card-box-img img {width: 100%; height: 100%; object-fit: cover; object-position: center;}
.account-dashboard-box .card-box-body {padding: 20px;}
.account-dashboard-box .card-box-body h4 {color: #000;font-weight: bold;line-height: 27px;margin-bottom: 15px;}
.account-dashboard-box .card-box-body p {color: #8e8e8e;font-size: 16px;font-weight: 400;line-height: 30px;}

.dashboard-account-section .onboarding-step-block .top-text {margin-bottom: 30px; margin-top: -10px;}
.dashboard-account-section .onboarding-step-block .top-text p {margin-bottom: 0;}
.dashboard-account-section .onboarding-step-block .ob-step-form {width: 100%;max-width: 817px;padding: 10px 0 10px 40px;}
.dashboard-account-section .fm-upload-block .upload-files-box > p {margin-bottom: 0; line-height: 1;}

.upload-product-row {display: flex;flex-direction: row;margin: 0 -20px;flex-wrap: wrap;}
.upload-product-row .upload-box {position: relative;flex: 0 0 50%;max-width: 50%;padding: 0 20px;}
.upload-product-row .upload-box label {position: relative;display: flex;flex-direction: column;align-items: center;justify-content: center;text-align: center;margin: 0;padding: 38px;border-radius: 10px;background: transparent;border: 1px solid #6259ce;box-shadow: 0px 2px 6px rgba(36, 41, 70, 0.05);cursor: pointer;font-family: 'Nunito', sans-serif;font-size: 25px;font-weight: bold;line-height: 30px;color: #6259CE;text-transform: uppercase;min-height: 140px;}
.upload-product-row .upload-box label > span {display: block;max-width: 267px;}
.upload-product-row .upload-box.single-uplode label {background: #6259CE;color: #fff;}
.upload-product-row .upload-box label > input[type="file"] {position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 100%;height: 100%;opacity: 0;visibility: hidden;display: none;}
.quanity-item-control .item-quantity-box {max-width: 128px;display: flex;flex-direction: row;align-items: stretch;flex-wrap: wrap;}
.quanity-item-control .item-quantity-box .minus, .quanity-item-control .item-quantity-box .plus {width: 40px;height: 40px;border-radius: 2px;background: rgb(98, 89, 206, 0.1);display: flex;flex-direction: column;align-items: center;justify-content: center;text-align: center;}
.quanity-item-control .item-quantity-box .qty-control {display: flex;align-items: center;justify-content: center;flex-direction: column;text-align: center;width: 100%;height: 100%;}
.quanity-item-control .item-quantity-box .quantity-text {width: 40px;height: 40px;margin: 0 4px;}
.quanity-item-control .item-quantity-box .quantity-text .form-control {border-radius: 2px;background: #fff;border: 1px solid #e5e5e5;text-align: center;padding: 5px;}

.reports-block-table.card-box-table .card-box-header .card-title {font-size: 20px;line-height: 30px;}

.project-details-content .card-box.card-block-box {box-shadow: none; padding: 20px 24px;}

.project-details-info .head-info {display: flex;flex-direction: row;flex-wrap: wrap;align-items: center;justify-content: space-between;margin-bottom: 28px;}
.project-details-info .head-info .title-info {display: flex;flex-direction: row;flex-wrap: wrap;align-items: flex-start;width: calc(100% - 170px);}
.project-details-info .head-info .title-info .image {width: 60px;height: 60px;border-radius: 10px;overflow: hidden;background: rgb(255, 171, 0, 0.1);}
.project-details-info .head-info .title-info .image > img {width: 100%;height: 100%;object-fit: cover;object-position: center;}
.project-details-info .head-info .title-info .content {width: calc(100% - 60px);padding: 0 15px 0 24px;}
.project-details-info .head-info .title-info .content h3 {line-height: 32px;}
.project-details-info .head-info .title-info .content .tags-list ul {display: flex;flex-direction: row;flex-wrap: wrap;}
.project-details-info .head-info .title-info .content .tags-list ul > li {display: inline-flex;align-items: center;justify-content: center;margin: 6px 4px 0 0;}
.tags-list ul > li > .t-badge-o.red:hover {color: #fff;background: #EA5455;}
.tags-list ul > li > .t-badge-o:hover {color: #fff;background: #6259CE;}
.tags-list ul > li > .t-badge-o.skyblue:hover {color: #fff;background: #63E2F1;}

.project-details-info .uploaded-documents-list {margin: 25px 0 0 0;}
.project-details-info .uploaded-documents-list > li {margin: 0 0 15px 0; display: flex; flex-direction: row; align-items: center; justify-content: space-between;background: rgba(115, 103, 240, 0.05);border-radius: 10px;padding: 15px;}
.project-details-info .uploaded-documents-list > li:last-child {margin-bottom: 0;}
.project-details-info .uploaded-documents-list > li .file-name {padding-right: 15px;}
.project-details-info .uploaded-documents-list > li .file-name > i {margin-right: 3px; display: inline-block; vertical-align: top;}
.project-details-info .uploaded-documents-list > li .file-action {display: flex;align-items: center;}
.project-details-info .uploaded-documents-list > li .file-action > .action-icon {width: 20px;height: 20px;display: inline-flex;align-items: center;justify-content: center;text-align: center;border-radius: 45px;margin-right: 10px;padding: 2px; color: #1e1e1e;font-weight: bold;background: #fff;}
.project-details-info .uploaded-documents-list > li .file-action > .action-icon:last-child {margin-right: 0;}
.project-details-info .uploaded-documents-list > li .file-action > .action-icon:hover {background: #6259CE; color: #fff;}
.project-details-info .uploaded-documents-list > li .file-action > .action-icon:hover > img {filter: brightness(0) invert(1);}
.project-details-info .uploaded-documents-list > li .file-action > .action-icon > i {font-weight: bold;}

.project-details-info .head-info .date-info {width: 170px;}
.project-details-info .head-info .date-info [class*="date-"] {font-family: 'Nunito', sans-serif;font-size: 12px;font-weight: 400;line-height: 16px;text-align: right;color: #656565;margin-bottom: 8px;}
.project-details-info .head-info .date-info .date-last {color: #ec5453;}
.project-details-info .head-info .date-info [class*="date-"]:last-child {margin-bottom: 0;}

.sub-title {color: #1e1e1e;font-size: 16px;font-weight: 600;line-height: 22px;}
.project-details-info .project-description .sub-title {line-height: 16px;margin-bottom: 30px;}
.project-details-info .project-description p {color: #656565;font-size: 14px;font-weight: 400;line-height: 20px;margin-bottom: 30px;}
.project-description .add-row {margin-bottom: 10px;}
.project-description .add-row .add-img-link {width: 48px;height: 48px;background: rgba(40, 198, 111, 0.1);display: flex;align-items: center;justify-content: center;border-radius: 45px;position: relative;cursor: pointer;margin: 0 auto;}
.project-description .add-row .add-img-link input[type="file"] {position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 100%;height: 100%;opacity: 0;visibility: hidden;z-index: -1;}
.project-description .add-row .add-image-block > img {display: inline-flex; align-items: stretch; margin-right: 15px;}

.task-progress-info {display: flex;flex-direction: row;align-items: flex-end;flex-wrap: wrap;margin-bottom: 30px;}
.task-progress-info .progress-info {width: calc(100% - 143px);padding-right: 28px;}
.task-progress-info .progress-info .status-info {margin: 12px 0 0 0;position: relative;padding-right: 45px;}
.task-progress-info .progress-info .status-info .progress {background: rgba(197,197,197,0.2);height: 10px;border-radius: 45px;margin-bottom: 7px;}
.task-progress-info .progress-info .progress-count {position: absolute;top: 0;right: 0;font-size: 14px;line-height: 1;text-align: right;color: #656565;}
.task-progress-info .progress-info .total-task {font-family: 'Nunito', sans-serif;color: #656565;font-size: 10px;font-weight: 400;line-height: 12px;}
.task-progress-info .action-btn {width: 143px;}
.task-progress-info .action-btn > .btn {display: block;}

.task-checklist {margin: 15px 0 0 0;}
.task-checklist ul > li {margin-bottom: 6px;}
.task-checklist ul > li:last-child {margin-bottom: 0;}

.checkbox-full {position: relative;display: block;}
.checkbox-full input[type="checkbox"] {position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 100%;height: 100%;opacity: 0;visibility: hidden;display: none;}
.checkbox-full label {display: block;position: relative;color: #1e1e1e;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 400;line-height: 1;margin: 0;cursor: pointer;padding: 11px 40px 11px 10px;border-radius: 10px;background: rgba(243,243,243,0.4);-webkit-transition: 0.3s all ease-in-out;-moz-transition: 0.3s all ease-in-out;-ms-transition: 0.3s all ease-in-out;transition: 0.3s all ease-in-out;}
.checkbox-full label::before {content: '';display: inline-block;position: absolute;top: 50%;right: 10px;margin-top: -9px;width: 18px;height: 18px;border-radius: 100%;background-color: #fff;border: 1px solid #c5c5c5;-webkit-transition: 0.3s all ease-in-out;-moz-transition: 0.3s all ease-in-out;-ms-transition: 0.3s all ease-in-out;transition: 0.3s all ease-in-out;background-repeat: no-repeat;background-position: center;background-size: 10px;}
.checkbox-full label::after {content: '';display: inline-block;position: absolute;top: 50%;right: 10px;margin-top: -9px;width: 18px;height: 18px;border-radius: 100%;background-color: transparent;border: 1px solid #6259CE;-webkit-transition: 0.3s all ease-in-out;-moz-transition: 0.3s all ease-in-out;-ms-transition: 0.3s all ease-in-out;transition: 0.3s all ease-in-out;background-image: url('../images/checkmark-outline-blue.svg');background-repeat: no-repeat;background-position: center;background-size: 10px;opacity: 0;visibility: hidden;}
.checkbox-full input[type="checkbox"]:checked + label::after {opacity: 1;visibility: visible;}


.task-checklist.edit ul > li {margin-bottom: 16px;}
.task-checklist.edit ul > li:last-child {margin-bottom: 0;}

.task-edit-block {position: relative;border: 1px dashed #c3c3c3;padding: 11px 60px 11px 10px;border-radius: 10px;}
.task-edit-block h6 {color: #1e1e1e;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 400;line-height: 1;}
.task-edit-block .action-btn {position: absolute;top: 50%;right: 10px;margin-top: -11px;display: flex;flex-direction: row;}
.task-edit-block .action-btn > a {display: inline-flex;width: 22px;height: 22px;border-radius: 45px;margin-right: 6px;}
.task-edit-block .action-btn a:last-child {margin-right: 0;}
.task-edit-block .form-control {padding: 0;width: 100%;border: 0;min-height: auto;border-radius: 0;}

.project-attached-info .attached-file-head {display: flex;flex-direction: row;align-items: center;justify-content: space-between;flex-wrap: wrap;margin-bottom: 22px;}
.project-attached-info .attached-file-head .total-files-title {padding-right: 15px;}
.project-attached-info .attached-file-head .total-files-title h6 {display: inline-flex;align-items: center;}
.project-attached-info .attached-file-head .total-files-title h6 .t-badge {display: inline-flex;align-items: center;justify-content: center;width: 23px;height: 23px;padding: 0;min-width: auto;border-radius: 45px;font-size: 12px;font-weight: 400;letter-spacing: -0.06em;margin-left: 8px;}
.project-attached-info .attached-file-head .upload-file {display: inline-flex;align-items: center;}
.project-attached-info .attached-file-head .upload-file > label {position: relative;font-size: 14px;line-height: 19px;display: inline-flex;align-items: center;cursor: pointer;}
.project-attached-info .attached-file-head .upload-file > label input[type="file"] {position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 100%;height: 100%;opacity: 0;visibility: hidden;}
.project-attached-info .attached-file-head .upload-file > label .icon {margin-right: 10px;}
.attached-file-list ul > li {display: flex;flex-direction: row;align-items: center;justify-content: space-between;flex-wrap: wrap;margin-bottom: 19px;}
.attached-file-list ul > li:last-child {margin-bottom: 0;}
.attached-file-list ul > li .file-info {display: flex;flex-direction: row;align-items: start;flex-wrap: wrap;width: calc(100% - 20px);}
.attached-file-list ul > li .file-info .image {width: 36px;height: 36px;display: inline-flex;align-items: center;justify-content: center;background: rgb(98, 89, 206, 0.1);border-radius: 90px;overflow: hidden;}
.attached-file-list ul > li .file-info .image > img {width: 10px;display: block;margin: 0 auto;}
.attached-file-list ul > li .file-info .content {padding: 0 16px;width: calc(100% - 36px);}
.attached-file-list ul > li .file-info .content h6 {color: #1e1e1e;font-size: 14px;font-weight: 400;line-height: 19px;margin-bottom: 5px;}
.attached-file-list ul > li .file-info .content .file-size {color: #656565;font-size: 10px;font-weight: 400;line-height: 12px;}

.attached-file-list .upload-attachement-block {position: relative;border-radius: 5px;background: #f4f4f4;padding: 22px 20px;margin: 30px 0 0 0;}
.attached-file-list .upload-attachement-block .form-control {background-color: #fff;}
.attached-file-list .fm-upload-block .upload-files-box {background: #fff !important;}
.uploaded-items-group {display: flex;flex-direction: row;flex-wrap: wrap;margin-right: auto;}
.uploaded-items-group .uploaded-item {flex: 0 0 77px;max-width: 77px;margin-right: 10px;margin-bottom: 10px;position: relative;}
.uploaded-items-group .uploaded-item:last-child {margin-right: 0;}
.uploaded-items-group .uploaded-item .remove {display: inline-flex;width: 11px;height: 11px;background: #707070;align-items: center;justify-content: center;padding: 2px;border-radius: 100%;position: absolute;top: 3px;right: 3px;}
.uploaded-items-group .uploaded-item .remove:hover {background: #000;}
.uploaded-items-group .uploaded-item .remove > img {filter: brightness(1) invert(1);}
.uploaded-items-group .uploaded-item .icon {border-radius: 6px;}
.uploaded-items-group .uploaded-item .icon > img {border-radius: 6px;width: 100%;height: 93px;object-fit: cover;object-position: top;}
.uploaded-items-group .uploaded-item .file-name {border-radius: 3px;background: #6259CE;position: absolute;left: 0;right: 0;bottom: 0;padding: 3px 4px;font-family: 'Nunito', sans-serif;font-weight: 400;font-size: 8px;text-align: center;color: #fff;}
.drag-box.error .uploaded-items-group .uploaded-item .file-name {background: #f00;}
.drag-box.success .uploaded-items-group .uploaded-item .file-name {background: #34c38f;}
.upload-attachement-block .uploaded.upload-files-box {cursor: default;}

/*---Account Settings---*/
.t-card-primary {margin-bottom: 30px; overflow: hidden; border-radius: 15px; border: 0; }
.t-card-primary .card-box-header { margin-bottom: 0; background-color: #edebfd; padding: 9px 30px;}
.t-card-primary .card-title {font-size: 20px; line-height: 27px; font-weight: 700; color: #6259CE; margin-bottom: 0;}
.t-card-primary .card-box-header .card-title-desc p {color: #6259CE;font-size: 14px;}
.t-card-primary .card-box-body { padding: 30px;}
.t-card-primary .card-box-body .t-primary-btn-outline img, .t-card-primary .card-box-body .t-danger-btn-outline img { margin-right: 11px; }
.t-card-primary .card-box-body .t-primary-btn-outline:hover img, .t-card-primary .card-box-body .t-danger-btn-outline:hover img { filter: brightness(0) invert(1); }
.t-card-primary .card-box-body p { line-height: 28px; margin-bottom: 28px;}
.t-card-primary .card-box-body p:last-child {margin-bottom: 0;}

.profile-col { text-align: center; background-color: #fff; padding: 32px; border-radius: 10px; }
.profile-col .profile-img { width: 156px; height: 156px; display: inline-block; margin-bottom: 18px; margin-top: 14px; background-image: url('../images/profile-avatar-img.svg'); background-repeat: no-repeat; background-position: center; background-size: cover;}
.profile-col .profile-img img { width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 100%; }
.profile-col .profile-name { margin-bottom: 36px;}
.profile-col .profile-name h2 { margin-bottom: 2px; line-height: 41px; font-weight: 700;}
.profile-col .profile-name p { font-size: 18px; line-height: 24px; color: #656565; }
.profile-col .trn-block { background-color: #F3F3F3; padding: 20px 15px; border-radius: 10px; }
.profile-col .trn-block h4 { margin-bottom: 15px; line-height: 28px; font-weight: 700;}
.profile-col .trn-block p { font-size: 16px; line-height: 22px; }
.profile-col .deactivate-col { margin-top: 47px; text-align: left; max-width: 280px; }
.profile-col .deactivate-col .custom-switch { margin-bottom: 10px; }
.profile-col .deactivate-col h6 { line-height: 19px; margin-bottom: 3px; }
.profile-col .deactivate-col p { line-height: 14px; font-size: 12px; color: #8E8E8E; }

.vertical-nav { background-color: #fff; padding: 13px 0; border-radius: 10px;}
.vertical-nav ul { margin: 0; padding: 0;}
.vertical-nav ul li { margin: 0; position: relative; list-style: none; font-size: 16px; line-height: 22px; margin-right: 5px;}
.vertical-nav ul li a {display: flex; align-items: center;color: #000; padding: 17px 34px;}
.vertical-nav ul li a:hover { color: #6259CE; }
.vertical-nav ul li.active a { font-weight: 700; color: #6259CE; background: rgba(98, 89, 206, 0.13); border-radius: 0 10px 10px 0;}
.vertical-nav ul li:before { position: absolute; content: ''; opacity: 0; left: 0; top: 0; height: 100%; width: 5px; background-color: #6259CE; border-radius: 0 10px 10px 0; }
.vertical-nav ul li.active:before { opacity: 1;}
.vertical-nav ul li .icon { width: 24px; height: 24px; margin-right: 6px; }
.vertical-nav ul li .icon img { -webkit-filter: brightness(0);-ms-filter: brightness(0);-moz-filter: brightness(0);filter: brightness(0); }
.vertical-nav ul li.active .icon img, .vertical-nav ul li a:hover .icon img { -webkit-filter: brightness(1);-ms-filter: brightness(1);-moz-filter: brightness(1);filter: brightness(1); }

.page-main-title { display: flex; flex-wrap: wrap; justify-content: space-between;  align-items: center; margin-bottom: 30px;}
.page-main-title h2 { font-weight: 700; margin-bottom: 0; padding-right: 15px;}
.page-main-title .head-search-box .form-control {background: #fff;}
.page-main-title.title-breadcrumbs h2 {font-weight: 400;display: inline-flex;align-items: center;}
.page-main-title.title-breadcrumbs .icon-seprator {margin: 0 8px;display: inline-flex;align-items: center; width: 30px;}
.page-main-title.title-breadcrumbs h2 strong {font-weight: bold;}

.account-settings-gridrow { display: flex; flex-wrap: wrap; margin: 0 -15px;}
.account-settings-gridrow .left-col { flex: 0 0 21%; max-width: 21%; padding: 0 15px;}
.account-settings-gridrow .left-col.profile-left-col { flex: 0 0 25%; max-width: 25%; padding: 0 15px;}
.account-settings-gridrow .middle-col { flex: 0 0 53%; max-width: 53%; padding: 0 15px; }
.account-settings-gridrow .last-col { flex: 0 0 26%; max-width: 26%; padding: 0 15px; }

.edit-form-row .form-group { display: flex; flex-wrap: wrap; margin-bottom: 20px;}
.edit-form-row .form-group:last-child { margin-bottom: 0;}
.edit-form-row .form-group .left-title { width: 175px; margin: 11px 0; padding-right: 10px;}
.edit-form-row .form-group .left-title label { margin-bottom: 0;}
.edit-form-row .form-group .left-title label.label-icon {display: flex; flex-direction: row; align-items: center;}
.edit-form-row .form-group .left-title label.label-icon .icon {margin-right: 8px; width: 20px;}
.edit-form-row .form-group .right-field { width: calc(100% - 175px);}
.edit-form-row .form-group.large .left-title { width: 256px; }
.edit-form-row .form-group.large .right-field { width: calc(100% - 256px); }
.edit-form-row .form-group .company-bio .left-title { width: 100%; margin: 0; }
.edit-form-row .form-group .company-bio .left-title label { margin: 0 0 5px 0;}
.edit-form-row .form-group .company-bio .right-field { width: 100%; }
.edit-form-row .form-group .change-field { float: right; font-size: 16px; line-height: 22px; font-weight: 600; margin-top: 2px; }
.edit-form-row .form-group .change-field:hover {color: #6259CE !important;}
.edit-form-row .form-group .note-field {font-size: 12px;line-height: 14px;text-align: left;color: #000;margin: 10px 0 0 0;}

.edit-form-row .form-group .right-field .gender-row { display: flex; flex-wrap: wrap; margin-top: 11px; }
.edit-form-row .form-group .right-field .gender-row .c-checkbox { margin: 0 28px 0 0; padding-left: 20px; }
.edit-form-row .form-group .right-field .gender-row .c-checkbox label { margin: 0; font-size: 14px; color: #8E8E8E;}
.edit-form-row .form-group .right-field .gender-row .c-checkbox label:before { top: 1px; border: none; background-color: #E5E5E5; box-shadow: none; width: 14px; height: 14px; border-radius: 3px;}
.edit-form-row .form-group .right-field .gender-row .c-checkbox label:after { top: 1px; width: 14px; height: 14px; background-size: 10px; opacity: 1; }
.edit-form-row .form-group .right-field .gender-row .c-checkbox input[type="radio"]:checked ~ label:before { background-color: #6259CE; }
.edit-form-row .form-group .right-field .gender-row .c-checkbox input[type="radio"] { position: absolute; top: 0; left: 0; right: 0; bottom: 0; width: 100%; height: 100%; opacity: 0; visibility: hidden; display: none; }
.edit-form-row .form-group .right-field .date-control-group { position: relative;}
.edit-form-row.profile-form .form-group .right-field .date-control-group { max-width: 170px;}
.edit-form-row .form-group .right-field .profile-picture { width: 80px; height: 80px; position: relative; margin-bottom: 15px;  background-image: url('../images/profile-avatar-img.svg'); background-repeat: no-repeat; background-position: center; background-size: cover;}
.edit-form-row .form-group .right-field .profile-picture > img { width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 100%; }
.edit-form-row .form-group .right-field .profile-picture .profile-edit { width: 27px; height: 27px; background-color: #28C66F; position: absolute; right: 0; bottom: 0; background-image: url(../images/profile-edit-icon.svg); background-position: center center; background-repeat: no-repeat; border-radius: 50px; cursor: pointer; margin: 0; display: inline-flex; align-items: center; justify-content: center;}
.edit-form-row .form-group .right-field .profile-picture .profile-edit input[type="file"] {display: none; position: absolute; left: 0; right: 0; bottom: 0; top: 0; width: 100%; height: 100%; opacity: 0; visibility: hidden;}
.edit-form-row .form-group .right-field .profile-picture .profile-edit.remove-img {background: #EC5453;}
.edit-form-row .form-group .right-field .profile-picture .profile-edit.remove-img img {width: 13px;}
.edit-form-row .form-group .right-field .socials-right { padding-right: 60px; position: relative; }
.edit-form-row .form-group .right-field .socials-right .social-icon { position: absolute; right: 0; top: 0; }
.edit-form-row .form-group textarea.form-control { height: 132px; resize: none;}

.add-btncol .btn {font-size: 14px; font-weight: 700; padding: 12px 20px;}

.language-row { display: flex; flex-wrap: nowrap; margin: 0 -15px; }
.language-row .language-col { padding: 0 15px; max-width: 303px; width: 100%; margin-bottom: 16px !important; }
.language-row .language-col ng-select2, .language-row .language-col .select2-control {width: 100%;}
.language-row .del-lang {margin: 10px 0 0 0;}

.bottom-btnrow { display: flex; flex-wrap: wrap; justify-content: flex-end; }
.bottom-btnrow .btn { margin-left: 16px; min-height: 40px;}
.bottom-btnrow .btn img { margin-right: 16px; }
.bottom-btnrow .t-grey-btn-outline:hover img { filter: brightness(0) invert(1); }

.days-group-control { display: flex; align-items: center;}
.days-group-control .days-select-col { max-width: 165px; width: 100%; }
.days-group-control > span { font-size: 14px; color: #8E8E8E; padding: 0 22px; }
.days-group-control .change-field-link { margin-left: 20px;}
.days-group-control .currency-input-group {max-width: 150px; margin-left: 35px;}


.team-profile-row { display: flex; flex-wrap: wrap; align-items: center; margin-bottom: 40px; }
.team-profile-row .profile-imgcol { width: 195px; height: 195px; position: relative;background-image: url(../images/profile-avatar-img.svg);background-repeat: no-repeat;background-position: center;background-size: cover;}
.team-profile-row .profile-imgcol > img { width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 100%; }
.team-profile-row .profile-imgcol .profile-edit { width: 30px; height: 30px; background-color: #28C66F; position: absolute; right: 15px; bottom: 15px; background-image: url(../images/profile-edit-icon.svg); background-position: center center; background-repeat: no-repeat; border-radius: 50px; cursor: pointer; margin: 0; display: inline-flex; align-items: center; justify-content: center; }
.team-profile-row .profile-imgcol .profile-edit input[type="file"] {display: none; position: absolute; left: 0; right: 0; bottom: 0; top: 0; width: 100%; height: 100%; opacity: 0; visibility: hidden;}
.team-profile-row .profile-imgcol .profile-edit.remove-img {background: #EC5453;}
.team-profile-row .profile-imgcol .profile-edit.remove-img img {width: 14px;}

.team-profile-row .profile-rightcol { padding-left: 75px; width: calc(100% - 195px); }
.team-profile-row .profile-rightcol > .row { align-items: center;}
.team-profile-row .profile-rightcol .content h3 { font-size: 25px; font-weight: 700; margin-bottom: 20px;}
.team-profile-row .profile-rightcol .list-content { padding: 0; margin: 0;}
.team-profile-row .profile-rightcol .list-content li { padding: 0; margin: 0 0 45px 0; list-style: none; display: flex; flex-wrap: wrap; align-items: center;}
.team-profile-row .profile-rightcol .list-content li:last-child { margin-bottom: 0;}
.team-profile-row .profile-rightcol .list-content li .icon { width: 50px; height: 50px; border-radius: 50px; margin-right: 26px; }
.team-profile-row .profile-rightcol .list-content li .icon img { width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 50px; }
.team-profile-row .profile-rightcol .list-content li .content { width: calc(100% - 76px); }
.team-profile-row .profile-rightcol .list-content li .content h6 { font-weight: 700; line-height: 22px; }
.team-profile-row .profile-rightcol .list-content li .content p { line-height: 22px; }
.team-profile-detail .card-box-body { padding: 35px 50px;}
.team-profile-detail .t-badge { font-size: 12px; text-transform: uppercase; border-radius: 12px; padding: 9px 16px; }
.team-profile-detail .t-badge.green { color: #28C66F; background: rgba(40,198,111,0.09); }
.team-profile-detail .form { max-width: 710px;}
.team-profile-detail .form h4 { font-weight: 700; margin-bottom: 20px; }
.team-profile-detail .form .form-group { display: flex; flex-wrap: wrap; }
.team-profile-detail .form .form-group .left-title { width: 175px; margin: 11px 0; }
.team-profile-detail .form .form-group .left-title label { margin-bottom: 0; }
.team-profile-detail .form .form-group .right-field { width: calc(100% - 175px); }
.team-profile-detail .form .form-group .right-field .deactivate-col { margin-top: 11px; }
.team-profile-detail .form .form-group .right-field .deactivate-col .custom-switch { margin-bottom: 10px; }
.team-profile-detail .form .form-group .right-field .deactivate-col h6 { line-height: 19px; margin-bottom: 3px; }
.team-profile-detail .form .form-group .right-field .deactivate-col p { line-height: 14px; font-size: 12px; color: #8E8E8E; }
.team-profile-detail .form .form-group:last-child { margin-bottom: 0;}

.team-rightcol .card-box-body { padding: 25px;}
.team-rightcol h4 { margin-bottom: 15px; font-weight: 700;}
.team-rightcol .head-search-box { margin-bottom: 15px;}
.team-list { margin: 0; padding: 0; }
.team-list > li { margin: 0 0 10px 0; padding: 0 70px 0 0; list-style: none; display: flex; flex-wrap: wrap; align-items: center; position: relative; }
.team-list > li .icon { width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; margin-right: 20px; background-color: #EFEFEF; border-radius: 100%; }
.team-list > li .icon img { width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 100%; }
.team-list > li .content { width: calc(100% - 60px); position: relative;  }
.team-list > li .more-action-dd { position: absolute; right: 40px; top: 12px; }
.team-list > li .more-action-dd .action-more-btn { width: 20px;}

.team-list-table tr td {padding: 0 0 10px 0 !important; vertical-align: middle; border: 0 !important;}
.team-list-table tr td:last-child {text-align: right !important; vertical-align: middle; padding-right: 40px !important;}
.team-list-table tr:last-child td {padding-bottom: 0 !important;}

.team-list-table .team-person-info {display: flex; flex-wrap: wrap; align-items: center;}
.team-list-table .team-person-info .icon { width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; margin-right: 20px; background-color: #EFEFEF; border-radius: 100%; }
.team-list-table .team-person-info .icon img { width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 100%; }
.team-list-table .team-person-info .content { width: calc(100% - 60px); position: relative;}
.team-list-table .more-action-dd {display: inline-block; vertical-align: middle;}
.team-list-table .more-action-dd .action-more-btn { width: 20px;}

.new-coupon-block .card-box-body { padding: 28px 20px 20px 20px;}
.new-coupon-block .card-box-header { padding: 0; margin-bottom: 30px; display: block;}
.new-coupon-block .card-box-header .card-title { margin-bottom:7px; font-weight: 700; }
.new-coupon-block .card-box-header p { line-height: 22px; font-weight: 300;}
.new-coupon-block .form-rows { margin-bottom: 45px;}
.new-coupon-block .form { padding-left: 30px; }
.new-coupon-block .form h4 { font-weight: 700; margin-bottom: 20px; }
.new-coupon-block .form .form-group { display: flex; flex-wrap: wrap; }
.new-coupon-block .form .form-group .left-title { width: 175px; margin: 11px 0; }
.new-coupon-block .form .form-group .left-title label { margin-bottom: 0; }
.new-coupon-block .form .form-group .right-field { width: calc(100% - 175px); }
.new-coupon-block .form .form-group .right-field .deactivate-col { margin-top: 11px; }
.new-coupon-block .form .form-group .right-field .deactivate-col .custom-switch { margin-bottom: 10px; }
.new-coupon-block .form .form-group .right-field .deactivate-col h6 { line-height: 19px; margin-bottom: 3px; }
.new-coupon-block .form .form-group .right-field .deactivate-col p { line-height: 14px; font-size: 12px; color: #8E8E8E; }
.new-coupon-block .form .form-group:last-child { margin-bottom: 0;}
.new-coupon-block .form .check-list { margin-top: 11px; }
.new-coupon-block .form .check-list .c-radiobox {padding-left: 24px; margin-bottom: 15px; }
.new-coupon-block .form .check-list .c-radiobox label { font-size: 14px; }
.new-coupon-block .form .check-list .c-radiobox label:after { width: 14px; height: 14px; background-size: 10px; top: 1px; }
.new-coupon-block .form .check-list .c-radiobox label:before { border: 1px solid #dfe2e6; width: 14px; height: 14px; border-radius: 3px; top: 1px; box-shadow: none; }
.new-coupon-block .form .check-list .c-radiobox input[type="radio"]:checked ~ label:before { border-color: #6259CE;}
.new-coupon-block .form .form-group .enable-check { display: flex; flex-wrap: wrap;}
.new-coupon-block .form .form-group .enable-check .left-title { width: auto; padding-right: 20px; margin: 3px 0; font-size: 14px;}

.new-coupon-block .tag-selected {border-color: #e5e5e5; min-height: 40px; max-width: 100%;}
.new-coupon-block .tag-selected > .selected-tag { color: #6259CE; font-size: 14px;}

.new-coupon-block .form .check-list .c-checkbox {padding-left: 24px; margin-bottom: 15px; }
.new-coupon-block .form .check-list .c-checkbox label { font-size: 14px; }
.new-coupon-block .form .check-list .c-checkbox label:after { width: 14px; height: 14px; background-size: 10px; top: 1px; }
.new-coupon-block .form .check-list .c-checkbox label:before { border: 1px solid #dfe2e6; width: 14px; height: 14px; border-radius: 3px; top: 1px; box-shadow: none; }
.new-coupon-block .form .check-list .c-checkbox input[type="checkbox"]:checked ~ label:before { border-color: #6259CE;}

.date-timerow { display: flex; flex-wrap: wrap; margin: 0 -10px; }
.date-timerow .date-timecol { padding: 0 10px; max-width: 40%; width: 100%; }

.status-select-control {max-width: 140px;}
.lg-radiobox {display: flex;flex-flow: row wrap;align-items: center;}
.lg-radiobox .c-radiobox {padding: 8px 24px 8px 40px;}
.lg-radiobox .c-radiobox:last-child {padding-right: 0;}
.lg-radiobox .c-radiobox label {color: #8e8e8e;}
.lg-radiobox .c-radiobox label::before {width: 32px;height: 32px;border: 1px solid #e5e5e5;}
.lg-radiobox .c-radiobox input[type="radio"]:checked ~ label {color: #000;}
.lg-radiobox .c-radiobox input[type="radio"]:checked ~ label::before {border-width: 2px;}
.lg-radiobox .c-radiobox label::after {width: 20px;height: 20px;top: 6px;left: 6px;}

.field-with-info {display: flex;flex-flow: row wrap;align-items: center;}
.field-with-info .field-info-type {width: calc(100% - 32px); padding-right: 8px;}
.field-with-info .info-tooltip {width: 32px;display: inline-flex;align-items: center;justify-content: center;}
.field-with-info .field-info-type .price-field-control .unit {color: #8e8e8e; font-size: 16px;}

.sort-control.purple-dropdown .sortby-name { font-size: 14px; margin-right: 20px;}
.sort-control.purple-dropdown .select2-container--default .select2-selection--single .select2-selection__rendered { padding: 13px 35px 13px 10px; color: #fff; font-size: 14px; background-color: #6259CE; font-weight: 700; border-radius: 10px; }
.sort-control.purple-dropdown .select2-container--default .select2-selection--single .select2-selection__arrow { right: 10px; filter: brightness(0.5) invert(1); }
.card-box-table .head-search.head-search-between { justify-content: space-between; align-items: flex-start; }

.head-search-with-btn {display: flex;flex-direction: row;align-items: center;flex: 1;justify-content: flex-end;}
.head-search-with-btn .t-primary-btn-small {padding: 13px 25px;margin-left: 24px;}
.head-search-with-btn .t-primary-btn-small .icon {margin-right: 15px;}


.new-category { max-width: 1280px; }
.edit-form-row .form-group .right-field .slug { position: relative;}
.edit-form-row .form-group .right-field .slug span { font-size: 12px; line-height: 14px; color: #8E8E8E; margin-top: 7px; display: inline-block; padding: 0px 16px; }
.edit-form-row .form-group .right-field .slug .slug-url { position: absolute; left: 0; top: 0; background-color: #F3F3F3; border: 1px solid #E5E5E5; border-radius: 10px; padding: 10px 16px; color: #000000; font-size: 14px; line-height: 17px; max-width: 225px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
.edit-form-row .form-group .right-field .slug .form-control {padding-left: 230px;}

.radio-select-row { display: inline-flex; flex-wrap: wrap; background-color: #FCFCFC; border: 1px solid #F3F3F3; border-radius: 50px; padding: 3px; }
.radio-select-row .custom-control { position: relative; padding: 0; }
.radio-select-row .custom-control label { margin: 0; cursor: pointer;}
.radio-select-row .custom-control label input[type="radio"] { position: absolute; left: 0; top: 0; width: 100%; height: 100%; opacity: 0; }
.radio-select-row .custom-control label span { position: relative; font-size: 12px; color: #000000; padding: 5px 15px; display: inline-block; line-height: 20px; text-transform: uppercase; }
.radio-select-row .custom-control label span:after { position: absolute; content: ''; z-index: -1; left: 0; top: 0; width: 100%; height: 100%; background-color: #6259CE; border-radius: 20px; opacity: 0; -webkit-transition: .2s linear;  -moz-transition: .2s linear;  -ms-transition: .2s linear; transition: .2s linear;}
.radio-select-row .custom-control label input[type="radio"]:checked + span { color: #fff; font-weight: 700; }
.radio-select-row .custom-control label input[type="radio"]:checked + span:after { opacity: 1; }

.v-check-list .c-radio {padding-left: 24px; margin-bottom: 15px; position: relative; }
.v-check-list .c-radio input[type="radio"] { position: absolute; top: 0; left: 0; right: 0; bottom: 0; width: 100%; height: 100%; opacity: 0; visibility: hidden; display: none; }
.v-check-list .c-radio label { font-size: 16px; margin: 0; cursor: pointer; }
.v-check-list .c-radio label:before { width: 14px; height: 14px; border: 1px solid #707070; border-radius: 15px; top: 0px; box-shadow: none; content: ''; position: absolute; left: 0; display: inline-block; background-color: #fff; transition: 0.2s all ease-in-out; }
.v-check-list .c-radio input[type="radio"]:checked ~ label:before { border-color: #6259CE; background-color: #6259CE;}
.uploaded-imagecol { width: 100%; }
.uploaded-imagecol .uploaded-image { position: relative; height: 290px; width: 100%; margin-bottom: 20px; }
.uploaded-imagecol .uploaded-image img { height: 100%; width: 100%; object-fit: cover; object-position: center; border-radius: 12px; }
.uploaded-imagecol .upload-options { display: flex; flex-wrap: wrap; }
.uploaded-imagecol .upload-options .image-edit { cursor: pointer; margin: 0 30px 0 0; color: #707070; }
.uploaded-imagecol .upload-options .image-edit input[type="file"] {display: none; position: absolute; left: 0; right: 0; bottom: 0; top: 0; width: 100%; height: 100%; opacity: 0; visibility: hidden;}
.uploaded-imagecol .upload-options a { color: #FF0000; }

.multiple-links { display: flex; flex-wrap: wrap; position: relative;}
.multiple-links .btn { margin-left: 15px; padding: 12px 25px;}
/* .multiple-links .btn:first-child {margin-left: 0;} */
.print-dropdown-box {position: relative;}
.print-dropdown-box .t-primary-btn {background-image: url('../images/chevron-down-outline.svg');background-size: 12px;background-position: top 15px right 10px;background-repeat: no-repeat;padding-right: 30px;text-align: left; align-items: flex-start;}
.print-dropdown-box .dropdown-menu {right: 0;left: auto;padding: 0;border-radius: 10px;background: #fff;box-shadow: 0px 2px 6px rgba(36, 41, 70, 0.05);border: 1px solid #ececec;margin: 7px 0 0 0;overflow: hidden;}
.print-dropdown-box .dropdown-menu > ul > li > a, .print-dropdown-box .dropdown-menu > ul > li > button {display: block;color: #1E1E1E;font-size: 14px;font-weight: 600;line-height: 18px;padding: 12px 15px;border: 0;width: 100%;background: transparent;text-align: left;}
.print-dropdown-box .dropdown-menu > ul > li {border-bottom: 1px solid #ececec;}
.print-dropdown-box .dropdown-menu > ul > li:last-child {border-bottom: 0;}
.print-dropdown-box .dropdown-menu > ul > li > a:hover, .print-dropdown-box .dropdown-menu > ul > li > a:active, .print-dropdown-box .dropdown-menu > ul > li > a.active, .print-dropdown-box .dropdown-menu > ul > li.active > a, .print-dropdown-box .dropdown-menu > ul > li > button:hover, .print-dropdown-box .dropdown-menu > ul > li > button:active, .print-dropdown-box .dropdown-menu > ul > li > button.active, .print-dropdown-box .dropdown-menu > ul > li.active > button {background: rgba(115, 103, 240, 0.1);color: #6259CE;}

.no-button { color: #6259ce; font-weight: 600; border-bottom: 1px solid #6259ce; cursor: default;}

.preview-col .top-link { margin-bottom: 25px; text-align: right;}
.preview-col .top-link a { font-size: 14px; color: #6259CE; font-weight: 700; text-transform: uppercase; }
.preview-col h5 { font-weight: 700; color: #8E8E8E; line-height: 24px; margin-bottom: 20px; }
.preview-col .tags-row { font-size: 10px; line-height: 14px; color: #3E3E3E; margin-bottom: 15px; }
.preview-col .tags-row a { color: #1D51FE; }
.preview-col .image-prevcol { position: relative; display: flex; flex-wrap: wrap; justify-content: space-between; margin-bottom: 23px; }
.preview-col .image-prevcol .image { height: 280px; width: calc(100% - 50px); }
.preview-col .image-prevcol .image img { width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 12px; }
.preview-col .image-prevcol .right-icon { margin: 0; padding: 0; }
.preview-col .image-prevcol .right-icon li { margin: 0 0 13px 0; padding: 0; list-style: none;}
.preview-col .image-prevcol .right-icon li a { display: flex; align-items: center; justify-content: center; border: 1px solid #380E6F; border-radius: 100%; width: 26px; height: 26px; }
.preview-col .image-prevcol .right-icon li a:hover { background-color: #6259CE; border-color: #6259CE; }
.preview-col .image-prevcol .right-icon li a:hover img { filter: brightness(0) invert(1); }
.preview-col .content p { font-size: 10px; line-height: 14px; color: #8E8E8E; font-weight: 300; }
.preview-col .content .heading { margin-bottom: 23px; }
.preview-col .content .heading h3 { font-weight: 700; color: #B5B5B5; line-height: 32px; margin-bottom: 4px; }
.preview-col .content .heading h3 sub { font-size: 14px; bottom: 0;}

.package-btnrow {display: flex; flex-wrap: wrap; margin: 0 -10px;}
/* .package-btnrow > li {padding: 0 10px; flex: 1; list-style: none; position: relative;} */
.package-btnrow li div { font-size: 15px;}
.package-btnrow > li {padding: 0 10px; flex: 1; list-style: none; position: relative; flex: auto; flex-grow: 0; }
.package-btnrow > li > a {background-color: #FCFCFC; border: 1px solid #E5E5E5; border-radius: 10px; font-size: 16px; color: #000000; line-height: 30px; display: flex; align-items: stretch; padding: 14px 15px; width: 100%; justify-content: space-between;}
.package-btnrow > li > a > img {margin-left: 0; filter: brightness(1) invert(1);}

.package-btnrow > li > a.remove {background: #EC5453;color: #fff;width: 25px;height: 25px;display: flex;flex-direction: column;align-items: center;justify-content: center;position: absolute;top: 0;right: 0;opacity: 0;visibility: hidden;border-radius: 100%;margin: -3px; padding: 0; border: 0;}
.package-btnrow > li:hover > a.remove {opacity: 1; visibility: visible;}
.package-btnrow > li > a.remove > img {margin: 0; filter: none !important;}

.terms-content ul { list-style-type: disc; margin: 1em 0; padding-left: 40px; }
.terms-content ol { list-style-type:decimal; margin: 1em 0; padding-left: 40px; }
/* .terms-content table { border-collapse: collapse; width: 100%; }
.terms-content table { border: 1px solid black; }
.terms-content th,
.terms-content td { padding: 8px; border: 1px solid black; } */


.add-custom-package {padding: 16px 0;border-top: 1px solid #f8f7fa;border-bottom: 1px solid #f8f7fa;margin-top: 28px;}

.image-prevblock h6 {margin-bottom: 20px; font-weight: normal;}
.image-prevrow {display: flex; flex-wrap: wrap; margin-bottom: 44px;}
.image-prevrow .image-prevleft-col {width: calc(100% - 225px);}
.image-prevrow .image-prevleft-col .large-image {height: 290px; width: 100%; margin-bottom: 20px;}
.image-prevrow .image-prevleft-col .large-image img {height: 100%; width: 100%; object-fit: cover; object-position: center; border-radius: 12px;}
.image-prevrow .thumbnail-row {position: relative;}
.image-prevrow .thumbnail-row h6 {margin-bottom: 12px;}
.image-prevrow .thumbnail-row ul {display: flex; flex-wrap: wrap; margin: 0 -10px;}
.image-prevrow .thumbnail-row ul li {padding: 0 10px; width: 33.33%; list-style: none; margin-bottom: 10px; position: relative;}
.image-prevrow .thumbnail-row ul li .thumbnail {height: 90px; width: 100%;}
.image-prevrow .thumbnail-row ul li .thumbnail > img {height: 100%; width: 100%; object-fit: cover; object-position: center; border-radius: 10px;}
.image-prevrow .thumbnail-row ul li .thumbnail > a {background: #EC5453;color: #fff;width: 25px;height: 25px;display: flex;flex-direction: column;align-items: center;justify-content: center;position: absolute;top: 0;right: 0;opacity: 0;visibility: hidden;border-radius: 100%;margin: -3px;}
.image-prevrow .thumbnail-row ul li .thumbnail:hover > a {opacity: 1;visibility: visible;}
.image-prevrow .thumbnail-row .add-showcase {position: absolute;top: 50%;right: -60px; margin-top: -14px;}
.image-prevrow .thumbnail-row .add-showcase > a {width: 48px;height: 48px;border-radius: 45px;display: flex;align-items: center;justify-content: center;background: rgba(40, 198, 111, 0.1);}

.image-prevrow .image-prevright-col {width: 225px; padding-left: 30px;}
.image-prevrow .image-prevright-col ul {margin: 0 -7px; padding: 0; display: flex; flex-wrap: wrap;}
.image-prevrow .image-prevright-col ul > li {margin: 0; padding: 0 7px; list-style: none;}
.image-prevrow .image-prevright-col ul > li > a {display: flex; align-items: center;}
.image-prevrow .image-prevright-col ul > li > a img {max-width: 14px; margin-right: 14px;}
.image-prevrow .image-prevright-col ul > li > a.delete-btn {background: #EC5453; color: #fff; padding: 12px 14px; border: 0; min-height: 40px;}
.image-prevrow .image-prevright-col ul > li > a.delete-btn img {margin-right: 0;}

.image-prevrow .image-prevright-col .t-primary-btn {position: relative; z-index: 1;}
.image-prevrow .image-prevright-col .t-primary-btn input[type="file"] {position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 100%;height: 100%;opacity: 0;z-index: 10;cursor: pointer;}

.color-add-block h4 {font-size: 20px;font-weight: bold;color: #6259ce;line-height: 27px;margin-bottom: 30px;}
.color-add-block .btn {min-width: 155px;padding: 12px 15px;font-size: 14px;font-weight: bold;}

.stock-quantity .form-control {width: 85px;}
.stock-input-group  {position: relative;display: flex;flex-direction: row;align-items: center;}
.stock-input-group .form-control {width: 66px;}
.stock-input-group .unit {font-size: 14px;font-weight: 400;line-height: 14px;color: #8e8e8e; padding-left: 20px;}

.switch-control-box {display: flex;flex-direction: row;flex-wrap: wrap;margin: 36px -30px 0 -30px;}
.switch-control-box .switch-control {flex: 0 0 33.33%;max-width: 33.33%;padding: 0 30px;}
.switch-control h6 {color: #000;font-size: 14px;font-weight: 600;line-height: 19px;margin: 10px 0 3px 0;}
.switch-control p {color: #8e8e8e;font-size: 12px;font-weight: 400;line-height: 14px !important; margin-bottom: 0 !important;}

.delivery-estimate-field {display: flex;align-items: center;flex-direction: row;}
.delivery-estimate-field .estimate-field {max-width: 60px;}
.delivery-estimate-field .estimate-field .form-control {padding: 5px;text-align: center;}
.delivery-estimate-field  span.to {display: inline-flex;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 400;text-align: center;color: #8e8e8e;padding: 0 8px;}
.delivery-estimate-field .estimate-field-select {max-width: 100px;margin-left: 12px;}

.delivery-time-group .delivery-estimate-field .estimate-field {max-width: 165px;}
.delivery-time-group .delivery-estimate-field span.to {padding: 0 20px;text-transform: capitalize;}

.delivery-group-row {display: flex;flex-direction: row;flex-wrap: wrap;margin: 0 -15px;}
.delivery-group-row > .form-group:first-child, .delivery-group-row > .form-group:last-child {flex: 0 0 30%; max-width: 30%;}
.delivery-group-row > .form-group {flex: 0 0 40%;max-width: 40%;padding: 0 15px; flex-direction: column;}
/* .delivery-group-row > .form-group.interval-form-group {flex: 0 0 22%;max-width: 24%;} */
.delivery-group-row > .form-group .left-title {width: 100%;}
.delivery-group-row > .form-group .right-field {width: 100%;}
.delivery-group-row .days-group-control {flex-wrap: wrap;}
.delivery-group-row .days-group-control .days-select-col {flex: 0 0 45%; max-width: 45%;}
.delivery-group-row .days-group-control span {padding: 0 5px; flex: 0 0 10%; max-width: 10%; text-align: center;}

.measurement-field-control {position: relative;}
.measurement-field-control .measure-type {color: #8e8e8e;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 400;line-height: 1;position: absolute;top: 0;height: 100%;right: 16px;display: flex;flex-direction: column;align-items: center;justify-content: center;}
.measurement-field-control .form-control {padding-right: 60px;}

.dimension-input-group {display: flex;flex-direction: row;align-items: stretch;gap: 0 20px;}
.dimension-input-group .measurement-field-control {display: inline-flex;align-items: stretch;width: 100%;max-width: 125px; flex: 1;}
.dimension-input-group .measurement-field-control .form-control {padding-right: 40px;}

.price-package-group {display: flex;flex-direction: row;align-items: center;justify-content: space-between;}
.price-package-group .form-control {max-width: 184px;}
.price-package-group .total {max-width: calc(100% - 184px);padding: 0 20px 0 10px;text-align: right;}
.price-package-group .percent-input-group {position: relative;display: flex;flex-direction: row;align-items: center;}
.price-package-group .percent-input-group .form-control {width: 108px; margin-right: 15px;}
.price-package-group .percent-input-group span {font-size: 14px; font-weight: bold;}
.price-package-group.total-package {background: rgba(115, 103, 240, 0.1); max-width: 266px; padding: 16px 0; margin-left: auto; margin-top: 25px;}
.price-package-group.total-package {font-weight: bold;}
.price-package-group.total-package .total {max-width: 100%; width: 100%;}
.price-package-group.total-package .total span {display: inline-flex; align-items: center; margin-right: 70px;}

.color-pick-control {position: relative; flex: auto;}
.color-pick-control .form-control {padding-left: 44px;}
.color-pick-control .color-picker-item {position: absolute;top: 50%;width: 23px;height: 23px;display: inline-block;overflow: hidden;left: 12px;-webkit-transform: translateY(-50%);-moz-transform: translateY(-50%);-ms-transform: translateY(-50%);transform: translateY(-50%); border: 1px solid #f2f2f2; border-radius: 100%;}
.color-pick-control input[type="color"] {padding: 0;border: 0;margin: 0;outline: 0;background: transparent;width: 100%;height: 100%;cursor: pointer;}
.color-pick-control input[type=color]::-webkit-color-swatch {border: none;border-radius: 50%;padding: 0;}
.color-pick-control input[type=color]::-webkit-color-swatch-wrapper {border: none;border-radius: 50%;padding: 0;}

.track-order-process {margin-top: 25px;}
.process-track-order {position: relative;display: table;width: 100%;margin: 0;padding: 0;table-layout: fixed;list-style: none;}
.process-track-order > li {position: relative;display: table-cell;width: auto;text-align: center;vertical-align: top;-webkit-transition: 0.2s all ease-in-out;-moz-transition: 0.2s all ease-in-out;-ms-transition: 0.2s all ease-in-out;transition: 0.2s all ease-in-out;}
.process-track-order > li::before {content: '';background-color: #e5e5e5;position: absolute;left: 0;top: 0;display: block;width: 50%;height: 3px;-webkit-transition: 0.2s all ease-in-out;-moz-transition: 0.2s all ease-in-out;-ms-transition: 0.2s all ease-in-out;transition: 0.2s all ease-in-out;margin: 0 -1px;}
.process-track-order > li::after {content: '';background-color: #e5e5e5;position: absolute;right: 0;top: 0;display: block;width: 50%;height: 3px;-webkit-transition: 0.2s all ease-in-out;-moz-transition: 0.2s all ease-in-out;-ms-transition: 0.2s all ease-in-out;transition: 0.2s all ease-in-out;margin: 0;}
.process-track-order > li:first-child:before, .process-track-order > li:last-child:after {display: none;}
.process-track-order > li.active::before,.process-track-order > li.active::after,.process-track-order > li.completed::before,.process-track-order > li.completed::after {background: #6259ce;border-radius: 45px;}
.process-track-order .order-track-info {position: relative;padding: 54px 0 0 0;-webkit-transition: 0.2s all ease-in-out;-moz-transition: 0.2s all ease-in-out;-ms-transition: 0.2s all ease-in-out;transition: 0.2s all ease-in-out;}
.process-track-order .order-track-info::before {content: '';display: block;width: 35px;height: 35px;border: 5px solid #fff;position: absolute;top: 0;left: 0;right: 0;margin: -17.5px auto 0 auto;background: #e5e5e5;border-radius: 100%;z-index: 100;-webkit-transition: 0.2s all ease-in-out;-moz-transition: 0.2s all ease-in-out;-ms-transition: 0.2s all ease-in-out;transition: 0.2s all ease-in-out;}
.process-track-order > li.active .order-track-info::before, .process-track-order > li.completed .order-track-info::before {background: #6259ce;}
.process-track-order .order-track-info h6 {font-size: 14px;line-height: 14px;text-align: center;color: #8e8e8e;margin-bottom: 10px;}
.process-track-order > li.active .order-track-info h6, .process-track-order > li.completed .order-track-info h6 {font-weight: bold;font-size: 16px;color: #6259ce;}
.process-track-order .order-track-info p {color: #8e8e8e;font-size: 14px;line-height: 20px;text-align: center;}

.track-order-status {margin: 50px 0 0 0;}
.track-order-status .btn.active {border-radius: 10px 10px 0 0; background: #6259CE; color: #fff;}
.track-order-status .btn.active > img {-webkit-filter: brightness(0) invert(1);-moz-filter: brightness(0) invert(1);-ms-filter: brightness(0) invert(1);filter: brightness(0) invert(1);}
.track-order-status .track-order-block {position: relative;padding: 30px 15px;border-radius: 0px 10px 10px 10px;background: #fff;border: 1px solid #e5e5e5;display: none;}
.track-order-status .track-order-block .status-info {display: flex;flex-direction: row;flex-wrap: wrap;margin-bottom: 23px;}
.track-order-status .track-order-block .status-info .status-select {width: 251px;}
.track-order-status .track-order-block .status-info .edit-form-row {width: calc(100% - 251px);padding-left: 85px;}
.track-order-status .track-order-block .status-info .edit-form-row .form-group .left-title {width: 78px;}
.track-order-status .track-order-block .status-info .edit-form-row .form-group .right-field {width: calc(100% - 78px);}
.track-order-status .track-order-block .status-info .date-control-group {max-width: 50%;width: 100%;margin-right: 20px;}

.product-order-table.custom-table .table td .name-col .image {width: 125px;margin-right: 0;border-radius: 10px;background: #efefef;height: 90px;}
.product-order-table.custom-table .table .name-col .content {width: calc(100% - 125px); padding-left: 22px;}
.product-order-table.custom-table .table .name-col .content h6 {color: #000;font-weight: bold;}
.product-order-table.custom-table .table .name-col .content p {color: #8e8e8e;font-size: 14px;font-weight: 400;}
.product-order-table.custom-table .table {margin: 0;}
.product-order-table.custom-table .table td {color: #74788d;font-size: 14px;font-weight: 400;}
.product-order-table.custom-table .table tr td:last-child {text-align: right;}
.product-order-table.custom-table tfoot > tr > td {border: 0;padding: 0 !important;}
.product-order-table.custom-table .total-order {min-width: 281px;margin: 10px 0 0 0;}
.product-order-table.custom-table .total-order tr > td {color: #000;font-size: 16px;font-weight: 300;padding: 13px 20px;border: 0;text-align: left !important;}
.product-order-table.custom-table .total-order tr > td:last-child {text-align: right !important;}
.product-order-table.custom-table .total-order tr:last-child > td {border-top: 1px solid rgba(0, 0, 0, 0.06);}

.order-info-block .order-info-box {display: flex;flex-direction: row;flex-wrap: wrap;align-items: flex-start;margin-bottom: 20px;}
.order-info-block .order-info-box .image {width: 104px;height: 75px;border-radius: 10px;background: #efefef;overflow: hidden;}
.order-info-block .order-info-box .image img {width: 100%;height: 100%;object-fit: cover;border-radius: 10px;}
.order-info-block .order-info-box .content {width: calc(100% - 104px);padding: 10px 0 0 33px;}
.order-info-block .order-info-box .content h6 {font-weight: 500;}
.order-info-row {display: flex;flex-direction: row;flex-wrap: wrap;align-items: self-start;margin-bottom: 18px;}
.order-info-row:last-child {margin-bottom: 0;}
.order-info-row .title {width: 155px;}
.order-info-row .title h6 {color: #8e8e8e;font-size: 16px;font-weight: 400;line-height: 22px; word-wrap: break-word;}
.order-info-row .content {padding-left: 15px; width: calc(100% - 155px);}
.order-info-row .content p {color: #000;font-size: 16px;font-weight: 400;line-height: 22px; word-wrap: break-word;}
.order-info-row .content p > a {color: #6259CE;}
.order-id-row .title h6 {color: #6259CE !important; font-weight: 600;}

.order-info-block.delivery .order-info-row .title h6, .order-info-block.delivery .order-info-row .content p {font-weight: 600; color: #707070;}
.order-info-block.delivery .order-info-row .content p {word-break: break-word;}

.order-delivery-list .order-info-box {align-items: center; margin-bottom: 16px; padding-bottom: 16px; border-bottom: 1px solid rgba(181,181,181,0.1);}
.order-delivery-list .order-info-box:last-child {margin-bottom: 25px; border-bottom: 0; padding-bottom: 0;}
.order-delivery-list .order-info-box .content {padding-top: 0;}

.add-link-sm {display: inline-flex;align-items: center;font-family: 'Nunito', sans-serif;font-size: 16px;font-weight: bold;line-height: 30px;color: #6259ce;}
.add-link-sm:hover {color: #6259CE;}
.add-link-sm .icon {display: inline-flex;width: 20px;height: 20px;background: rgba(115, 103, 240, 0.25);align-items: center;justify-content: center;border-radius: 45px;margin-right: 24px;}
.add-link-sm .icon > img {width: 10px;}

.tags-control {position: relative;}
.tags-control .form-control {padding-right: 40px;}
.tags-control .add-tags {position: absolute;top: 50%;right: 15px; width: 20px; height: 20px; margin-top: -10px;border-radius: 45px;background: rgba(115, 103, 240, 0.25);display: flex;align-items: center;justify-content: center;flex-direction: column;text-align: center;}
.tags-control .add-tags > img {width: 10px;}

.selected-items-tag ul > li {position: relative;display: inline-block;padding: 8px 40px 8px 16px;background: #edebfd;border-radius: 45px;margin-right:7px;margin-top: 15px;color: #6259ce;font-family: 'Nunito', sans-serif;font-weight: normal;font-size: 14px;line-height: 1;text-align: left;min-width: 90px;word-break: break-all;}
.selected-items-tag ul > li > a {display: inline-flex;position: absolute;right: 16px;width: 10px;top: 50%;margin-top: -5px;}
.selected-items-tag ul > li > a:hover {color: #191919;}

.edit-form-row.full-form-row .left-title, .edit-form-row.full-form-row .right-field {width: 100%; padding: 0; margin: 0;}
.edit-form-row.full-form-row .left-title h6 {font-weight: 400;margin-bottom: 15px;}
.field-with-action {position: relative;display: flex;flex-flow: row nowrap;align-items: stretch;}
.field-with-action + .field-with-action {margin-top: 20px;}
.field-with-action > .form-control {width: calc(100% - 50px);}
.field-remove-btn {display: inline-flex;width: 40px;height: 40px;border-radius: 10px;background: #e5e5e5;align-items: center;justify-content: center;margin-left: 10px;}
.field-remove-btn:hover {background: #EA5455;}

.varient-type-group {display: flex;flex-flow: row wrap;align-items: flex-start;justify-content: space-between;}
.varient-type-group + .varient-type-group {margin-top: 30px;}
.varient-type-group hr {width: 100%;border-color: #f8f7fa;display: block;margin: 20px 0 10px 0;}
.varient-type-group .varient-info {padding-right: 15px; width: calc(100% - 125px);}
.varient-type-group .varient-info h6 {color: #000;font-size: 14px;font-weight: bold;line-height: 1;margin-bottom: 5px;}
.t-grey-btn-outline.edit-btn, .t-danger-btn-outline.edit-btn {border-radius: 5px;padding: 7px 11px; font-weight: 400;}
.varient-list > ul > li {position: relative;display: inline-flex;align-items: center;justify-content: center;border-radius: 10px;background: #e5e5e5;color: #000;padding: 3px 12px;font-family: 'Nunito', sans-serif;font-size: 12px;font-weight: 500;line-height: 14px;margin: 0 5px 5px 0;}
.varient-list > ul > li:last-child {margin-right: 0;}
.varient-list > ul > li .color-pick-control {margin: 0 7px 0 -7px; width: 16px; height: 16px;}
.varient-list > ul > li .color-pick-control .color-picker-item {left: 0;width: 16px;height: 16px;}

.select-varient-filter {position: relative; margin: 16px 0; display: flex; flex-flow: row wrap; justify-content: space-between; align-items: center;}
.select-varient-filter ul > li {position: relative;display: inline-flex;align-items: center;margin-right: 26px;}
.select-varient-filter ul > li:last-child {margin-right: 0;}
.select-varient-filter ul > li > a {color: #6259ce;font-family: 'Nunito', sans-serif;font-size: 13px;font-weight: 600;line-height: 1;display: inline-flex;align-items: center;}
.select-varient-filter ul > li:first-child > a {color: #000;}
.select-varient-filter ul > li > a:hover {color: #000;}
.select-varient-filter ul > li:first-child > a:hover {color: #6259CE;}
.select-varient-filter ul > li > a .icon {display: inline-flex; align-items: center; margin-left: 8px;}
.select-varient-filter .filter-btn .btn {font-size: 12px;padding: 7px 10px;border-radius: 5px;font-weight: 600;min-width: 60px;}

.varient-product-table .table tr th {font-size: 12px; white-space: nowrap;}
.varient-product-table .table tr td, .varient-product-table .table tr th {padding: 12px 10px; border: 0;}
.varient-product-table.custom-table .table td .c-checkbox {width: 14px;}
.varient-product-table.custom-table .table td .thumb-img {background: #f8f7fa;border: 1px dashed #e5e5e5;border-radius: 5px;width: 30px;height: 30px;}
.varient-product-table.custom-table .table td {font-size: 13px;}
.varient-product-table.custom-table .table thead tr > th:last-child, .varient-product-table.custom-table .table tbody tr > td:last-child {position: -webkit-sticky;position: sticky;right: 0;z-index:1;}
.varient-product-table.custom-table .table tbody tr > td:last-child {background: #fafafa;}
.varient-product-table th .add-remove-btn, .varient-product-table td .add-remove-btn {display: flex;align-items: center;justify-content: center;text-align: center;width: 25px;height: 25px;background: rgba(115, 103, 240, 0.1);}
.varient-product-table th .action-btn {width: 24px;height: 24px;display: flex;align-items: center;justify-content: center;margin-left: auto;}

.thumb-flex {display: flex;flex-direction: row;flex-wrap: wrap;align-items: center;}
.thumb-flex .thumb-name {width: calc(10% - 30px); padding: 0 15px; white-space: nowrap;}

.varient-product-table .addon-item {background: transparent !important;border: 1px solid #f5f6f8 !important;}
.varient-product-table .addon-item > .c-checkbox {padding-left: 28px !important;}

.price-field-control {position: relative;}
.price-field-control .unit {position: absolute;top: 0;left: 15px;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 400;line-height: 1;text-align: left;color: #000;z-index: 1;height: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;}
.price-field-control .form-control {padding-left: 50px;}

.price-field-control.percent .unit {position: absolute;top: 0;left: auto;right: 12px;}
.price-field-control.percent .form-control {padding-right: 30px; padding-left: 16px;}

.varient-product-table.custom-table .table td a.t-grey-btn-outline:hover {color: #fff;}
.varient-product-table .select2-container {max-width: 50px;}
.varient-product-table .select2-container--default .select2-selection--single {border: 0; background-color: rgba(229,229,229,0.28);}
.varient-product-table .select2-container--default .select2-selection--single .select2-selection__rendered {padding: 5px 20px 5px 10px;line-height: 1.5;}
.varient-product-table .select2-container--default .select2-selection--single .select2-selection__arrow {background-image: url('../images/caret-bottom-blue.svg');width: 8px;right: 10px;}

.varient-product-table .price-field-control .unit {font-size: 12px; color: #8e8e8e;}
.varient-product-table .price-field-control .form-control {padding-left: 40px; width: 150px;}

.coupon-type-tabs {padding-right: 15px; display: flex; flex-direction: row; align-items: center;}
.coupon-type-tabs .tabs {display: flex;background: #fff;border: 1px solid #f3f3f3;overflow: hidden;border-radius: 10px;width: 200px;}
.coupon-type-tabs .tabs > li {display: inline-flex;flex: 0 0 50%;max-width: 50%;}
.coupon-type-tabs .tabs > li > a {color: #000;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 400;line-height: 1;text-align: center;padding: 12px 15px;border-radius: 10px;display: inline-flex;align-items: center;justify-self: center;justify-content: center;overflow: hidden;width: 100%;}
.coupon-type-tabs .tabs > li.active > a {background: #6259CE;color: #fff;font-weight: bold;}

.shipping-filters-list {position: relative;margin-left: 70px;}
.shipping-filters-list ul {display: flex;flex-direction: row;background: #fff;border: 1px solid #f3f3f3;overflow: hidden;border-radius: 10px;}
.shipping-filters-list ul > li {display: inline-flex;}
.shipping-filters-list ul > li .c-radio {position: relative;}
.shipping-filters-list ul > li .c-radio input[type="radio"] {position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 100%;height: 100%;opacity: 0;visibility: hidden;}
.shipping-filters-list ul > li .c-radio label {position: relative;margin: 0;font-family: 'Nunito', sans-serif;font-weight: 400;font-size: 14px;line-height: 18px;text-align: left;color: #1e1e1e;cursor: pointer;padding: 10px 14px;display: flex;flex-direction: row;align-items: center;justify-content: center;transition: 0.3s all ease-in-out;}
.shipping-filters-list ul > li .c-radio input[type="radio"]:checked + label {background: #6259CE;color: #fff;border-radius: 10px;font-weight: bold;}
.shipping-filters-list ul > li .c-radio label::before {display: none;}
.shipping-filters-list ul > li .c-radio label::after {display: none;}

.sell-coupon-block .t-card-primary > .card-box-header {padding: 9px 30px;margin: 0;}
.sell-coupon-block .t-card-primary > .card-box-header .card-title {margin: 0;}

.sell-coupon-block .sub-title {margin-bottom: 16px; font-weight: bold; color: #000;}
.sell-coupon-block .check-list {margin: 0;}

.grey-link {color: #707070;display: block;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 400;margin: 15px 0;}
.grey-link:hover {color: #000;}

.tag-selected {border-radius: 10px;background: #fff;border: 1px solid #6259ce;min-height: 45px;display: flex;flex-direction: column;align-items: flex-start;justify-content: center;padding: 4px 8px;max-width: 329px;margin: 0 0 26px 0;}
.tag-selected > .selected-tag {position: relative;display: inline-block;padding: 6px 10px;background: #edebfd;border-radius: 5px;color: #1e1e1e;font-family: 'Nunito', sans-serif;font-weight: 600;font-size: 16px;line-height: 1;min-width: 90px;word-break: break-all;text-align: center;border: 1px dashed #6259CE;}

.cross-sell-filter-product {margin-bottom: 24px;display: flex;flex-direction: row;}
.cross-sell-filter-product .btn {padding: 12px 30px;min-width: 252px;}
.cross-sell-product-table .table {margin-bottom: 0;}
.cross-sell-product-table .table tr:first-child td {border-top: 1px solid #F8F7FA;}
.cross-sell-product-table .table td {border-color: #F8F7FA;font-weight: 600;}
.cross-sell-product-table .table tr:last-child td {border-bottom: 0;}
.cross-sell-product-table + .add-custom-package {margin: 0;}
.cross-sell-product-table .trigger-table {border: 1px solid #6259CE;border-radius: 10px;border-collapse: inherit;}

.cross-sell-product-table .table td .add-link-sm.grey .icon {margin-right: 0; background: rgba(142, 142, 142, 0.25);}
.cross-sell-product-table .table td .add-link-sm.grey:hover .icon {background: rgba(142, 142, 142, 0.5);}

.invoice-finance-settings .t-badge-o {border-radius: 5px; min-width: 80px;}

.supports-settings .coupon-type-tabs {width: 330px;}

.supports-settings .filter-tabs { width: 440px }

.supports-settings .coupon-type-tabs .tabs {width: 100%;}

.ticket-list-table.custom-table .table td {font-weight: 400;}
.ticket-list-table.custom-table .table .name-col .content {font-weight: bold;}
.ticket-list-table .name-col .content .ide {color: #1e1e1e;display: block;font-size: 12px;line-height: 14px;margin: 10px 0 0;font-weight: 400;}
.ticket-list-table .t-badge.green {color: #fff;background: #34c38f;}
.ticket-list-table .t-badge.gray {color: #707070;background: #e5e5e5;}
.ticket-list-table .t-badge {color: #fff;background: #6259CE;}
.ticket-list-table .t-badge.red {color: #fff;background: #EA5455;}
.ticket-list-table .action-table > ul {display: flex;flex-direction: row;justify-content: flex-end;align-items: center;}
.ticket-list-table .action-table > ul > li {display: inline-flex;align-items: center;margin-right: 35px;}
.ticket-list-table .action-table > ul > li:last-child {margin-right: 0;}
.ticket-list-table .action-table > ul > li > .action-link {position: relative;width: 22px;}
.ticket-list-table .action-table > ul > li > .action-link .count {color: #fff;width: 20px;height: 20px;background: #7367f0;border: 2px solid #fff;position: absolute;top: -10px;right: -10px;border-radius: 45px;display: flex;align-items: center;justify-content: center;text-align: center;font-size: 10px;font-weight: 400;}
.action-link.upload-attachment {position: relative;margin-bottom: 0;display: inline-flex;cursor: pointer; width: 180px;}
.action-link.upload-attachment input[type="file"] {position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 100%;height: 100%;opacity: 0;visibility: hidden;display: none;}

.multiple-links .date-range-pick {margin-left: 0;}
.multiple-links .date-range-pick .range-btn {line-height: 1; padding: 11px 15px 11px 50px;}

.request-invoice-ticket .head-info {position: relative;background: #e5e5e5;border-radius: 10px;border: 1px solid #e5e5e5;padding: 18px 25px 25px 18px;display: flex;flex-flow: row wrap;align-items: center;justify-content: space-between;}
.request-invoice-ticket .head-info .personal-info {padding-right: 15px;}
.request-invoice-ticket .head-info .personal-info .name {color: #1e1e1e;font-size: 16px;font-weight: bold;line-height: 22px;}
.request-invoice-ticket .head-info .personal-info .amount-paid {margin-top: 5px; font-size: 16px; color: #000;}
.request-invoice-ticket .head-info .personal-info .amount-paid strong {display: inline-flex; margin-left: 20px;}
.request-invoice-ticket .head-info .personal-info > ul {margin-top: 5px;}
.request-invoice-ticket .head-info .personal-info > ul > li {position: relative;display: inline-flex;align-items: center;padding-right: 15px;margin-right: 16px;}
.request-invoice-ticket .head-info .personal-info > ul > li::after {content: '';display: block;width: 1px;height: 100%;background: #000;border-radius: 15px;position: absolute;top: 0;right: 0;}
.request-invoice-ticket .head-info .personal-info > ul > li:last-child {padding-right: 0;margin-right: 0;}
.request-invoice-ticket .head-info .personal-info > ul > li:last-child::after {display: none;}
.request-invoice-ticket .head-info .ordernumber {border-radius: 10px;background: #fff;border: 1px solid #c5c5c5;display: inline-flex;padding: 11px 15px;font-family: 'Nunito', sans-serif;font-size: 16px;text-align: center;color: #000;}
.request-invoice-ticket.ticket-chat-thread .thread-block {position: relative;padding: 25px;max-width: 100%;background: #fff;border: 1px solid #e5e5e5;border-radius: 10px;margin-top: -15px;}
.ticket-chat-thread .thread-block .image-row {display: flex;flex-flow: row wrap;align-items: stretch;margin: 30px 0 0 0;gap: 20px;}
.ticket-chat-thread .thread-block .image-row .img-item {position: relative;flex: 0 0 182px;max-width: 182px;width: 100%;height: 98px; overflow: hidden; border-radius: 10px;}
.ticket-chat-thread .thread-block .image-row .img-item a > img, .ticket-chat-thread .thread-block .image-row .img-item > img {width: 100%;height: 100%;object-fit: cover;border-radius: 10px;}
.ticket-chat-thread .thread-block .image-row .img-item .count {position: absolute;top: 0;right: 0;margin: -5px;display: flex;flex-direction: column;align-items: center;justify-content: center;width: 20px;height: 20px;color: #fff;background: #707070;border: 2px solid #fff;text-align: center;font-size: 10px;font-weight: 400;border-radius: 45px;}

.ticket-todo-list ul {max-height: 435px; overflow-y: auto;}
.ticket-todo-list ul > li {position: relative;display: flex;flex-flow: row wrap;align-items: center;justify-content: space-between;padding: 20px;border-radius: 20px;background: #fafafa;border: 1px solid #e5e5e5;margin-bottom: 20px;}
.ticket-todo-list ul > li .info {width: calc(100% - 176px);padding-right: 10px;}
.ticket-todo-list ul > li .info h6 {color: #000;font-size: 16px;font-weight: bold;line-height: 22px;margin-bottom: 10px;}
.ticket-todo-list ul > li .info p {color: #1e1e1e;font-size: 12px;line-height: 14px;font-weight: 400;}
.ticket-todo-list ul > li .btn {width: 176px;padding: 13px 15px;}

.invoice-done-box {position: relative;border-radius: 20px;background: #fff;box-shadow: 0px 2px 6px rgba(36, 41, 70, 0.05);padding: 20px;border: 1px solid #e5e5e5;margin-bottom: 17px;}
.invoice-done-box .head-info {position: relative;display: flex;flex-flow: row wrap;align-items: center;justify-content: space-between;}
.invoice-done-box .head-info .info {width: calc(100% - 176px);padding-right: 10px;}
.invoice-done-box .head-info .info h6 {color: #1e1e1e;font-size: 16px;font-weight: bold;line-height: 22px;margin-bottom: 10px;}
.invoice-done-box .head-info .info p {color: #1e1e1e;font-size: 12px;line-height: 14px;font-weight: 400;}
.invoice-done-box .head-info .btn {width: 176px;padding: 13px 15px;}
.invoice-done-box .head-info .btn .icon {margin-right: 10px;}
.invoice-done-box .timeline-request {padding: 20px 0 0 25px;}
.invoice-done-box .timeline-request ul > li {padding-bottom: 27px;padding-left: 30px;}
.invoice-done-box .timeline-request ul > li:last-child {padding-bottom: 0;}
.invoice-done-box .timeline-request ul > li p {color: #1e1e1e;font-size: 14px;font-weight: 300;line-height: 1;margin-bottom: 2px;}
.invoice-done-box .timeline-request ul > li::before {width: 14px;height: 14px;}
.invoice-done-box .timeline-request ul > li::after {height: calc(100% - 20px);top: 22px;left: 6px;}
.invoice-done-box .timeline-request ul > li.completed::before {width: 20px;height: 20px;left: -2px;}

.shipping-info-row {display: flex;flex-wrap: wrap;flex-direction: row;align-items: flex-start;justify-content: space-between;padding-bottom: 17px;margin-bottom: 40px;border-bottom: 1px solid rgba(181,181,181,0.1);}
.shipping-info-row .address-info {max-width: 287px;width: 100%;}
.shipping-info-row .address-info .label-img {width: 100%;overflow: hidden;height: 74px;background: #efefef;border-radius: 10px;margin-bottom: 25px;}
.shipping-info-row .address-info .label-img > img {width: 100%;height: 100%;object-fit: cover;object-position: center;border-radius: 10px;}
.shipping-info-row .address-info address {color: #000;font-family: 'Nunito', sans-serif;font-size: 16px;font-weight: 400;line-height: 23px;margin-bottom: 13px;}
.shipping-info-row .address-info .telephone {color: #000;font-family: 'Nunito', sans-serif;font-size: 16px;font-weight: 400;}
.shipping-info-row .address-info .telephone span {color: #abafb3;}
.shipping-info-row .order-info {max-width: 352px;width: 100%;}
.shipping-info-row .order-info h2 {color: #7367f0;}
.shipping-info-row .order-info .order-table {margin: 40px 0 0 0;}
.shipping-info-row .order-info .order-table .order-content {display: flex;flex-direction: row;justify-content: space-between;flex-wrap: wrap;font-family: 'Nunito', sans-serif;font-size: 16px;line-height: 22px;font-weight: 400;color: #000;}
.shipping-info-row .order-info .order-table .order-content .left {color: #8e8e8e;padding: 5px 0;}
.shipping-info-row .order-info .order-table .order-content .right {color: #000;padding: 5px 0;}
.shipping-info-row .order-info .order-table .order-content:last-child .left, .shipping-info-row .order-info .order-table .order-content:last-child .right {padding-bottom: 0;}

.invoice-only .shipping-info-row .address-info {margin-bottom: 30px;}
.invoice-only .shipping-info-row .order-info {max-width: max-content;}
.invoice-only .shipping-info-row .order-info .order-table {margin-top: 20px;}
.invoice-only .shipping-info-row .order-table .left {min-width: 180px;}
.invoice-only .shipping-info-row .invoice-qr-info {padding-left: 30px;}

.invoice-billing-row {display: flex;flex-wrap: wrap;flex-direction: row;margin-bottom: 27px;align-items: flex-start;}
.invoice-billing-row .item-block {width: calc(100% - 332px);padding-right: 15px;display: flex;flex-direction: row;flex-wrap: wrap;margin: 0 -15px;}
.invoice-billing-row .item-block .item-info {flex: 0 0 50%;max-width: 50%;padding: 0 15px;}
.invoice-billing-row .item-block .item-info h4 {color: #7367f0;margin-bottom: 25px; font-weight: bold;}
.invoice-billing-row .item-block .item-info h6 {color: #000;font-size: 18px;margin-bottom: 16px;font-weight: bold;}
.invoice-billing-row .item-block .item-info p {color: #000;font-size: 16px;line-height: 24px;}

.invoice-only .invoice-billing-row.full-content .item-block {margin: 0 -15px;}
.invoice-only .invoice-billing-row.full-content .item-block .item-info {padding: 0 15px; flex: 0 0 22%; max-width: 22%;}
.invoice-only .invoice-billing-row.full-content .item-block .item-info:last-child {flex: 0 0 34%; max-width: 34%;}

.custom-table .table td .product-name h5 {font-weight: bold; font-size: 16px;}
.custom-table .table td .product-name p {font-size: 12px; color: #8e8e8e; margin-top: 4px;}

.invoice-billing-row.full-content .item-block {width: 100%; padding: 0; flex: 0 0 auto;justify-content: space-between; margin: 0;}
.invoice-billing-row.full-content .item-block .item-info {flex: 0 0 auto; max-width: 100%; padding: 0;}

.bill-info-table .bill-content {display: flex;flex-direction: row;flex-wrap: wrap;font-family: 'Nunito', sans-serif;font-size: 16px;line-height: 22px;font-weight: 400;color: #000;}
.bill-info-table .bill-content .left {color: #8e8e8e;padding: 5px 0;width: 155px;}
.bill-info-table .bill-content .right {color: #000;padding: 5px 0;width: calc(100% - 155px);padding-left: 30px;}

.invoice-billing-row .barcode-block {width: 352px;padding: 15px 15px 32px 15px;border-radius: 10px;background: rgba(112, 112, 112, 0.1);text-align: center;}
.invoice-billing-row .barcode-block h4 {color: #000;font-size: 20px;line-height: 24px;margin-bottom: 20px;}
.invoice-billing-row .barcode-block.qrcode {background: transparent; padding: 0; margin-top: -30px;}

.invoice-transaction-table {margin-bottom: 48px;}
.invoice-transaction-table.custom-table .table td {padding: 25px 20px; font-weight: 400;}
.invoice-transaction-table.custom-table .table tfoot tr > th {padding: 0;}
.invoice-transaction-table.custom-table .table tfoot tr > td {border: 0; padding: 0;}
.invoice-transaction-table.custom-table .table tfoot tr > td > table {margin-left: auto; margin-top: 18px;}
.invoice-transaction-table.custom-table .table tfoot tr > td > table tr th, .invoice-transaction-table.custom-table .table tfoot tr > td > table tr td {padding: 9px 20px; border: 0;}
.invoice-transaction-table.custom-table .table tfoot tr > td > table tr th:last-child, .invoice-transaction-table.custom-table .table tfoot tr > td > table tr td:last-child {text-align: right;}
.invoice-transaction-table.custom-table .table tfoot tr > td > table tr.total-td th {background: rgba(115, 103, 240, 0.07);padding-top: 11px;padding-bottom: 11px;}
.invoice-transaction-table.custom-table .table tfoot tr > td > table tr.total-td {position: relative;top: 10px;}

.invoice-note {border-top: 1px solid rgba(181,181,181,0.1);padding-top: 24px;}
.invoice-note h6 {margin-bottom: 17px;}
.invoice-note p {font-size: 16px;font-weight: 300;line-height: 22px;}

.alert {display: flex;flex-flow: row wrap;align-items: flex-start;border-radius: 15px;padding: 20px;font-family: 'Nunito', sans-serif;font-weight: bold;font-size: 14px;line-height: 1;text-align: left;color: #000;}
.alert .icon {width: 15px;}
.alert .icon + .content {width: calc(100% - 20px);padding-left: 20px;}
.alert.alert-warning {background: rgba(255, 251, 187, 0.44);box-shadow: 0px 2px 4px rgba(36, 41, 70, 0.05);border: 0;}

.data-transfer-form {margin: 44px 0 0 0;}
.setting-group-block {border-bottom: 1px solid rgba(112, 112, 112, 0.17);padding-bottom: 35px;margin-bottom: 45px;}
.setting-group-block:last-child {border-bottom: 0;padding-bottom: 0;}
.setting-group-block .edit-form-row {padding-left: 74px;max-width: 854px;position: relative;}
.setting-group-block .setting-title {font-weight: bold;margin-bottom: 35px;}
.edit-form-row .form-group .change-field.download-file {color: #6259ce;font-size: 14px;font-weight: bold;margin: 10px 0 0;}

.data-transfer-form .edit-form-row .form-group .left-title {width: 210px;}
.data-transfer-form .edit-form-row .form-group .left-title h6 {font-weight: bold;}
.data-transfer-form .edit-form-row .form-group .right-field {width: calc(100% - 210px);}
.data-transfer-form .edit-form-row .form-group .sm-control {max-width: 260px;}
.data-transfer-form .edit-form-row .form-group .c-checkbox {margin: 10px 0;}
.data-transfer-form .edit-form-row .form-group .note-field {margin-left: 16px;}
.data-transfer-form .select2-container--default .select2-selection--single .select2-selection__arrow {background-image: url('../images/select-down-arrow-light-grey.svg');}

.validation-alert-card .alert-danger {border-radius: 15px 15px 0 0;margin: 0;}
.validation-alert-card .alert p {font-weight: 300;}
.validation-alert-card .alert-warning p {margin: 10px 0;}
.validation-alert-card .alert-warning .alert-danger {border-radius: 0;margin: 10px 0 30px 0;}
.validation-alert-card .alert-warning .alert-danger p {margin: 0; word-break: break-all; line-height: 1.4;}

.choose-file-btn label {position: relative;display: inline-flex;align-items: center;justify-content: center;border-radius: 10px;background: #e5e5e5;border: 1px solid #e5e5e5;padding: 9px 16px;color: #000;font-size: 16px;font-weight: 400;line-height: 22px;text-align: center;min-width: 129px;cursor: pointer;margin: 0;}
.choose-file-btn label input[type="file"] {position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 100%;height: 100%;opacity: 0;visibility: hidden;}

.choose-file-btn + .choosed-document {margin-top: 15px;}

.choosed-document {position: relative;display: flex;flex-flow: row wrap;align-items: center;justify-content: space-between;border: 1px solid #e5e5e5;border-radius: 10px;padding: 15px 50px 15px 15px;margin: 0 0 15px 0;font-size: 14px; color: #000;}
.choosed-document .file-name, .choosed-document .file-name > a {font-size: 14px; color: #000; font-weight: 600;}
.choosed-document .remove {position: absolute;top: 50%;width: 20px;height: 20px;margin-top: -10px;z-index: 10;right: 15px;}

.import-data-table.custom-table .table th, .import-data-table.custom-table .table td {border: 1px solid rgba(112, 112, 112, 0.17);}
.import-data-table.custom-table .table td {vertical-align: top;padding: 20px;font-weight: 400;}
.import-data-table.custom-table .table td .form-control {width: 210px;}
.import-data-table.custom-table .table th:first-child, .import-data-table.custom-table .table td:first-child {border-left: 0;}
.import-data-table.custom-table .table th:last-child, .import-data-table.custom-table .table td:last-child {border-right: 0;}
.import-data-table.custom-table .table .download-file {text-decoration: underline;color: #6259ce;font-weight: 400;}

.filter-record-div {position: relative;margin-top: -45px; margin-bottom: 30px;}
.filter-record-div .filter-btn {border: 1px solid #6259ce; display: flex; margin-left: auto;}
.filter-record-div .filter-btn img {-webkit-filter: brightness(0) invert(1);-moz-filter: brightness(0) invert(1);-ms-filter: brightness(0) invert(1);filter: brightness(0) invert(1);}
.filter-record-div .filter-btn:hover, .filter-record-div .filter-btn.active {background: #fff;color: #6259CE;}
.filter-record-div .filter-btn:hover img, .filter-record-div .filter-btn.active img {filter: none !important;}
.filter-record-div .filter-content {position: relative;top: 100%;left: 0;right: 0;width: 100%;border-radius: 15px;background: #f8f7ff;border: 1px solid #7367f0;box-shadow: 0px 2px 4px rgba(36, 41, 70, 0.05);padding: 40px;display: none;margin-top: 15px;}
.filter-record-div .filter-content::before {content: '';display: inline-block;width: 13px;height: 13px;background: #f8f7ff;border: 1px solid #7367f0;position: absolute;top: -8px;right: 85px;border-right: 0;border-bottom: 0;-moz-transform: rotate(45deg);-ms-transform: rotate(45deg);-webkit-transform: rotate(45deg);transform: rotate(45deg);-webkit-transition: 0.2s all ease;-moz-transition: 0.2s all ease;-ms-transition: 0.2s all ease;transition: 0.2s all ease;}

.filter-selection {display: flex;flex-direction: row;flex-wrap: wrap;gap: 0 40px;}
.filter-selection .select-item {flex: 0 0 201px;max-width: 201px;}
.filter-selection .select-item:first-child {max-width: 121px;flex: 0 0 121px;}
.filter-selection .select-item .form-control {background-color: #fff;border-color: #f3f3f3;min-height: 50px;}
.filter-selection .select2-container--default .select2-selection--single {border-color: #f3f3f3;}
.filter-selection .select2-container--default .select2-selection--single .select2-selection__rendered {padding: 11px 40px 11px 16px;}
.filter-selection .select-item .form-control.date-time-control {background-image: url('../images/calenda-icon.svg');background-position: center right 15px;}
.filter-selection .form-floating {position: relative;}
.filter-selection .form-floating .floating-label {font-size: 10px;color: #8e8e8e;background: #fff;position: absolute;top: -10px;left: 15px;padding: 5px;}
.filter-content .select-all {font-family: 'Nunito', sans-serif;font-size: 15px;font-weight: bold;line-height: 18px;color: #6259ce;display: inline-flex;margin-bottom: 16px;margin-top: 10px;}
.filter-content .t-grey-btn-outline {background: #fff;}
.filter-content .t-grey-btn-outline:hover {background: #8e8e8e;}

.multiple-checkbox-group .c-checkbox {display: inline-flex;align-items: center;margin: 0 45px 10px 0;min-height: 19px;}
.multiple-checkbox-group .c-checkbox:last-child {margin-right: 0;}
.multiple-checkbox-group .c-checkbox label::before {border: 1px solid #707070;border-radius: 0;width: 19px;height: 19px;}
.multiple-checkbox-group .c-checkbox label::after {border-radius: 0;width: 19px;height: 19px;background-size: 15px;}

.entity-data-table .sales-transaction-table.custom-table .table {border: 1px solid #f6f6f6;}
.entity-data-table .sales-transaction-table.custom-table .table th {padding: 15px;border: 1px solid #f6f6f6;}
.entity-data-table .sales-transaction-table.custom-table .table td {padding: 12px 15px;border: 1px solid #f6f6f6;}

.filter-field .range-date {display: table;margin: 0 -5px;width: 100%;}
.filter-field .range-date .form-floating {display: table-cell;padding: 0 5px;}
.filter-field .range-date .form-floating .floating-label {font-size: 14px;font-weight: 600;margin-bottom: 3px;display: inline-block;}
.filter-field  .form-control.date-time-control {background-image: url(../images/calenda-icon.svg);background-position: center right 15px;}

.wallet-stats-box .card-box .card-box-body {position: relative;padding: 29px 25px;border-radius: 10px;background: #7367f0;box-shadow: 0px 2px 6px rgba(36, 41, 70, 0.05);margin-bottom: 30px;}
.wallet-balance-block {position: relative;display: flex;flex-flow: row wrap;align-items: center;justify-content: space-between;}
.wallet-balance-block .balance-info {color: #fff;padding-right: 15px;width: calc(100% - 180px);}
.wallet-balance-block .balance-info h3 {color: #fff;font-size: 22px;font-weight: bold;line-height: 30px;margin-bottom: 5px;}
.wallet-balance-block .balance-info h1 {color: #fff;font-size: 40px;line-height: 54px;margin-left: 5px;}
.wallet-balance-block .balance-info h1 .currency {font-size: 30px;display: inline-flex;align-items: flex-end;margin-right: 10px;line-height: 1;}
.wallet-balance-block .payout-request .t-white-btn {width: 100%;display: flex;align-items: center;justify-content: center;padding: 13px 15px;}
.wallet-balance-block .payout-request .t-white-btn > img {margin-right: 10px;display: inline-flex;align-items: center;}

.payout-request-body .payout-status {position: absolute;top: 14px;right: 10px;font-weight: bold;font-size: 10px;text-transform: uppercase;text-align: center;color: #fff;display: inline-flex;align-items: center;justify-content: center;border-radius: 5px;padding: 4px 8px;min-width: 114px;}
.payout-request-body .payout-status > .info-icon {display: inline-flex;align-items: center;}
.payout-request-body .payout-status > .info-icon::after {display: none;}
.payout-request-body .payout-status > .info-icon img {width: 10px;filter: brightness(0) invert(1);margin-left: 7px;}


.dropdown-menu.payout-info-box {border-radius: 8px;background: #fff;box-shadow: 0px 4px 16px rgba(16, 24, 40, 0.12);padding: 14px;border-top: 5px solid #f1b44c;min-width: 400px;z-index: 1000;text-align: left;margin-top: -30px;}
.dropdown-menu.payout-info-box::before {content: '';position: absolute;top: 26px;left: -4px;display: inline-flex;width: 8px;height: 8px;border-radius: 2px;background: #fff;transform: rotate(45deg);z-index: -1;}
.payout-info-box {text-transform: initial;}
.payout-info-box .t-badge-o.yellow {border-radius: 5px;background: rgba(241, 180, 76, 0.06);border: 1px dashed #f1b44c;font-size: 10px;line-height: 1;text-align: left;color: #000;padding: 4px 10px;}
.payout-info-box .payout-info-detail {display: flex;flex-wrap: wrap;align-items: center;margin: 15px 0;}
.payout-info-box .payout-info-detail .payout-title {width: calc(100% - 140px);padding-right: 10px;}
.payout-info-box .payout-info-detail .payout-title h6 {font-size: 16px;font-weight: bold;line-height: 22px;color: #000;text-transform: capitalize;}
.payout-info-box .payout-info-detail .payout-title p {font-size: 12px;font-weight: 400;color: #8e8e8e;text-transform: capitalize;margin-top: 2px;}
.payout-info-box .payout-info-detail .price-field-control {width: 140px;}
.payout-info-box .payout-info-detail .price-field-control .unit {font-size: 10px;color: #000;font-weight: 600;left: 10px;}
.payout-info-box .payout-info-detail .price-field-control .form-control {background: rgba(241, 180, 76, 0.05);border: 1px solid #f1b44c;box-shadow: 0px 2px 6px rgba(36, 41, 70, 0.05);font-weight: 600;font-size: 16px;line-height: 1.1;text-align: left;color: #000;}
.payout-info-box .account-info {position: relative;border-radius: 8px;background: #f7f7f7;padding: 25px 44px 19px 21px;margin-bottom: 10px;}
.payout-info-box .account-info .edit-acc-details {position: absolute;top: 5px;right: 5px;background: #fff;width: 23px;height: 23px;border-radius: 6px;display: inline-flex;align-items: center;justify-content: center;}
.payout-info-box .account-info .edit-acc-details:hover {background: #8E8E8E;}
.payout-info-box .account-info .edit-acc-details:hover > img {filter: brightness(0) invert(1);}
.payout-info-box .account-info .edit-acc-details > img {width: 10px;}
.payout-info-box .account-info ul > li {display: flex;flex-direction: row;align-items: flex-start;margin-bottom: 10px;}
.payout-info-box .account-info ul > li:last-child {margin-bottom: 0;}
.payout-info-box .account-info ul > li .left-label {color: #707070;font-family: 'Nunito', sans-serif;font-size: 11px;font-weight: 300;line-height: 15px;margin-right: 30px;min-width: 147px;text-transform: capitalize;}
.payout-info-box .account-info ul > li .right-label {color: #000;font-family: 'Nunito', sans-serif;font-size: 11px;font-weight: bold;line-height: 15px;word-break: break-all;text-transform: capitalize;}
.payout-info-box .request-note {display: flex;flex-direction: row;align-items: center;justify-content: space-between;}
.payout-info-box .request-note .alert {border-radius: 4px;background: rgba(241, 180, 76, 0.06);box-shadow: 0px 2px 6px rgba(36, 41, 70, 0.05);margin: 5px 0;}
.payout-info-box .request-note .alert {color: #000;    font-size: 8px;line-height: 11px;}
.payout-info-box .request-note .btn {padding: 4px 15px;font-size: 10px;border-radius: 7px;}

.wallet-stats-box .card-box .payout-request-body.request {background: rgba(241, 180, 76, 0.06);border: 1px solid #f1b44c;box-shadow: 0px 2px 6px rgba(36, 41, 70, 0.05);}
.payout-request-body.request .wallet-balance-block .balance-info h3,.payout-request-body.request .wallet-balance-block .balance-info h1 {color: #f1b44c;}
.payout-request-body.request .payout-status {background-color: #f1b44c;border: 1px solid #f1b44c;box-shadow: 0px 2px 6px rgba(36, 41, 70, 0.05);}
.payout-request-body.request .t-white-btn {color: #f1b44c;}
.payout-request-body.request .t-white-btn:hover {background-color: #f1b44c;color: #fff;}

.wallet-stats-box .card-box .payout-request-body.success {background: rgba(40, 198, 111, 0.1);border: 1px solid #28c66f;box-shadow: 0px 2px 6px rgba(36, 41, 70, 0.05);}
.payout-request-body.success .wallet-balance-block .balance-info h3,.payout-request-body.success .wallet-balance-block .balance-info h1 {color: #28c66f;}
.payout-request-body.success .payout-status {background: #28c66f;border: 1px solid #28c66f;box-shadow: 0px 2px 6px rgba(36, 41, 70, 0.05);    }
.payout-request-body.success .t-white-btn {color: #28c66f;}
.payout-request-body.success .t-white-btn:hover {background-color: #28c66f;color: #fff;}

.more-wallet-info {padding: 20px 18px;border-radius: 10px;background: rgba(196,196,196,0.1);box-shadow: 0px 2px 6px rgba(36, 41, 70, 0.05);}
.more-wallet-info h6 {color: #000;font-weight: bold;line-height: 22px;margin-bottom: 16px;}
.more-wallet-info h4 {color: #000;font-weight: bold;line-height: 27px;}
.more-wallet-info h4 > span {display: inline-flex;margin-right: 5px;}

.my-wallet-section .card-box-header > h4 {font-weight: bold;}

.t-primary-btn.disabled {background: #b9b9b9; opacity: 1;cursor: no-drop;}
.my-wallet-section .btn-lg {padding: 20px 15px; min-width: 255px;}
.wallet-stats-box.blank-data  .card-box .card-box-body {background: #f8f8f8; padding: 22px 25px;}
.blank-data .wallet-balance-block .balance-info h3 {color: #000;opacity: 0.35; margin: 0;}
.blank-data .more-wallet-info h6 {opacity: 0.35; margin: 0;}

.reg-acc-list {display: flex;flex-direction: row;flex-wrap: nowrap;overflow-x: auto;}
.reg-acc-list::-webkit-scrollbar {height: 5px; border-radius: 5px;}
.reg-acc-list::-webkit-scrollbar-track {background: #f6f6f6;}
.reg-acc-list::-webkit-scrollbar-thumb {background: #c5c5c5;}
.reg-acc-list::-webkit-scrollbar-thumb:hover {background: #6259CE;}

.reg-acc-list .bank-account-details {position: relative;flex: 0 0 418px;max-width: 418px;margin-right: 44px;border-radius: 15px;border: 1px solid #e5e5e5;background: #fff;padding: 20px 30px;overflow: hidden;margin-bottom: 10px;}
.reg-acc-list .bank-account-details:last-child {margin-right: 0;}
.reg-acc-list .bank-account-details .bank-logo {margin-bottom: 30px;}
.reg-acc-list .bank-account-details .bank-logo > img {height: 50px;}
.reg-acc-list .bank-account-details .pay-request {position: relative;display: flex;align-items: center;flex-flow: row wrap;justify-content: space-between;margin: 0 -30px -20px;padding: 30px;background: #e5e5e5;border-radius: 15px;z-index: 10;}
.reg-acc-list .bank-account-details .pay-request::after {content: '';display: inline-flex;width: 101px;height: 95px;background-image: url('../images/curve-shape.svg');background-repeat: no-repeat;background-position: bottom left;background-size: contain;position: absolute;bottom: 0;left: 0;z-index: -1;opacity: 0.5;}
.reg-acc-list .bank-account-details .pay-request .user-info {width: calc(100% - 180px);padding-right: 15px;}
.reg-acc-list .bank-account-details .pay-request .user-info .name {color: #000;font-size: 22px;font-weight: bold;line-height: 30px;}
.reg-acc-list .bank-account-details .pay-request .user-info .acc-number {color: #000;font-size: 20px;font-weight: 400;}
.reg-acc-list .bank-account-details .pay-request .pay-request-btn {width: 180px;}
.reg-acc-list .bank-account-details .pay-request .pay-request-btn .btn {width: 100%;}
.reg-acc-list .bank-account-details .pay-request .pay-request-btn .t-primary-btn img {-webkit-filter: brightness(0) invert(1);-moz-filter: brightness(0) invert(1);-ms-filter: brightness(0) invert(1);filter: brightness(0) invert(1);}

.reg-acc-list .bank-account-details.default-account {border-color: #6259ce;}
.reg-acc-list .bank-account-details.default-account .pay-request {background: #6259CE;}
.reg-acc-list .bank-account-details.default-account .pay-request .user-info .name {color: #fff;}
.reg-acc-list .bank-account-details.default-account .pay-request .user-info .acc-number {color: #fff;}
.reg-acc-list .bank-account-details.default-account .pay-request .pay-request-btn .btn {background: #fff;color: #6259CE;}
.reg-acc-list .bank-account-details.default-account .pay-request .pay-request-btn .btn img {filter: none;}
.reg-acc-list .bank-account-details.default-account .t-badge.green {background: #34c38f;color: #fff;position: absolute;top: -10px;min-width: 75px;}
.reg-acc-list .bank-account-details.default-account .pay-request::after {opacity: 0.11;}

.wallet-transaction-latest .custom-table .table td {padding: 14px;}
.wallet-transaction-latest .custom-table .table td .name-col .image {margin: 0;}
.wallet-transaction-latest .custom-table .table td .name-col .content {width: calc(100% - 40px);padding-left: 14px;color: #1e1e1e;font-size: 16px;}
.wallet-transaction-latest .custom-table .table td .name-col .content .date {display: block;color: #656565;font-size: 14px;line-height: 19px;margin-top: 3px;}
.wallet-transaction-latest .custom-table .table td .price-amount {font-weight: bold;}

.rma-details ul > li {display: block;font-family: 'Nunito', sans-serif;margin-bottom: 16px;}
.rma-details ul > li h6 {color: #1e1e1e;font-family: 'Nunito', sans-serif;font-size: 16px; line-height: 22px;font-weight: bold;}
.rma-details ul > li small {color: #1e1e1e;font-size: 14px;font-weight: 500;}

.tracking-id-info  {color: #1e1e1e;font-size: 16px;font-weight: bold;line-height: 22px;}
.tracking-id-info > span {color: #8e8e8e;font-weight: 400;}
.credit-memo-table .product-detail-info .name, .credit-memo-table .product-detail-info .size {line-height: 22px;}
.credit-memo-table .product-detail-info .size {margin-bottom: 18px;}
.credit-memo-table .product-detail-info .id > a {color: #6259CE;}
.credit-memo-table .table tr > td {padding: 20px;}
.credit-memo-table tfoot tr > td {background: rgba(98,89,206,0.13);font-weight: bold !important;text-transform: uppercase;}
.credit-memo-block .card-box-header .tracking-id-info {padding: 11px 15px;border: 1px solid #6259CE;border-radius: 9px;}
.credit-memo-checklist .c-checkbox {margin-bottom: 18px;}
.credit-memo-checklist .c-checkbox label {text-transform: uppercase;}
.credit-memo-checklist .c-checkbox label::before {border: 1px solid #dfe2e6;width: 19px;height: 19px;}
.credit-memo-checklist .c-checkbox label::after {width: 19px;height: 19px;background-size: 15px;}

.checkbox-price-control  {display: flex;align-items: center; margin-bottom: 18px;}
.checkbox-price-control .c-checkbox {margin-bottom: 0;}
.checkbox-price-control .price-field-control {flex: 0 0 110px;width: auto;margin-top: 0 !important;margin-left: 30px;}
.checkbox-price-control .price-field-control .unit {left: 8px;}
.checkbox-price-control .price-field-control .form-control {color: #000;border:0.5px solid #6259CE;border-radius: 4px;min-height: 24px;width: 100% !important;padding: 2px 8px;padding-left: 40px;}

.shipping-gridrow .t-card-primary {margin-bottom: 50px;}
.shipping-gridrow .t-card-primary:last-child {margin-bottom: 0;}

.shipping-gridrow .t-card-primary .card {margin-bottom: 40px;background: #fff;border: 1px solid #e5e5e5;border-radius: 10px;padding: 0 8px; overflow: hidden;}
.shipping-gridrow .t-card-primary .card:last-child {margin-bottom: 0;}
.shipping-gridrow .t-card-primary .card .card-header {display: flex;align-items: center;justify-content: space-between;background: #fff;padding: 15px 11px; border-radius: 0;}
.shipping-gridrow .t-card-primary .card .card-header .card-title {color: #000000;}
.shipping-gridrow .t-card-primary .card .card-body {padding: 15px 11px;}

.element-box {position: relative;border-radius: 10px;border: 1px solid #e5e5e5;padding: 12px;display: flex;flex-wrap: wrap;flex-direction: row;align-items: center;}
.element-box .element-icon {width: 50px;height: 60px;border-radius: 10px;background: transparent;border: 1px solid #e5e5e5;display: inline-flex;align-items: center;justify-content: center;padding: 10px;}
.element-box .element-content {width: calc(100% - 50px);padding-left: 20px;}
.element-box .element-content h6 {font-size: 16px;line-height: 22px;font-weight: bold;color: #000;margin-bottom: 9px;}
.element-box .element-content p {color: #000;font-size: 14px;line-height: 14px;margin-bottom: 0;}

.shipping-zone-block {margin: 30px 0 0 0;position: relative;}
.shipping-zone-block .zonal-title {display: flex;justify-content: space-between;align-items: center;margin-block-end: 20px;}
.shipping-zone-block .zonal-title .title {font-weight: bold;line-height: 27px;}
.shipping-zone-block .zonal-title .create-zone {color: #6259CE;font-size: 20px;font-weight: bold;text-decoration: underline;display: inline-flex;}

.zone-select-control {max-width: 153px;margin-bottom: 10px;}
.zone-select-control .select2-container--default .select2-selection--single {border-radius: 7px;background: rgba(98, 89, 206, 0.13);border: 0;}
.zone-select-control .select2-container--default .select2-selection--single .select2-selection__rendered {padding: 5px 20px 5px 10px;line-height: 1.5;font-weight: bold;}
.zone-select-control .select2-container--default .select2-selection--single .select2-selection__arrow {background-image: url('../images/caret-bottom-blue.svg');width: 8px;right: 10px; filter: brightness(0) invert(0);}
.shipping-zone-block .selected-location {margin-bottom: 20px;font-size: 14px;color: #000;padding-left:10px;}

.page-main-title .btn-back {width: 49px;height: 49px;display: inline-flex;align-items: center;justify-content: center;border-radius: 9px;background: rgba(98, 89, 206, 0.13);margin-right: 20px; padding: 11px;}
.page-main-title .btn-back img {margin: 0 auto;}
.page-main-title .btn-back:hover {background: #6259CE;}

.commision-rate-table .table {border-radius: 8px;}
.commision-rate-table .table thead > tr > th {background: #f5f6f8;font-family: 'Nunito', sans-serif;color: #000;font-size: 14px;font-weight: bold;line-height: 20px;text-align: left;padding: 22px;vertical-align: middle;border: 0;}
.commision-rate-table .table thead > tr > th:first-child {border-radius: 8px 0 0 8px;}
.commision-rate-table .table thead > tr > th:last-child {border-radius: 0 8px 8px 0;}
.commision-rate-table .table tbody tr > td {vertical-align: middle;border-top: 0;border-bottom: 1px solid #f6f6f6;padding: 10px 22px;font-size: 14px;text-align: left;color: #000;}
.commision-rate-table .table tbody tr:first-child > td {padding-top: 25px;}
.commision-rate-table .table tbody tr:last-child  > td {border-bottom: 0;}
.commision-rate-table .product-info-name .type {display: block;font-size: 12px;line-height: 18px;text-align: left;color: #8e8e8e;margin-bottom: 4px;}
.configure-link {font-size: 16px;font-weight: bold;line-height: 22px;text-align: left;color: #6259ce;display: inline-flex;align-items: center;padding-right: 15px;background-image: url('../images/right-angle-blue.svg');background-position: center right;background-repeat: no-repeat;}
.configure-link:hover {color: #000;}

.verified-field {position: relative;}
.verified-field .form-control {padding-right: 50px;}
.verified-field .verification-note {position: absolute;top: 0;right: 16px;display: inline-flex;align-items: center;height: 100%;font-family: 'Nunito', sans-serif;font-weight: bold;font-size: 16px;line-height: 22px;text-align: right;color: #34c38f;}
.verified-field .verification-note > img {display: inline-flex;align-items: center;margin-right: 0;}

.field-icon-group {position: relative;}
.field-icon-group .form-control {padding-left: 45px;}
.field-icon-group .field-icon {position: absolute;top: 0;left: 16px;display: inline-flex;align-items: center;height: 100%;}

.teammate-setting-col .form-group {margin-bottom: 24px;}
.teammate-setting-col .form-group label {font-size: 16px;font-weight: 600;margin-bottom: 10px;}

.upload-group-new {display: flex;flex-direction: row;align-items: center;flex-wrap: wrap;}
.upload-group-new .upload-area {width: calc(100% - 128px);padding-left: 24px;}
.upload-group-new .profile-picture {width: 128px;height: 128px;position: relative;background-image: url(../images/profile-placeholder.svg);background-repeat: no-repeat;background-position: center;background-size: cover;}
.upload-group-new .profile-picture > img {width: 100%;height: 100%;object-fit: cover;object-position: center;border-radius: 100%;}
.upload-group-new .profile-picture .profile-edit {width: 32px;height: 32px;background-color: #f6f6f6;position: absolute;right: 19px;bottom: 0;background-image: url(../images/edit-icon2.svg);background-position: center center;background-size: 15px;background-repeat: no-repeat;border-radius: 50px;cursor: pointer;margin: 0;display: inline-flex;align-items: center;justify-content: center;}
.upload-group-new .profile-picture .profile-edit.remove-img {background: #f6f6f6;}
.upload-group-new .profile-picture .profile-edit.remove-img img {width: 15px;}
.upload-group-new .profile-picture .profile-edit input[type="file"] {display: none;position: absolute;left: 0;right: 0;bottom: 0;top: 0;width: 100%;height: 100%;opacity: 0;visibility: hidden;}
.upload-group-new .fm-upload-block .upload-files-box {border-radius: 8px;background: #fff;border: 1px solid #e5e5e5;}
.upload-group-new .fm-upload-block .upload-files-box .upload-icon {background: #f2f4f7;width: 40px;height: 40px;border-radius: 20px;display: inline-flex;align-items: center;justify-content: center;margin-bottom: 24px;}
.upload-group-new .fm-upload-block h6 {font-weight: normal;font-size: 14px;line-height: 20px;text-align: left;color: #8e8e8e;}
.upload-group-new .fm-upload-block h6 > span {color: #6259CE;font-weight: bold;}
.upload-group-new .fm-upload-block .upload-files-box > p {font-size: 14px;line-height: 20px;text-align: left;color: #8e8e8e;margin-bottom: 0;}

.configure-selection {display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;}
.configure-selection .config-select {width: 260px;padding-right: 12px;}
.configure-selection .configure-link {margin: 10px 0;}

.form-group.disabled label {color: #b5b5b5;}
.form-group.disabled .select2,.form-group.disabled .form-control,.form-group.disabled .select2-control {pointer-events: none;}

.table-filter-action ul > li {padding-right: 15px;}
.table-filter-action ul > li:last-child {padding-right: 0;}
.table-filter-action .btn {position: relative;min-width: 211px;display: inline-flex;align-items: center;justify-content: space-between;padding: 7px 15px;text-transform: capitalize;}
.table-filter-action .btn .count {background: #fff;border-radius: 15px;display: inline-flex;align-items: center;justify-content: center;width: 49px;height: 24px;text-align: center;}

.table-inside-dropdown .t-danger-btn-outline {position: relative;color: #f00;background: rgba(255, 0, 0, 0.02);border: 1px solid #f00;text-transform: capitalize;min-width: 298px; padding-right: 50px;}
.table-inside-dropdown .t-danger-btn-outline::after {content: '';display: inline-block;position: absolute;width: 14px;height: 14px;right: 15px;top: 50%;margin-top: -7px;background-image: url('../images/chevron-down-outline-red.svg');background-repeat: no-repeat;background-position: center;background-size: 12px;transition: 0.2s all linear;}
.table-inside-dropdown.show .t-danger-btn-outline::after {transform: rotate(-180deg);}
.table-inside-dropdown .t-danger-btn-outline .count {background: #fff;border-radius: 15px;display: inline-flex;align-items: center;justify-content: center;width: 49px;height: 24px;text-align: center;}
.table-inside-dropdown.more-action-dd .dropdown-menu {min-width: 100%;}
.table-inside-dropdown.more-action-dd .dropdown-menu > li > a:hover {background: #fff; color: #000;}

.table-filter-action .red-select-control .select2-container--default .select2-selection--single .select2-selection__rendered {color: #f00;font-weight: bold;}
.table-filter-action .red-select-control .select2-container--default .select2-selection--single {background: rgba(255, 0, 0, 0.02);border-color: #f00;}
.table-filter-action .red-select-control .select2-container--default .select2-selection--single .select2-selection__arrow {background-image: url('../images/chevron-down-outline-red.svg');background-size: 12px;}

.addv-detail-section .order-table .order-content {padding: 0 0 24px 0;}
.addv-detail-section .order-table .label-name {font-size: 14px;line-height: 20px;color: #8e8e8e;margin-bottom: 4px;}
.addv-detail-section .order-table .content-type p {font-size: 16px;font-weight: 600;line-height: 22px;color: #000;}
.addv-detail-section .order-table .content-type p > a {color: #6259CE; font-weight: bold;}

.addv-detail-section .imgupload .img-content {position: relative;max-width: 320px;border-radius: 8px;background-image: url('../images/img.jpg');background-repeat: no-repeat;background-size: cover;background-position: top center;height: 160px;}
.addv-detail-section .imgupload > img {width: 100%;object-fit: cover;object-position: top center;}
.addv-detail-section .imgupload .img-content > a {position: absolute;top: 0;right: 0;width: 30px;height: 30px;display: inline-flex;align-items: center;justify-content: center;border-radius: 45px;background: #f6f6f6;margin: -10px;z-index: 10;border: 2px solid #fff;}

.addv-detail-section .alert {margin-bottom: 0;}
.alert-gray {border-radius: 4px;background: rgba(62, 62, 62, 0.06);box-shadow: 0px 2px 6px rgba(36, 41, 70, 0.05);}
.alert.alert-sm {padding: 6px;align-items: center;}
.alert.alert-sm .icon {width: 8px;}
.alert.alert-sm .icon + .content {width: calc(100% - 8px);padding-left: 9px;font-size: 8px;color: #000;font-weight: 400;}

.promotions-settings .date-timerow, .addv-detail-section .date-timerow {padding-right: 30px;}
.promotions-settings .date-timerow .date-timecol, .addv-detail-section .date-timerow .date-timecol {flex: 0 0 50%; max-width: 50%;}

.commision-details-info {display: flex;flex-direction: column;}
.commision-details-info .toggle-link {position: relative;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: bold;line-height: 20px;text-align: right;color: #6259ce;display: inline-flex;align-items: center;justify-content: flex-end;padding: 8px 18px 8px 8px;margin-left: auto;}
.commision-details-info .toggle-link::after {content: '';display: inline-flex;align-items: center;position: absolute;top: 50%;right: 0;width: 10px;height: 10px;background-image: url('../images/select-down-arrow-blue.svg');background-repeat: no-repeat;background-position: center right;background-size: 8px;margin-top: -5px;transition: 0.3s all ease;}
.commision-details-info .toggle-link[aria-expanded="true"]::after {transform: rotate(-180deg);}
.commisiton-rate-info .text-desc {display: flex;flex-direction: row;align-items: center;justify-content: space-between;}
.commisiton-rate-info .text-desc .type {font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 400;line-height: 20px;color: #8e8e8e;}
.t-grey-btn-outline.light-grey {color: #b5b5b5;font-weight: bold;border-color: #e5e5e5;background: transparent;text-transform: capitalize;box-shadow: none;}
.t-grey-btn-outline.light-grey:hover {background: #e5e5e5;color: #1e1e1e;}
.t-grey-btn-outline.light-grey:hover > img {filter: brightness(0) invert(0);}

.head-search-box-date {max-width: 557px; width: 100%;}
.ticket-search { max-width: 400px;}
.head-search-box-date .date-range-pick {min-width: max-content;}

.page-main-title .t-badge.yellow {background: #fcecd2;color: #f1b44c;font-size: 12px;font-weight: bold;display: inline-flex;align-items: center;border-radius: 4px;min-width: 126px;vertical-align: middle;}
.page-main-title .t-badge {vertical-align: middle;}
.update-list-info > ul > li {display: block;margin-bottom: 24px;}
.update-list-info > ul > li:last-child {margin-bottom: 0;}
.update-list-info .span-title {color: #8e8e8e;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 400;line-height: 20px;}


.middle-content-container {max-width: 764px; margin: 0 auto;}
.middle-content-container .account-settings-gridrow .middle-col {max-width: 100%; flex: 0 0 100%;}

.notifications-list-section .page-main-title {margin-bottom: 54px;}
.notification-filter {display: flex;align-items: flex-end;justify-content: space-between;margin-bottom: 20px;}
.notification-filter ul.tabs > li {margin: 0 8px 0 0;display: inline-flex;}
.notification-filter ul.tabs > li > a {background: transparent;color: #b5b5b5;font-family: 'Nunito', sans-serif;font-weight: 600;font-size: 16px;line-height: 22px;text-align: center;padding: 12px 19px;border-radius: 45px;display: inline-flex;align-items: center;justify-content: center;}
.notification-filter ul.tabs > li > a .total-count {border-radius: 45px;width: 22px;height: 22px;color: #fff;background: #b5b5b5;display: inline-flex;align-items: center;justify-content: center;text-align: center;font-size: 10px;letter-spacing: -0.07em;margin-left: 11px;}
.notification-filter ul.tabs > li:hover > a, .notification-filter ul.tabs > li.active > a {color: #6259CE;background: #edebfd;}
.notification-filter ul.tabs > li:hover > a .total-count,.notification-filter ul.tabs > li.active > a .total-count {background-color: #6259ce;}
.notification-info {display: flex;flex-wrap: wrap;align-items: center;justify-content: space-between;padding: 24px 0;border-bottom: 1px solid #e5e5e5;}
.notification-info .details {display: flex;flex-direction: row;flex-wrap: wrap;align-items: center;width: calc(100% - 170px);position: relative;}
.notification-info .details > .img-icon {width: 40px;height: 40px;display: inline-flex;align-items: center;justify-content: center;text-align: center;background-color: #6259CE;border-radius: 45px;overflow: hidden; background-image: url('../images/user-icon-vector.svg');background-size: 18px;background-position: center;background-repeat: no-repeat;}
.notification-info .details > .img-icon img {width: 100%; height: 100%; object-fit: cover; object-position: center;}
.notification-info .details > .content {width: calc(100% - 40px);padding-left: 16px;}
.notification-info .details > .content > p {color: #8e8e8e;font-size: 16px;line-height: 22px;}
.notification-info .details > .content > p strong {color: #000;margin-right: 5px;}
.notification-info .details > .content > p .t-badge-o {border-radius: 4px;background: rgba(237, 235, 253, 0);border: 1px solid #6259ce;padding: 6px 16px;line-height: 14px;margin: 5px 0 5px 5px;}
.notification-info .details > .content > p .t-badge-o:hover {color: #6259ce;}
.notification-info .details > .content > p .t-badge-o.grey {color: #707070;border-color: #b5b5b5;background: rgba(246, 246, 246, 0);}
.notification-info .details > .content > p .t-badge-o.grey:hover {color: #707070;}
.notification-info .time-stamp {font-size: 14px;line-height: 20px;text-align: right;color: #3a3c4a; width: 170px; padding-left: 10px;}
.notification-info.recent .details > .content > p::after {content: '';width: 12px;height: 12px;background: #34c38f;position: relative;border-radius: 45px;display: inline-block;margin-left: 10px;}

.support-chat-block {position: relative;display: flex;flex-wrap: wrap;flex-direction: row; height: 100%;}
.support-chat-block .card-box {height: 100%; min-height: 100vh; }
.support-chat-sidebar {width: 382px;padding: 16px;height: 100%; box-shadow: none;}
.support-chat-content {width: calc(100% - 406px);margin-left: 24px; box-shadow: none;}

.ticket-list-filter {margin: 24px 0 16px 0;}
.ticket-list-filter > ul {display: flex;flex-direction: row;}
.ticket-list-filter > ul > li {display: flex;flex-direction: row;align-items: center;margin: 0 4px 0 0;}
.ticket-list-filter > ul > li > a {font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 400;line-height: 20px;color: #b5b5b5;padding: 11px 16px;border-radius: 8px; display: inline-flex; align-items: center; justify-content: center;}
.ticket-list-filter > ul > li > a .icon {display: inline-flex; align-items: center; margin-right: 16px;}
.ticket-list-filter > ul > li.active a, .ticket-list-filter > ul > li > a:hover {background: #edebfd;color: #6259CE;}
.ticket-list-filter > ul > li.active a .icon svg *, .ticket-list-filter > ul > li > a:hover .icon svg * {fill: #6259CE;}

.sup-ticket-info {display: flex;align-items: center;flex-direction: row;justify-content: space-between;}
.support-ticket-listing ul > li {border-radius: 8px;background: #fff;transform: 0.3s all ease;padding: 16px;margin-bottom: 4px;}
.support-ticket-listing ul > li.active, .support-ticket-listing ul > li:hover {background: #f6f6f6;}
.sup-ticket-info .content {display: flex;flex-direction: row;align-items: flex-start;justify-content: space-between;}
.sup-ticket-info .content .img-icon {width: 36px;height: 36px;background: transparent;}
.sup-ticket-info .content .img-icon > img {width: 100%;height: 100%;object-fit: cover;object-position: center;}
.sup-ticket-info .content .content-info {width: calc(100% - 36px);padding-left: 16px;}
.sup-ticket-info .sup-ticket-no {font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 400;line-height: 20px;color: #000;margin-bottom: 8px;}

.support-ticket-listing ul > li:hover .sup-ticket-info .sup-ticket-no, .support-ticket-listing ul > li.active .sup-ticket-info .sup-ticket-no {font-weight: bold;}

.sup-ticket-info .sup-ticket-assign {font-family: 'Nunito', sans-serif;font-size: 12px;font-weight: 400;line-height: 16px;color: #8e8e8e;}
.sup-ticket-info .sup-ticket-assign .round-sm-green {margin-left: 5px;}
.sup-ticket-info .time-clock {font-family: 'Nunito', sans-serif;font-size: 12px;font-weight: 400;line-height: 16px;color: #8e8e8e;}

.round-sm-green {width: 8px;height: 8px;display: inline-flex;align-items: center;background: #34c38f;border-radius: 100%;}

.support-chat-content .card-box-header {padding: 32px; border-bottom: 1px solid #e5e5e5;}
.support-chat-content .card-title {font-weight: bold; margin-bottom: 0;}
.support-chat-content .card-box-body {padding: 24px;}

.assigned-ticket {display: flex; align-items: center; color: #8e8e8e; font-size: 14px; font-weight: 400; line-height: 20px; }
.assigned-ticket .assign-person {color: #000; font-weight: 600; padding-left: 16px;}
.assigned-ticket .assign-person > img {width: 24px; border-radius: 100%; margin-right: 5px; display: inline-flex;}

.chatbox-section {padding: 24px 0 0 0;}
.chatbox-chat-group .chatbox-card {display: block;position: relative;margin-bottom: 24px;max-width: 412px;}
.chatbox-chat-group .chatbox-card .avatar-icon {width: 36px;height: 36px;display: inline-flex;align-items: center;justify-content: center;margin-top: 8px;position: absolute;top: 0; left: 0;}
.chatbox-chat-group .chatbox-card .avatar-icon > img {width: 100%; height: 100%; object-fit: cover; object-position: center;}
.chatbox-chat-group .chatbox-card .chatbox-body {padding-left: 52px;display: flex;flex-direction: row; flex-wrap: wrap;}
.chatbox-card .request-box {position: relative;border-radius: 8px;background: #fff;border: 1px solid #e5e5e5;padding: 24px;display: flex;flex-direction: column;gap: 24px 0;justify-content: space-between;width: 100%;}
.chatbox-card .request-box .request-msg {display: flex;flex-direction: row;align-items: center;font-size: 14px;line-height: 20px;text-align: left;color: #8e8e8e;}
.chatbox-card .request-box .request-msg .img-icon {width: 16px;}
.chatbox-card .request-box .request-msg p {width: calc(100% - 16px);padding-left: 16px;}
.chatbox-card .request-box .support-ticket-no {line-height: 22px;font-weight: 400;color: #000;}
.chatbox-card .request-box .request-status {font-size: 16px;font-weight: bold;line-height: 20px;text-align: left;color: #8e8e8e;}
.chatbox-card .request-box.accepted {border-color: #6259ce;}
.chatbox-card .request-box.accepted .request-status {color: #6259ce;}
.chatbox-card .request-box.declined {border-color: #f46a6a;}
.chatbox-card .request-box.declined .request-status {color: #f46a6a;}
.chatbox-chat-group .chatbox-card.sender {margin-left: auto;}
.chatbox-chat-group .chatbox-card.sender .avatar-icon {left: auto; right: 0;}
.chatbox-chat-group .chatbox-card.sender .chatbox-body {padding-left: 0;padding-right: 52px;}

.chatbox-chat-group .chatbox-card.responder {max-width: 472px;}
.chatbox-chat-group .chatbox-card .response-box {background: #edebfd;border-radius: 8px;padding: 12px 16px;color: #6259ce;}
.chatbox-chat-group .chatbox-card .response-box a {font-weight: bold; color: #6259CE;}
.chatbox-chat-group .timestamp-clock {font-family: 'Nunito', sans-serif;font-size: 12px;font-weight: 400;line-height: 18px;text-align: left;color: #8e8e8e;margin-top: 8px; width: 100%;}

.reply-control-box {position: relative;max-height: 80px;margin: 0 -24px;}
.reply-control-box .reply-control {border-radius: 0px 0px 16px 16px;background: #f2f3f5;border: 0;height: 80px;resize: none;padding: 28px 72px;font-family: 'Nunito', sans-serif;font-size: 16px;line-height: 22px;color: #8e8e8e;}
.reply-control-box .add-items {width: 40px;height: 40px;border-radius: 100%;background: transparent;position: absolute;top: 50%;margin-top: -20px;display: inline-flex;align-items: center;justify-content: center;left: 16px;}
.reply-control-box .add-items > img {width: 16px;}
.reply-control-box .send-btn {width: 40px;height: 40px;border-radius: 100%;background: transparent;position: absolute;top: 50%;margin-top: -20px;display: inline-flex;align-items: center;justify-content: center;right: 16px;padding: 0;}

.fc-event-listing .month-selection {position: relative;margin-bottom: 24px;padding-bottom: 24px;border-bottom: 1px solid #F6F6F6;text-align: left;}
.month-selection .dropdown-month {font-family: 'Nunito', sans-serif;font-weight: bold;font-size: 20px;line-height: 1;color: #000;display: inline-flex;align-items: center;text-align: left;}
.fc-event-info-box {position: relative;margin-bottom: 16px;}
.fc-event-info-box .date-time-info {display: flex;flex-direction: row;align-items: center;justify-content: space-between;margin-bottom: 8px;}
.fc-event-info-box .date-time-info p {font-family: 'Nunito',sans-serif;font-size: 12px;font-weight: normal;line-height: 18px;color: #8e8e8e;}
.fc-event-info-box .date-time-info p > span {display: inline-flex;margin-left: 5px;}
.fc-event-card {position: relative;padding: 27px 24px 16px;background: #f6f6f6;border-radius: 8px;overflow: hidden;border-left: 8px solid #6259CE;}
.fc-event-card .name-info {display: flex;flex-wrap: wrap;align-items: center;margin-bottom: 35px;}
.fc-event-card .name-info .img-avatar {width: 32px;height: 32px;border-radius: 45px;display: inline-flex;align-items: center;justify-content: center;text-align: center;background: #6259CE;}
.fc-event-card .name-info .img-avatar > img {height: 14px;}
.fc-event-card .name-info h6 {font-size: 14px;line-height: 20px;text-align: left;color: #000;width: calc(100% - 32px);padding-left: 8px;}
.fc-event-card p {font-size: 16px;line-height: 20px;text-align: left;color: #000;}
.fc-event-info-box.pending .fc-event-card {border-left-color: #F1B44C;}
.fc-event-info-box.completed .fc-event-card {border-left-color: #28C66F;}

.calendar-full-plugin {position: relative;}
.fc-custom-month-selection {display: flex;align-items: stretch;flex-wrap: wrap;position: absolute;top: 0;left: 0;gap: 0 15px;}
.fc-custom-month-selection .fc-event-listing .month-selection {padding: 0; margin: 0; border: 0;}
.fc .fc-daygrid-more-link {color: #6259CE;}

/*---Modal Popup Design---*/
.modal-backdrop.show {opacity: 0.7;}
.custom-modal .modal-content { border: none; box-shadow: 0 2px 6px rgba(36,41,70,0.05); border-radius: 10px; }
.custom-modal .modal-header { background-color: #6259CE; color: #fff; padding: 15px 30px; border: none; border-top-left-radius: 10px; border-top-right-radius: 10px; align-items: center;}
.custom-modal .modal-dialog { max-width: 550px; }
.custom-modal .modal-header h4 { font-weight: 700; text-transform: capitalize;}
.custom-modal .modal-header .close { opacity: 1; margin: 0; padding: 0; line-height: 0;}
.custom-modal .modal-body { padding: 25px;}
.custom-modal .modal-body p { font-size: 16px; line-height: 22px; font-weight: 300; margin-bottom: 28px;}

.add-teammate-modal .modal-header h4 {text-transform: uppercase;}
.add-teammate-modal .form .form-group { position: relative;}
.add-teammate-modal .form .form-group label { position: absolute; left: 15px; top: -8px; padding: 0 8px; background-color: #fff; font-size: 12px; line-height: 16px; color: #000; margin: 0; z-index: 1;}
.add-teammate-modal .form .form-group .form-control { padding: 15px 22px; font-size: 16px; }
.add-teammate-modal .form .form-group .btn img { margin-right: 10px;}
.add-teammate-modal .form .form-group:last-child { margin-bottom: 0;}
.add-teammate-modal .form .form-group .select2-container--default .select2-selection--single .select2-selection__rendered {padding: 13px 40px 13px 22px; font-size: 16px; font-weight: 400;}

.add-package-modal .modal-dialog {max-width: 798px;}
.add-package-modal .package-form-row {padding: 25px 20px 30px 25px;}
.add-package-modal .package-form-row.edit-form-row .form-group {margin-bottom: 20px;}

.currency-input-group {max-width: 220px; display: flex; flex-direction: row; align-items: center;}
.currency-input-group .price-currency {color: #000; font-size: 14px; line-height: 19px; margin-right: 11px;}

.colour-details-modal .modal-dialog {max-width: 521px;}
.colour-details-row .relevent-img {position: relative;height: 180px;border-radius: 12px;}
.colour-details-row .relevent-img > img {width: 100%;height: 100%;object-fit: cover;object-position: center;border-radius: 12px;}
.colour-details-modal .bottom-btnrow {margin-top: 48px;}

.add-custom-info-modal .modal-dialog {max-width: 798px;}
.add-custom-info-modal .modal-body {padding: 40px;}
.add-custom-info-modal .bottom-btnrow {margin-top: 40px;}
.type-info-control {display: flex;flex-direction: row;align-items: stretch; flex-wrap: wrap;}
.type-info-control .btn {border-radius: 8px;background: #fff;color: #000;;border: 1px solid #e5e5e5;font-size: 14px;line-height: 1;font-weight: 400;padding: 12px 18px;min-width: 138px; margin-right: 16px;text-transform: capitalize;}
.type-info-control .btn:last-child {margin-right: 0;}
.type-info-control .btn .icon {display: inline-flex; align-items: center; margin-right: 8px;}
.type-info-control .btn .icon > img {transition: 0.3s all ease;}
.type-info-control .btn:hover, .type-info-control .btn:active, .type-info-control .btn.active {border-color: #6259CE;}
.type-info-control .btn:hover .icon > img, .type-info-control .btn:active .icon > img, .type-info-control .btn.active .icon > img {-webkit-filter: brightness(1) invert(1);-moz-filter: brightness(1) invert(1);-ms-filter: brightness(1) invert(1);filter: brightness(1) invert(1);}

.add-coupon-info-modal .modal-dialog, .prod-sel-modal .modal-dialog {max-width: 790px;}
.add-coupon-info-modal .new-coupon-block .form-rows, .prod-sel-modal .new-coupon-block .form-rows {margin-bottom: 20px;}

.prod-sel-modal .new-coupon-block .form-rows:last-child {margin-bottom: 30px;}
.prod-sel-modal .new-coupon-block {padding-left: 25px;}
.add-coupon-info-modal .new-coupon-block .form, .prod-sel-modal .new-coupon-block .form {padding-left: 0;}
.add-coupon-info-modal .new-coupon-block .form-rows:last-child .form, .prod-sel-modal .new-coupon-block .form-rows:last-child .form {padding-left: 25px;}

.add-coupon-info-modal .date-timerow .date-timecol, .prod-sel-modal .date-timerow .date-timecol {max-width: 45%;}
.product-sku-select {display: flex;flex-direction: row;flex-wrap: wrap;margin: 20px -12px 0;}
.product-sku-select .control-select {flex: 0 0 45%;max-width: 45%;padding: 0 12px;}
.product-sku-select .select2-container--default .select2-selection--single {border-color: #707070;}
.product-sku-select .select2-container--default .select2-selection--single .select2-selection__arrow {background-image: url('../images/caret-down-icon.svg');}
.product-sku-select .select2-container--default .select2-selection--single .select2-selection__rendered {color: #707070;text-transform: uppercase;}

.ticket-modal .modal-dialog {max-width: 1102px;}
.add-new-ticket-block .form-group {margin-bottom: 24px;}
.add-new-ticket-block .form-control {padding: 17px 20px;}
.add-new-ticket-block .select2-container--default .select2-selection--single .select2-selection__rendered {padding: 17px 40px 17px 20px; line-height: 1.4;}
.add-new-ticket-block .select2-container--default .select2-selection--single .select2-selection__arrow {background-image: url('../images/select-down-arrow-light-grey.svg');}
.add-new-ticket-block .bottom-btnrow {margin: 35px 0 0 0;}
.add-new-ticket-block .bottom-btnrow .btn {min-height: 40px;}

.ticket-chat-thread .thread-block {border-radius: 15px;max-width: 90%;background: #f5f5f5;padding: 25px 20px;position: relative;margin-bottom: 25px;z-index: 1;}
.ticket-chat-thread .thread-block .date {color: #000;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: bold;line-height: 1;margin-bottom: 30px;}
.ticket-chat-thread .thread-block .title {color: #000;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: bold;line-height: 20px;margin-bottom: 25px; word-wrap: break-word;}
.ticket-chat-thread .thread-block .desc {color: #000;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 300;line-height: 20px;margin-bottom: 0;}
.ticket-chat-thread .thread-block .desc > a {color: #6259CE;}
.ticket-chat-thread .thread-block .desc > a:hover {color: #000;}
.ticket-chat-thread .thread-block .desc + .desc {margin-bottom: 20px;}
.ticket-chat-thread .thread-block.send-msg {background: #f5f5f5;margin-left: auto;text-align: right;margin-right: 10px;}
.ticket-chat-thread .thread-block.recive-msg {background: #f4f5fd;margin-right: auto;text-align: left;margin-left: 10px;}
.ticket-chat-thread .thread-block.send-msg::after {content: "";position: absolute;bottom: 0px;right: -11px;width: 25px;height: 25px;background-image: url(../images/chat-shape-right.svg);background-repeat: no-repeat;background-position: bottom right;background-size: 100%;z-index: -1;}
.ticket-chat-thread .thread-block.recive-msg::after {content: "";position: absolute;bottom: 0px;left: -11px;width: 25px;height: 25px;background-image: url(../images/chat-shape-left.svg);background-repeat: no-repeat;background-position: bottom left;background-size: 100%;z-index: -1;}

.upload-tax-invoice-modal .modal-dialog {max-width: 798px;}
.upload-tax-invoice-modal .fm-upload-block {margin: 20px 0 0 0;}
.upload-tax-invoice-modal .fm-upload-block p {margin: 0; font-size: 14px; font-weight: 400; line-height: 1;}
.upload-tax-invoice-modal .fm-upload-block .upload-action {margin: 15px 0 0 0;}
.upload-tax-invoice-modal .fm-upload-block .upload-action {display: flex; flex-direction: row; align-items: center; justify-content: space-between;}
.upload-tax-invoice-modal .fm-upload-block .upload-action > a {color: #000;font-family: 'Nunito', sans-serif;font-size: 16px;font-weight: 400;line-height: 20px;}
.upload-tax-invoice-modal .fm-upload-block .upload-action > a:hover {color: #6259CE;}

.edit-variant-modal .modal-dialog {max-width: 798px;}
.curreny-calculation-group {display: flex;flex-flow: row wrap;margin: 0 -17px;padding: 20px 0; border-bottom: 1px solid #e5e5e5; margin-bottom: 16px;}
.curreny-calculation-group .form-group {flex: 0 0 33.33%;max-width: 33.33%; padding: 0 17px;display: block;}
.curreny-calculation-group .form-group label {font-size: 16px;color: #000;display: block;margin-bottom: 9px;}

.otp-verify-modal .verify-email-block {padding: 10px 0 0 0;}
.otp-verify-modal .verify-email-block p {font-weight: 400; font-size: 14px;}
.otp-verify-modal .otp-form-group {max-width: 400px;margin-left: auto;margin-right: auto;}
.otp-verify-modal .otp-input-control {justify-content: center; gap: 0 30px;}
.otp-verify-modal .otp-form-group .resend-otp {font-size: 12px;}


.request-payout-form .title {color: #000;font-weight: bold;margin-bottom: 15px;}
.beneficiary-selection {position: relative;max-width: 251px;margin-bottom: 20px;}
.beneficiary-selection label {position: absolute;color: #7367f0;font-family: 'Nunito', sans-serif;font-size: 10px;line-height: 14px;padding: 0 5px;background: #fff;z-index: 10;top: -7px;left: 13px;}
.beneficiary-selection .select2-container--default .select2-selection--single {border: 1px solid #7367f0;border-radius: 6px;}
.beneficiary-selection .select2-container--default .select2-selection--single .select2-selection__rendered {font-weight: bold;font-size: 16px;padding: 11px 40px 11px 16px;line-height: 1;}
.beneficiary-selection .select2-container--default .select2-selection--single .select2-selection__arrow {background-image: url(../images/select-down-arrow-blue.svg);}
.request-payout-form .t-danger-btn-outline {background: #fad4d4; border-color: #fad4d4; color: #EA5455;}

.beneficiary-account-details {position: relative;padding: 30px;border-radius: 10px;background: #f8f7fe;box-shadow: 0px 2px 6px rgba(36, 41, 70, 0.05);}
.beneficiary-account-details::before {content: '';display: inline-block;width: 25px;height: 25px;background: #f8f7fe;position: absolute;top: -10px;left: 25px;-moz-transform: rotate(45deg);-ms-transform: rotate(45deg);-webkit-transform: rotate(45deg);transform: rotate(45deg);-webkit-transition: 0.2s all ease;-moz-transition: 0.2s all ease;-ms-transition: 0.2s all ease;transition: 0.2s all ease;}
.beneficiary-account-details .name {font-family: 'Nunito', sans-serif;color: #7367f0;font-size: 18px;font-weight: bold;line-height: 24px;margin-bottom: 30px;}
.beneficiary-account-details label {font-family: 'Nunito', sans-serif;color: #7367f0;font-size: 16px;font-weight: bold;line-height: 22px;margin-bottom: 15px;}
.beneficiary-account-details .account-info {margin-bottom: 25px;padding-left: 15px;}
.beneficiary-account-details .account-info ul > li {display: flex;flex-direction: row;align-items: flex-start;margin-bottom: 12px;}
.beneficiary-account-details .account-info ul > li:last-child {margin-bottom: 0;}
.beneficiary-account-details .account-info ul > li .left-label {color: #707070;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 300;line-height: 19px;margin-right: 30px;min-width: 147px;}
.beneficiary-account-details .account-info ul > li .right-label {color: #000;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: bold;line-height: 19px;word-break: break-all;}
.beneficiary-account-details .price-field-control .unit {color: #707070;}
.beneficiary-account-details .price-field-control .form-control {background: #fff;font-weight: 600;color: #000;border: 0;}

.request-payout-form .bottom-btnrow {margin-top: 30px;}

.reference-payout .copy-selection {position: relative;margin-bottom: 30px;}
.reference-payout p {max-width: 450px; margin: 0 auto;}
.reference-payout .copy-selection label {position: absolute;color: #000;font-family: 'Nunito', sans-serif;font-size: 12px;line-height: 14px;padding: 0 10px;background: #fff;z-index: 10;top: -6px;left: 13px;}
.reference-payout .copy-selection .form-control {border: 1px solid #c5c5c5;padding: 13px 50px 13px 20px;color: #000;font-size: 20px;font-weight: bold;text-transform: uppercase;letter-spacing: 0.1em;}
.reference-payout .copy-selection > a {position: absolute;top: 0;right: 18px;height: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;}
.reference-payout.request-payout-form .bottom-btnrow .t-grey-btn {font-weight: bold; background-color: #f3f3f3;}
.reference-payout.request-payout-form .bottom-btnrow .t-grey-btn .icon {margin-right: 0; margin-left: 10px;}
.reference-payout.request-payout-form .bottom-btnrow .t-grey-btn:hover {background: #8e8e8e;}
.reference-payout.request-payout-form .bottom-btnrow .t-grey-btn:hover .icon {filter: brightness(0) invert(1);}

.assigned-img-row {position: relative;overflow: hidden;}
.assigned-img-row > ul {display: flex;flex-direction: row;flex-wrap: nowrap;overflow-x: auto;}
.assigned-img-row > ul::-webkit-scrollbar {height: 5px; border-radius: 5px;}
.assigned-img-row > ul::-webkit-scrollbar-track {background: #f6f6f6;}
.assigned-img-row > ul::-webkit-scrollbar-thumb {background: #c5c5c5;}
.assigned-img-row > ul::-webkit-scrollbar-thumb:hover {background: #6259CE;}
.assigned-img-row > ul > li {max-width: 200px;flex: 0 0 200px;margin-right: 16px;margin-bottom: 15px;}
.assigned-img-row > ul > li:last-child {margin-right: 0;}
.assigned-img-row > ul > li .assign-img-info {text-align: center;}
.assigned-img-row > ul > li .assign-img-info .image {position: relative;width: 100%;height: 200px;margin-bottom: 24px;}
.assigned-img-row > ul > li .assign-img-info .image > img {width: 100%;height: 100%;object-fit: cover;object-position: center;border-radius: 4px;}
.assigned-img-row > ul > li .assign-img-info .image .t-badge {position: absolute;bottom: -10px;left: 0;right: 0;width: 100%;max-width: 80px;margin: 0 auto;border-radius: 45px;background: #28c66f;color: #fff;font-size: 12px;font-weight: 400;text-transform: capitalize;text-align: center;justify-content: center;align-items: center;padding: 6px 10px;display: none;}
.assigned-img-row > ul > li.active .assign-img-info .image .t-badge {display: inline-flex;}
.assigned-img-row > ul > li .assign-img-info .img-name {color: #000;font-size: 14px;font-weight: 400; line-height: 18px; word-wrap: break-word;}

.bank-acc-details .beneficiary-account-details::before {display: none;}
.bank-acc-details .c-checkbox {margin: 35px 0 0 0; padding-left: 35px;}
.bank-acc-details .c-checkbox label::before {width: 14px;height: 14px;border: 0;background: #e5e5e5;border-radius: 2px;}
.bank-acc-details .c-checkbox label::after {width: 14px; height: 14px; background-size: 10px;}
.bank-acc-details .c-checkbox input[type="checkbox"]:checked ~ label::before {background-color: #28c66f;border-color: #28c66f;}

.add-variants-modal .modal-dialog {max-width: 448px;}
.variant-add-lists ul {counter-reset: my-sec-counter;}
.variant-add-lists ul > li {position: relative;display: flex;flex-flow: row wrap;align-items: center;justify-content: space-between;padding: 12px 0;border-bottom: 1px solid #e5e5e5;}
.variant-add-lists ul > li:first-child {padding-top: 0;}
.variant-add-lists ul > li span.count {margin-right: 15px;}
.variant-add-lists ul > li span.count:before {counter-increment: my-sec-counter;content: counter(my-sec-counter)'.';color: #000;font-size: 14px;font-weight: 400;}
.variant-add-lists ul > li .name {position: relative;color: #000;font-size: 14px;font-weight: 400;width: calc(100% - 27px);padding-right: 15px;}
.variant-add-lists ul > li .field-remove-btn {width: 27px;height: 27px;margin: 0;padding: 6px;}
.variant-add-lists .small-title {color: #6259ce;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: bold;margin: 15px 0;}

.profile-enter-new-email {padding: 0 25px;}
.custom-modal .modal-body .profile-enter-new-email p {font-size: 14px; color: #000; font-weight: 400; text-align: center;}
.profile-enter-new-email .otp-form-group {padding: 24px 0 8px 0;margin: 27px auto;}
.profile-enter-new-email .otp-form-group .form-control {background: #fff;}
.profile-enter-new-email .otp-form-group .resend-otp {font-size: 12px;}
.profile-enter-new-email .bottom-btnrow .btn, .otp-verify-modal .bottom-btnrow .btn {min-width: 160px;}

.email-verification-modal .close {opacity: 1;position: absolute;top: 12px;right: 12px;}
.email-verification-modal h2 {font-weight: bold;}

.report-template-modal .modal-dialog {max-width: 790px;}
.report-template-modal .modal-body {padding: 30px 30px 30px 50px;}
.search-control-input {position: relative;}
.search-control-input .form-control {padding-right: 80px;}
.search-control-input .search-btn {position: absolute;top: 0;right: 0;height: 100%;display: flex;align-items: center;justify-content: center;padding: 10px;border-radius: 10px;background: #6259CE;min-width: 70px;}
.report-template-modal .selected-items-tag ul > li {padding: 8px 32px 8px 13px;font-weight: 600;}
.report-template-modal .selected-items-tag ul > li > a {width: 12px;height: 12px;background: #fff;border-radius: 45px;padding: 3px;right: 10px;margin-top: -6px;}
.template-checkbox-group {background: #f4f4f4;padding: 34px 24px;position: relative;border-radius: 10px;margin-top: 38px;}
.template-checkbox-group > ul {display: flex;flex-direction: row;align-items: flex-start;flex-wrap: wrap;margin: 0 -10px; max-height: 365px; overflow-y: auto;}
.template-checkbox-group > ul::-webkit-scrollbar {width: 5px; border-radius: 45px;}
.template-checkbox-group > ul::-webkit-scrollbar-track {background: #fff; border-radius: 45px;}
.template-checkbox-group > ul::-webkit-scrollbar-thumb {background: #ccc; border-radius: 45px}
.template-checkbox-group > ul::-webkit-scrollbar-thumb:hover {background: #bebebe; }
.template-checkbox-group > ul > li {flex: 0 0 33.33%;max-width: 33.33%;padding: 0 10px;margin: 12px 0;}
.template-checkbox-group .c-checkbox {padding-left: 50px;}
.template-checkbox-group .c-checkbox label::before {border: 1px solid #707070;}
.template-checkbox-group .c-checkbox label::before, .template-checkbox-group .c-checkbox label::after {width: 15px;height: 15px;border-radius: 0;}
.template-checkbox-group .c-checkbox label::after {background-size: 11px;}

.new-shipping-zone-modal .modal-dialog {max-width: 700px;}
.modal-body .edit-form-row .right-field .note {font-size: 14px;line-height: 14px;color: #8e8e8e;margin: 10px 0 0 16px;}
.new-shipping-zone-modal .template-checkbox-group > ul {max-height: 235px; overflow: auto;}
.new-shipping-zone-modal .template-checkbox-group > ul::-webkit-scrollbar {width: 5px; border-radius: 45px;}
.new-shipping-zone-modal .template-checkbox-group > ul::-webkit-scrollbar-track {background: #fff; border-radius: 45px;}
.new-shipping-zone-modal .template-checkbox-group > ul::-webkit-scrollbar-thumb {background: #ccc; border-radius: 45px}
.new-shipping-zone-modal .template-checkbox-group > ul::-webkit-scrollbar-thumb:hover {background: #bebebe; }

.new-shipping-zone-modal .template-checkbox-group > ul > li {flex: 0 0 100%; max-width: 100%;}
.new-shipping-zone-modal .template-checkbox-group {padding: 20px 30px;}

.add-rate-modal .modal-dialog {max-width: 790px;}
.add-rate-modal .stock-input-group {max-width: 100%;}
.add-rate-modal .stock-input-group .unit {color: #000;font-size: 14px;padding-right: 14px; padding-left: 0;}
.add-rate-modal .stock-input-group .form-control {width: 100%;}

.add-rate-modal .c-radiobox {padding-left: 50px;margin-bottom: 25px;}
.add-rate-modal .c-radiobox label::before {width: 16px;height: 16px;border: 1px solid #707070;}
.add-rate-modal .c-radiobox input[type="radio"]:checked ~ label::before {background: #fff;border-color: #6259CE;}
.add-rate-modal .c-radiobox label::after {width: 12px;height: 12px;border: 0;background: none;background: #6259CE;border-radius: 100%;margin: 2px;}
.add-rate-modal .radio-group {margin-bottom: 40px; }

.add-rate-modal .add-condition-block {position: relative;border-radius: 10px;background: #f4f4f4;padding: 17px;margin: 30px 0 0 0;}
.add-rate-modal .add-condition-block .title {font-weight: bold;color: #000;}
.add-rate-modal .add-condition-block .inner-field-box {padding: 0 15px; margin: 15px 0 0 0;}
.add-rate-modal .add-condition-block .inner-field-box .form-group .form-control {background: #fff;}
.add-rate-modal .add-condition-block .inner-field-box .form-group label {display: block; margin-bottom: 10px; color: #000; font-size: 16px;}
.add-rate-modal .measurement-field-control .measure-type, .add-rate-modal .price-field-control .unit {color: #000;}

.add-rate-modal .add-condition-block .inner-field-box .row {margin: 0 -32px;}
.add-rate-modal .add-condition-block .inner-field-box .row [class*="col-"] {padding: 0 32px;}

.config-scope-modal .modal-dialog {max-width: 833px;}
.config-scope-modal .modal-body {padding: 36px 40px;}

.config-scope-block .collapse-title {position: relative;border-radius: 8px;background: #f5f6f8;padding: 16px 65px 16px 24px;margin-bottom: 16px;display: flex;align-items: center;justify-content: space-between;cursor: pointer;}
.config-scope-block .collapse-title::after {content: '';position: absolute;top: 50%;right: 20px;margin-top: -11px;width: 22px;height: 22px;display: inline-flex;align-items: center;justify-content: center;background-image: url('../images/select-down-arrow-light-grey.svg');background-repeat: no-repeat;background-position: center;background-size: 14px;transition: 0.2s all linear;transform: rotate(180deg);}
.config-scope-block .collapse-title.active::after {transform: rotate(0deg);}
.config-scope-block .collapse-title h4 {color: #000;font-size: 20px;font-weight: bold;line-height: 27px;}
.config-scope-block .collapse-title .total-item {color: #8e8e8e;font-size: 14px;line-height: 20px;margin: 0;}
.config-scope-block .collapse-content {background: #fff;border: 1px solid #e5e5e5;border-radius: 8px;padding: 40px 30px;}
.config-selection .selection-mode > a {color: #8e8e8e;font-size: 16px;font-weight: bold;line-height: 22px;display: inline-block;padding: 0 27px 10px 0;}
.config-selection .selection-mode > a:last-child {padding-right: 0;}
.config-selection .selection-mode > a:hover {color: #1e1e1e;}
.config-selection ul {max-height: 400px;overflow-y: auto;}
.config-selection ul > li {padding: 17px 16px;border-radius: 4px;}
.config-selection ul > li.disabled {background: #f6f6f6;}
.config-selection .c-checkbox {padding-left: 36px;}
.config-selection .c-checkbox label {margin: 2px 0;color: #8e8e8e;font-size: 16px;font-weight: 400;line-height: 22px;text-align: left;}
.config-selection .c-checkbox input[type="checkbox"]:checked ~ label {font-weight: 600;color: #000;}
.config-selection .c-checkbox label::before {border-radius: 4px;width: 24px;height: 24px;border: 1px solid #e5e5e5;}
.config-selection .c-checkbox label::after {width: 24px;height: 24px;}

.add-bank-account-modal .payout-info-detail {margin-top: 0;justify-content: space-between; }
.add-bank-account-modal .payout-info-detail p {margin-bottom: 0;}
.add-bank-account-modal .payout-info-detail .t-badge.green {border-radius: 5px;color: #fff;padding: 3px 9px;font-weight: bold;font-size: 10px;background: #28c66f;border: 1px solid #28c66f;box-shadow: 0px 2px 6px rgba(36, 41, 70, 0.05);}
.add-bank-account-modal .payout-info-box .account-info {margin-bottom: 40px;}
.add-bank-account-modal .payout-info-box .price-field-control {width: 140px;}
.add-bank-account-modal .payout-info-box .price-field-control .unit {font-size: 10px;color: #000;font-weight: 600;left: 10px;}
.add-bank-account-modal .payout-info-box .price-field-control .form-control {background: rgba(40, 198, 111, 0.06);border: 1px solid #28c66f;box-shadow: 0px 2px 6px rgba(36, 41, 70, 0.05);font-weight: 600;font-size: 16px;line-height: 1.1;text-align: left;color: #000;}

.commit-changes-modal.custom-modal .modal-dialog {max-width: 640px;}
.commit-changes-modal.custom-modal .modal-header h4 {text-transform: capitalize;}
.commit-modal-content .commit-list-control  {max-width: 520px;}
/* .commit-modal-content .commit-list-control {padding-bottom: 1rem;} */
.commit-modal-content .commit-list-control h4 {font-weight: bold;margin-bottom: 16px;}
.bottom-btnrow.justify-content-start button:first-child {margin-left: 0;}

.custom-modal .commit-modal-content .sm-text {font-size: 14px;line-height: 20px;text-align: left;color: #8e8e8e;}
.commit-list-control .select2-container--default .select2-selection--single .select2-selection__rendered {padding-left: 40px; background-image: url('../images/flag-icon.svg'); background-repeat: no-repeat; background-position: center left 16px;}
.commit-list-control .select2-container--default .select2-selection--single .select2-selection__arrow {background-image: url('../images/select-down-arrow-light-grey.svg');}

.chat-window-modal .modal-body {padding: 32px;}
.chat-window-modal .ticket-chat-thread .thread-block {max-width: max-content; width: 480px; margin-bottom: 32px; padding: 24px; border-radius: 8px;}
.ticket-chat-thread .thread-block.send-msg {margin-right: 0; text-align: left;}
.ticket-chat-thread .thread-block.recive-msg {margin-left: 0;}
.chat-window-modal .ticket-chat-thread .thread-block::after {display: none;}

.chat-window-modal .ticket-chat-thread .thread-block .date, .chat-window-modal .ticket-chat-thread .thread-block .title {margin-bottom: 8px;}
.chat-window-modal .ticket-chat-thread .thread-block .desc {font-weight: 400;}

.chat-window-modal textarea.form-control {background: #f5f5f5;}

.file-attached-group {display: flex;flex-direction: row;flex-wrap: wrap;margin: -4px;}
.file-attached-group .file-attached-card {flex: 0 0 auto;max-width: 100%;padding: 4px; width: 144px;}
.file-attached-group .file-attached-item {position: relative;border-radius: 8px;border: 1px solid #CFD6F6;background: #6259CE;padding: 12px;display: flex;flex-direction: column;justify-content: space-between;gap: 16px 0;}
.file-attached-item .file-type-info {display: flex;flex-direction: row;flex-wrap: wrap;align-items: center;justify-content: space-between;}
.file-attached-item .file-type-info .file-icon {display: inline-flex;align-items: center;justify-content: center;border-radius: 4px;background: #FFF;width: 32px;height: 32px;text-align: center;}
.file-attached-item .file-type-info .file-type-name {font-family: 'Nunito', sans-serif;color: #9297E7;font-size: 12px;font-weight: 400;line-height: normal;}
.file-attached-item .file-detail-info .name {color: #FFF;font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: 700;line-height: normal;text-overflow: ellipsis;word-wrap: break-word;}
.file-attached-item .file-detail-info .size {color: #9297E7;font-family: 'Nunito', sans-serif;font-size: 12px;font-weight: 400;line-height: normal;}

.order-fc-modal .modal-dialog {max-width: 350px; margin: 0 auto;}
.fc-order-info .fc-order-header {display: flex;align-items: center;justify-content: space-between;margin-bottom: 32px;}
.fc-order-info .fc-order-header .name-info {flex: 0 0 65%; max-width: 65%;}
.fc-order-info .fc-order-header .name-info h4 {font-size: 20px;line-height: 27px;text-align: left;color: #000;}
.fc-order-info .fc-order-header .name-info p {margin-bottom: 0 !important;font-size: 12px;line-height: 18px;text-align: left;color: #8e8e8e;}
.fc-order-info .fc-order-header .action-link {flex: 0 0 25%; max-width: 25%;}
.fc-order-info .fc-order-header .action-link a {display: inline-flex;align-items: center;justify-content: center;width: 32px;height: 32px;background: #fff;border: 1px solid #e5e5e5;border-radius: 45px;}
.fc-order-info .fc-order-header .action-link a:hover {border-color: #000;}
.fc-order-info .fc-order-header .action-link a:hover img {filter: brightness(0) invert(0);}

.fc-order-info .order-img {margin-bottom: 32px;border-radius: 8px;background: #edebfd;overflow: hidden;}
.fc-order-info .order-img > img {width: 100%;height: 143px;object-fit: cover;object-position: center;}
.fc-order-info .order-avatar {display: flex;align-items: center;margin-bottom: 16px;}
.fc-order-info .order-avatar .img-avatar {width: 32px;height: 32px;background: #f6f6f6;display: inline-flex;align-items: center;justify-content: center;border-radius: 45px;}
.fc-order-info .order-avatar h6 {width: calc(100% - 32px);padding-left: 8px;font-size: 14px;font-weight: 400;color: #000;}
.fc-order-info .order-sku  {font-size: 14px;line-height: 20px;text-align: left;color: #000;}
.fc-order-info .order-sku .t-badge {padding: 4px 8px;min-width: auto;margin-right: 8px;}

/*---Ng-override---*/
.mat-typography,.mat-h1,.mat-h2,.mat-h3,.mat-h4,.mat-h5,.mat-h6,.mat-subheading-1 {font-family: 'Nunito', sans-serif !important;}
.mat-table, .mat-paginator, .mat-paginator-page-size {font-family: 'Nunito', sans-serif !important;}
.mat-paginator, .mat-paginator-page-size .mat-select-trigger {font-size: 14px;}
.mat-select-value, .mat-select-arrow {color: #000;}
.mat-paginator-page-size-label {color: #000;}
.mat-paginator-range-label .mat-icon-button {color: #000;}
.mat-paginator-range-label .mat-icon-button .mat-paginator-icon {fill: #000;}

.apexcharts-canvas * {font-family: 'Nunito', sans-serif !important;}
.mat-date-range-input {opacity: 0 !important; visibility: hidden !important; display: inline-flex; align-items: center;z-index: -1;position: relative;}
.mat-datepicker-content {box-shadow: 0 2px 6px #2429460d;border-radius: 10px;}
.mat-datepicker-content * {font-family: 'Nunito', sans-serif;}
.mat-calendar-controls .mat-calendar-period-button {color: #1E1E1E;}
.mat-calendar-arrow {fill: #656565;}
.mat-datepicker-toggle, .mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button {color: #656565;}
.mat-calendar-body-selected {background-color: #6259CE;}
.mat-calendar-body-in-range:before {background: rgba(98,89,206,0.1);}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {background: #6259CE;color: #fff;}
.mat-calendar-table-header, .mat-calendar-body-label {color: #1E1E1E;}

.date-range-pick .form-control {background: #fff;}
.md-drppicker {min-width: 776px;box-shadow: 0 2px 6px #2429460d !important;border-radius: 10px !important;margin-top: 15px !important;display: flex;flex-wrap: wrap;align-items: flex-start;padding: 0 !important; font-family: 'Nunito', sans-serif !important;}
.md-drppicker::before {content: '';display: inline-block;position: absolute;top: -20px;right: 18px;width: 20px;height: 14px;border: 10px solid #fff;border-top: 10px solid transparent;border-left: 10px solid transparent;border-right: 10px solid transparent;z-index: -1;}
.md-drppicker * {font-family: 'Nunito', sans-serif !important;}

.md-drppicker .ranges {width: 100% !important;flex: 0 0 24%;max-width: 24%;position: relative;padding: 30px 20px 0 0;margin: 0 !important;}
.md-drppicker .ranges::after {content: '';display: block;position: absolute;top: 0;right: 0;bottom: 0;width: 1px;height: 100vh;background: #e5e5e5;}
.md-drppicker .ranges ul li {border-radius: 0 10px 10px 0; font-size: 14px !important;}
.md-drppicker .ranges ul li:hover {background-color: rgba(98,89,206,0.05) !important;}
.md-drppicker .ranges ul li button {color: #1e1e1e; font-weight: bold; font-size: 14px; padding: 12px 20px !important;position: relative;border-radius: 0 10px 10px 0;}
.md-drppicker .ranges ul li button::before {content: '';display: block;position: absolute;left: 0;top: 0;width: 5px;height: 100%;background-color: #6259CE;opacity: 0;visibility: hidden;transition: 0.2s all ease-in-out;border-radius: 0 10px 10px 0;}
.md-drppicker .ranges ul li:hover button::before, .md-drppicker .ranges ul li button.active::before {opacity: 1; visibility: visible;}
.md-drppicker .ranges ul li button.active {background-color: rgba(98,89,206,0.05) !important; color: #1e1e1e !important;}

.md-drppicker .calendar {width: 100%;margin: 0 !important;flex: 0 0 38%;max-width: 38% !important; padding: 30px 20px;}
.md-drppicker .dropdowns {width: 60px !important;}
.md-drppicker .calendar-table {border: 0 !important; padding: 0 !important; border-radius: 0 !important;}
.md-drppicker td, .md-drppicker th {color: #1e1e1e !important; opacity: 1; width: 32px !important; height: 32px !important; min-width: 32px !important; margin: 0; border: 0 !important; font-size: 14px; font-weight: 500 !important;}
.md-drppicker th {font-weight: bold !important;}
.md-drppicker td {opacity: 1 !important;}
.md-drppicker td.in-range {background-color: rgba(98,89,206,0.1) !important; color: #1E1E1E !important;}
.md-drppicker td.active, .md-drppicker td.active:hover {background-color: #6259CE !important;color: #fff !important;}
.md-drppicker td.available:hover, .md-drppicker th.available:hover {background-color: rgba(98,89,206,0.1) !important;}
.md-drppicker td.active, .md-drppicker td.active:hover {background-color: #6259CE !important;color: #fff !important;}
.md-drppicker td.start-date.end-date {border-radius: 45px;}
.md-drppicker td.available:hover, .md-drppicker th.available:hover {opacity: 1 !important;}
.md-drppicker td.off, .md-drppicker td.off.end-date, .md-drppicker td.off.in-range, .md-drppicker td.off.start-date {color: #8e8e8e !important;}

.md-drppicker .buttons {width: 100%; flex: 0 0 100%; max-width: 100%; margin: 0 !important; padding: 20px;}
.md-drppicker .buttons .buttons_input {display: flex; align-items: stretch; justify-content: flex-end;}
.md-drppicker .buttons_input > .btn {background-color: #6259CE;color: #fff;padding: 11px 15px;border: 0;font-family: 'Nunito', sans-serif;border-radius: 10px;font-size: 14px;font-weight: bold;text-transform: uppercase;line-height: 1;display: inline-flex;align-items: center;justify-content: center;box-shadow: none;min-height: 40px;margin: 0 0 0 16px;}
.md-drppicker .buttons_input > .btn:before {display: none !important;}
.md-drppicker .buttons_input > .btn:hover {background-color: #191e38 !important;}
.md-drppicker .buttons_input > .btn.btn-default {background: #fff;color: #707070;border: 1px solid #8e8e8e;font-weight: 400;}
.md-drppicker .buttons_input > .btn.btn-default:hover {background: #8e8e8e !important;color: #fff;}
.md-drppicker .dropdowns select.monthselect, .md-drppicker .dropdowns select.yearselect {font-size: 14px !important;}

.angular-editor {display: flex !important; flex-direction: column !important;}
.angular-editor .angular-editor-textarea,.angular-editor .angular-editor-wrapper {order: 2 !important;}
.angular-editor .angular-editor-textarea {border: 1px solid #e5e5e5 !important;border-radius: 0 0 10px 10px !important;padding: 9px 16px !important; margin-top: 0 !important;}
.angular-editor .angular-editor-toolbar {background: #f8f8f8 !important;border: 1px solid #e5e5e5 !important;box-shadow: none !important;border-bottom: 0 !important;border-radius: 10px 10px 0 0 !important;padding: 10px !important;font-family: 'Nunito', sans-serif !important;}
.angular-editor .angular-editor-toolbar .angular-editor-button {border: 1px solid #e5e5e5 !important;border-radius: 5px !important;}
.angular-editor .angular-editor-toolbar .angular-editor-button:hover {background: #f4f5fd !important;color: #6259CE !important;}
.angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button.active {background: #f4f5fd !important;color: #6259CE !important;}
.angular-editor .angular-editor-wrapper .angular-editor-placeholder {color: #8e8e8e !important;opacity: 1 !important;padding: 9px 16px !important;}
.angular-editor font {font-family: 'Nunito', sans-serif !important;}

.angular-editor-textarea {
  ul {
    list-style-type: disc !important;
    padding-left: 40px !important;
  }

  ol {
    list-style-type: decimal !important;
    padding-left: 40px !important;
  }
}

.angular-editor-toolbar {
  input[type=color]:not(.color-picker) {
    display: initial !important;
    position: absolute;
    visibility: hidden;
    border: none;
    margin: 0;
    padding: 0;
    height: 0;
    width: 0;
  }
}

.mat-dialog-container { background: none !important; box-shadow: none !important; }
.for-custom-note { justify-content: space-between; }
.terms-note { color: #EC5453; font-size: 14px; }
.terms-note { opacity: 1; transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out; }
.terms-note.hidden { opacity: 0; visibility: hidden; }
.terms-note.visible { opacity: 1; visibility: visible; }

.ae-font {font-family: 'Nunito', sans-serif !important;color: #1e1e1e !important;}
.ae-font .ae-picker-label {font-family: 'Nunito', sans-serif !important;color: #1e1e1e !important;border-radius: 5px !important;background: #fff !important;border: 1px solid #e5e5e5 !important; font-weight: 700;}
.ae-font .ae-picker-options {font-family: 'Nunito', sans-serif !important;box-shadow: none !important;border-color: #e5e5e5 !important;border-radius: 0 0 5px 5px;}
.ae-font .ae-picker-options .ae-picker-item.focused, .ae-font .ae-picker-options .ae-picker-item:hover {background: #f4f5fd !important;color: #6259CE !important;}
.ae-font .ae-picker-options .ae-picker-item.selected {color: #6259CE !important;background: #f4f5fd !important;}

#invoice-request-ticket .form-group .add-new-ticket-block > .ticket-chat-thread {height: 300px;overflow-y: auto;overflow-x: hidden;margin-bottom: 30px;}

.iti  {display: block !important;}
.ob-content-box .auth-form-group .iti .form-control {padding-left: 52px;}
/*---tooltip-design--*/

.field-with-info .field-info-type.tt-info-field .select2-container--default .select2-selection--single .select2-selection__arrow {right: 38px;}
.field-with-info .field-info-type.tt-info-field .select2-container--default .select2-selection--single .select2-selection__rendered{padding-right: 55px;}

.tt-info-field  {position: relative;display: flex;align-items: center;width: 100%;}
.tt-info-field .form-control {padding-right: 45px;}
.tt-info-field .tt-icon {position: absolute;display: inline-flex;width: 22px;height: 22px;border-radius: 45px;top: 50%;margin-top: -11px;right: 16px;cursor: pointer;}

.tt-info-field.is-red .form-control, .tt-info-field.is-red .form-control:focus {border-color: #f46a6a;}
.tt-info-field.is-green .form-control, .tt-info-field.is-green .form-control:focus {border-color: #34c38f;}
.tt-info-field.is-yellow .form-control, .tt-info-field.is-yellow .form-control:focus {border-color: #f1b44c;}

.tt-info-field .is-red.form-control, .tt-info-field .is-red.form-control:focus {border-color: #f46a6a;}
.tt-info-field .is-green.form-control, .tt-info-field .is-green.form-control:focus {border-color: #34c38f;}
.tt-info-field .is-yellow.form-control, .tt-info-field .is-yellow.form-control:focus {border-color: #f1b44c;}

.tooltip.custom-ng-tooltip {max-width: 310px;text-align: left;padding: 24px;width: 100%;border-radius: 8px;background: #fff;box-shadow: 0px 4px 16px rgba(16, 24, 40, 0.12) !important;border-top: 4px solid transparent;opacity: 1;}
.tooltip.custom-ng-tooltip h6 {font-family: "Nunito", sans-serif;font-weight: bold;font-size: 16px;line-height: 22px;text-align: left;color: #000;margin: 0 0 5px 0;}
.tooltip.custom-ng-tooltip h6.semibold {font-weight: 600;}
.tooltip.custom-ng-tooltip p {font-family: "Nunito", sans-serif;font-weight: 400;font-size: 14px;line-height: 22px;text-align: left;color: #8e8e8e;margin: 0;}
.tooltip.custom-ng-tooltip p > a {color: #6259ce;}
.tooltip.custom-ng-tooltip a {font-family: 'Nunito', sans-serif;font-size: 14px;font-weight: bold;line-height: 20px;text-align: left;color: #6259ce;}
.tooltip.custom-ng-tooltip .t-badge {font-size: 12px; line-height: 1; min-width: auto; padding: 4px 8px; font-weight: 400 !important; text-transform: capitalize !important;border: 1px solid #6259CE;}
.tooltip.custom-ng-tooltip .t-badge.yellow2 {border-color: #f1b44c; color: #f1b44c;}
.tooltip.custom-ng-tooltip .t-badge.red {border-color: #f46a6a; color: #f46a6a;}
.tooltip.custom-ng-tooltip .t-badge.green {border-color: #34c38f; color: #34c38f;}
.tooltip.custom-ng-tooltip.green-tt {border-top-color: #34c38f;}
.tooltip.custom-ng-tooltip.yellow-tt {border-top-color: #f1b44c;}
.tooltip.custom-ng-tooltip.red-tt {border-top-color: #f46a6a;}
.green-tt .tooltip.custom-ng-tooltip {border-top-color: #34c38f;}
.yellow-tt .tooltip.custom-ng-tooltip {border-top-color: #f1b44c;}
.red-tt .tooltip.custom-ng-tooltip {border-top-color: #f46a6a;}
.tooltip.custom-ng-tooltip .tooltip-inner {background: transparent;max-width: 100%;color: inherit;border-radius: 0;text-align: left;padding: 0;}
.tooltip-bottom::after {width: auto;height: auto;left: 20px !important;right: auto;border-bottom-color: #fff !important;border-width: 12px !important;border-radius: 2px 2px 0 0;}
.tooltip .arrow {width: 24px;height: 12px;display: none;}

.tooltip.bs-tooltip-top::after {content: '';position: absolute;left: 0;right: 0;bottom: -23px;border: 12px solid transparent;border-top-color: #fff;z-index: -1;width: 24px; margin: 0 auto;}
.tooltip.bs-tooltip-bottom::after {content: '';position: absolute;left: 0;right: 0;top: -23px;border: 12px solid transparent;border-bottom-color: #fff;z-index: -1;width: 24px; margin: 0 auto;}

.onboarding-step-block .steps-left .main-logo img ,.auth-content-box .site-logo > img {width: 155px !important;}
.aside-navbar .site-logo > a {max-width: 139px !important;margin-left: 0;}

.fc-header-toolbar .fc-button-group .fc-prev-button,.fc-header-toolbar .fc-button-group .fc-next-button {width: 32px;height: 32px;background: #fff !important;border: 1px solid #e5e5e5;display: inline-flex;align-items: center;justify-content: center;border-radius: 100% !important;color: #b5b5b5 !important; font-size: 12px;padding: 0;}
.fc-header-toolbar .fc-button-group .fc-prev-button:hover,.fc-header-toolbar .fc-button-group .fc-next-button:hover {color: #1e1e1e !important;border-color: #1e1e1e;}

.fc-header-toolbar .fc-button-group .fc-today-button {font-family: 'Nunito', sans-serif;font-weight: bold;font-size: 16px;line-height: 22px;text-align: center;color: #8e8e8e;background: transparent !important;border: 0;text-transform: capitalize;padding: 0 16px !important;margin: 0 !important;}
.fc-header-toolbar .fc-button-group .fc-today-button:hover {color: #1e1e1e;}

.fc-theme-standard .fc-scrollgrid {border: 0;}
.fc .fc-scrollgrid table.fc-col-header  {margin-bottom: 10px;border-radius: 8px;background: #f5f6f8;}
.fc .fc-scrollgrid table.fc-col-header th {border: 0 !important;}
.fc-theme-standard th {border: 0;}
.fc .fc-scrollgrid table.fc-col-header th {padding: 13px;}
.fc .fc-col-header-cell-cushion {padding: 6px 0;color: #000;font-family: 'Nunito', sans-serif;font-weight: normal;font-size: 16px;line-height: 22px;text-align: center;}
.fc-scrollgrid-sync-table td {border-radius: 0;background: transparent !important;border: 0;padding: 5px;font-family: 'Nunito', sans-serif;font-weight: normal;font-size: 14px;line-height: 20px;text-align: left;color: #000;}
.fc-theme-standard td .fc-daygrid-day-frame {border-radius: 8px;background: #fff;border: 1px solid #e5e5e5;padding: 8px;}
.fc .fc-scrollgrid-section-liquid > td {height: 100%;border: 0;padding: 0}
.fc .fc-scrollgrid-section-body table.fc-scrollgrid-sync-table {border: 0;}
.fc .fc-daygrid-day-top {flex-direction: row;}
.fc .fc-daygrid-day-number {font-family: 'Nunito', sans-serif;font-weight: normal;font-size: 14px;line-height: 20px;text-align: left;color: #000;}
.fc .fc-day-other .fc-daygrid-day-top .fc-daygrid-day-number{color: #b5b5b5;}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame {border-color: #6259CE !important;}
.fc-daygrid-event {font-size: 12px;}
.fc-h-event {background: #6259CE;border-color: #6259CE;}
.fc-daygrid-event-dot {border-color: #6259CE;}

/* Filter panel styles */
.filter-button-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.reset-icon-container {
  width: 24px; /* Set a fixed width for consistency */
}

/* Style the filter button */
.filter-button {
  border: none;
  cursor: pointer;
  background: none;
  font-weight: 700;
  font-size: 14px;
  padding: 5px 8px;
}

.filter-container {
  position: relative;
  display: inline-block;
}

/* Style the floating filter panel */
.filter-panel {
  position: absolute;
  top: 40%;
  left: 0;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 250px;
  margin-top: 10px;
  width: 210px;
}

.filter-item {
  margin-bottom: 15px;
}

.filter-image {
  height: auto;
  width: 50px;
}

.find-button {
  padding: 8px 15px;
  font-size: 14px;
  margin-top: 10px;
  display: block;
  width: 50%;
}

.selected-filter {
  font-size: 14px;
  background-color: rgba(115, 103, 240, 0.10);
  padding: 5px 8px;
  border-radius: 10px;
}

.selected-filter .close-icon {
  cursor: pointer;
}

.ticket-type-dispute {
  color: #fb6a6a;
  /* border: 1px solid #fb6a6a; */
  padding: 3px 10px;
  border-radius: 11px;
  background-color: #fafafa;
}
.ticket-type-query {
  color: #7367f0;
  /* border: 1px solid #7367f0; */
  padding: 3px 10px;
  border-radius: 11px;
  background-color: red;
}

.custom-dialog-modal.opened-programatically {
  margin: 0 auto !important;
  padding: 0 !important;
  /* max-width: 90vw !important; */
  overflow-y: auto !important;
  max-height: 80vh;
}
.new-ticket-tabs .tabs { width: 280px; }
.t-badge.reopen {color: #fff;background: #f1b44c;}

.small-note { color: #8e8e8e; font-size: 14px !important; font-weight: 500 !important; line-height: 16px !important; }

.cst-tooltip-container {
  position: relative; /* Reference point for tooltip positioning */
  display: inline-block;
  cursor: pointer;
}

.cst-tooltip {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 100%; /* Align to the right of the container */
  transform: translateY(-50%); /* Adjust for vertical centering */
  background-color: #333;
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
}

.cst-tooltip-container:hover .cst-tooltip {
  opacity: 1;
  pointer-events: auto;
}

.cst-tooltip-right {
  margin-left: 6px; /* Add spacing between the container and the tooltip */
}

.view-attach-btn {
  color: #6259CE;
}

.remove-item-btn {
  background-color: #ec5453;
  float: right;
}

.attach-col {
  max-width: 200px;
}

.info-icon {
  width: 22px;
}

/* CSS for cancellation option in package details of service */

.package-cancel-row { display: flex; align-items: center; }
.reserved-space { min-height: 60px; display: flex; align-items: center; }
.visibility-hidden { visibility: hidden; }
.full-line { display: block; width: 100%; }
.custom-top { top: -8px; }

.disabled-link {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
}

.disabled-tasks {
  pointer-events: none;
  cursor: not-allowed;
}

/*---Responsive-Media-Query---*/

@media (max-width: 1700px) and (min-width: 1365px) {
    .card-states-box {flex-wrap: wrap; padding: 15px;}
    .card-states-box .states-info .states-digit {width: calc(100% - 100px);}
    .card-states-box .states-graph {width: 100px; display: flex; justify-content: flex-end;}
    .card-states-box .states-info .count span {font-size: 12px;}
    .card-states-box .states-info .count {font-size: 24px; ;line-height: 36px;}
}

@media (min-width: 1600px) {
    .dashboard-account-section .row {margin: 0 -25px;}
    .dashboard-account-section .row > [class*="col-"] {padding: 0 25px;}
    .dashboard-account-section .card-box {margin-bottom: 44px;}
    .project-details-content .row {margin: 0 -25px;}
    .project-details-content .row [class*="col-"] {padding: 0 25px;}

    .wallet-stats-box .row {margin: 0 -36px;}
    .wallet-stats-box .row [class*="col-"] {padding: 0 36px;}

    .shipping-gridrow .row .col-lg-8 {flex: 0 0 73%;max-width: 73%;}
    .shipping-gridrow .row .col-lg-4 {flex: 0 0 27%;max-width: 27%;}
}

@media (min-width: 1200px) and (max-width: 1439px) {
    .sales-analytics-block .col-lg-7, .sales-analytics-block .col-lg-5 {flex: 0 0 50%; max-width: 50%;}
    .sales-reports-block .col-xl-4 {flex: 0 0 50%; max-width: 50%;}

    .crm-reports-block > .row > [class*="col"] {flex: 0 0 100%; max-width: 100%;}
    .crm-reports-block > .row > [class*="col"] .row .col-md-6, .crm-reports-block > .row > [class*="col"] .row .col-md-12 {flex: 0 0 33.33%; max-width: 33.33%;}
    .lead-graph-chart .graphic-report {margin-top: 0;}

    .order-visit-block .col-block h4 {font-size: 18px;}

    .store-analysis-block .row > .col-lg-5 {flex: 0 0 40%; max-width: 40%;}
    .store-analysis-block .row > .col-lg-7 {flex: 0 0 60%; max-width: 60%;}
    .reports-analysis-block .row > .col-xl-5, .reports-analysis-block .row > .col-xl-7 {flex: 0 0 100%; max-width: 100%;}
    .earning-project-block .row > .col-lg-4, .earning-project-block .row > .col-lg-8 {flex: 0 0 100%; max-width: 100%;}
    /* .sort-control.multiple-sort-dropdown {width: calc(100% - 410px);}
    .head-search.head-search-between .head-search-box, .head-search-box-date {max-width: 400px;} */

    .account-dashboard-box .card-box-img {height: 200px;}

    .invoice-billing-row .item-block .item-info {flex: 0 0 100%; max-width: 100%; margin: 10px 0;}

    .ticket-track-block .col-xl-4 {flex: 0 0 40%; max-width: 40%;}
    .ticket-track-block .col-xl-8 {flex: 0 0 60%; max-width: 60%;}
    .ticket-track-block .col-xl-6 {flex: 0 0 100%; max-width: 100%;}

    .ticket-todo-list ul > li .info {width: 100%;}
    .ticket-todo-list ul > li .btn {margin-top: 10px;}
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .promotions-settings .row .col-xl-9 {flex: 0 0 66.66%; max-width: 66.66%;}
    .promotions-settings .row .col-xl-3 {flex: 0 0 33.33%; max-width: 33.33%;}
}

@media (min-width: 1200px) and (max-width: 1365px) {
    .dashboard-stats-block .col-xl-3 {flex: 0 0 50%; max-width: 50%;}
}

@media (max-width: 1700px) {
    .statistic-budget-report .stat-chart-area {padding-right: 15px;}
    .statistic-budget-report .budget-chart-area {padding-left: 15px;}
    .page-content-wrapper {padding-left: 20px; padding-right: 20px;}
    .tracker-reports-graph .chart {margin-top: 0;}
    .sales-analytics-data ul > li .amount {font-size: 22px;}
    .sales-analytics-data ul > li {padding: 0 15px;}
}

@media (max-width: 1640px) {
    .statistic-budget-report .stat-chart-area {width: 75%;}
    .statistic-budget-report .budget-chart-area {width: calc(100% - 75%);}

    .account-settings-gridrow .left-col.profile-left-col, .account-settings-gridrow .left-col { max-width: 30%; flex: 0 0 30%; }
    .account-settings-gridrow .middle-col { max-width: 70%; flex: 0 0 70%; }
    .account-settings-gridrow .last-col {max-width: 70%; flex: 0 0 70%; margin-left: 30%;}
    .preview-col .image-prevcol .image {height: auto;}
    .team-profile-detail .card-box-body { padding: 25px;}
    .team-profile-row .profile-rightcol { padding-left: 30px; }
    .team-profile-row .profile-rightcol .list-content li .icon { margin-right: 15px;}
    .team-profile-row .profile-rightcol .list-content li .content { width: calc(100% - 65px); }
    .team-list li .more-action-dd { right: 0;}

    .earning-reports-graph, .tracker-reports-graph {margin-top: 0;}
    .earning-reports-graph .details {width: 50%; padding-right: 15px; padding-bottom: 0;}
    .earning-reports-graph .chart {width: 50%;}

    .edit-form-row .form-group.publish-date .left-title { width: 110px; }
    .edit-form-row .form-group.publish-date .right-field { width: calc(100% - 110px); }
    .edit-form-row .form-group.publish-date .right-field .date-control-group { max-width: 100%; }

    .switch-control-box {margin-left: -15px; margin-right: -15px;}
    .switch-control-box .switch-control {padding: 0 15px;}
}

@media (max-width: 1600px) {
    .crm-statistics .card-block-box .card-box-body {padding-left: 0; padding-right: 0;}
    .card-states-box .states-info .count {font-size: 24px;}
    .page-content-wrapper {padding-left: 20px; padding-right: 20px;}

    .fc-event-card {padding: 16px;}
}

@media (max-width: 1440px) {
    .page-content-wrapper {padding: 20px;}
    .aside-navbar {width: 275px; padding: 25px;}
    .page-content-wrapper {margin-left: 275px;}
    .aside-navigation-list > ul > li > a, .bottom-nav .logout-link > a {padding: 10px 15px;}
    .aside-navigation-list > ul > li > a .icon, .bottom-nav .logout-link > a .icon {margin-right: 15px;}
    .has-sublist .sublist > li > a {padding: 10px 15px 10px 65px;}
    .has-sublist .sublist > li > a::before {left: 45px;}
    .help-needed {padding-left: 0; padding-right: 0;}

    .auth-form-group .check-fgot-group {margin-bottom: 40px;}
    .forgot-pwd-link {margin: 30px 0;}
    .seprator-title {margin: 30px 0;}

    .product-list-table .table .product-img {width: 40px; height: 40px;}
    .product-list-table .table td {padding: 15px 10px;}
    .product-list-table .table .product-digit .sold-item {padding-left: 10px;}
    .sales-transaction-table .pay-card-img {margin-right: 5px;}
    .custom-table .table thead th, .custom-table .table td {padding: 15px 10px !important; white-space: nowrap;}
    .invoice-transaction-table.custom-table .table td {white-space: wrap;}
    .sales-transaction-table .t-primary-btn {min-width: auto;}
    .earning-stat-info .earning-graph {padding: 0 10px;}
    .best-seller-box .t-primary-btn {min-width: auto;}

    .statistic-budget-report .stat-chart-area {width: 65%;}
    .statistic-budget-report .budget-chart-area {width: calc(100% - 65%);}
    .budget-chart-area .budget-total-amount .total-amount {font-size: 20px;}
    .budget-chart-area .budget-chart-graph {margin: 30px 0;}
    .budget-chart-area .t-primary-btn {margin-bottom: 0;}
    .lead-graph-chart {align-items: center;}
    .lead-graph-chart .graphic-report {padding-left: 15px;}
    .lead-graph-chart .total-growth h1 {font-size: 28px;}
    .traffic-analytics ul {margin: 0 -10px;}
    .traffic-analytics ul > li {flex: 0 0 50%; max-width: 50%; padding: 0 10px;}
    .traffic-analytics ul > li > span {margin-right: 10px;}

    .name-avatar-list p {padding-right: 10px; padding-bottom: 10px;}
    .tracker-reports-graph {margin-bottom: 30px;}
    .earning-report-stats-block {margin: 0 -15px;}
    .earning-report-stats-block .state-box {padding: 0 15px;}
    .tracker-reports-graph {align-items: center;}
    .campaign-report-list .right .earning-stat-info .amount {padding-right: 10px;}

    .date-timerow .date-timecol, .add-coupon-info-modal .date-timerow .date-timecol, .prod-sel-modal .date-timerow .date-timecol { max-width: 50%; }

    .sales-analytics-data ul > li {flex-direction: column; align-items: flex-start; padding: 0 12px; line-height: 24px;}
    .sales-analytics-data ul > li .amount {font-size: 18px;}
    .sales-analytics-data ul > li .amount span {margin-right: 3px;}
    .sales-analytics-data ul > li .data-type {margin-left: 0;}

    .account-application-status .card-block-box .card-title {font-size: 26px;}
    .upload-product-row .upload-box label {min-height: 130px; padding: 30px; font-size: 20px;}
    .bill-info-table .bill-content .right {padding-left: 0;}
    .invoice-transaction-table.custom-table .table tfoot tr > td > table tr th, .invoice-transaction-table.custom-table .table tfoot tr > td > table tr td {padding: 7px 10px !important;}
    .setting-group-block .edit-form-row {padding-left: 30px;}

    .filter-selection {gap: 0 15px;}
    .multiple-checkbox-group .c-checkbox {margin: 0 20px 10px 0;}
    .wallet-stats-box .card-box .card-box-body {padding: 20px;}
    .more-wallet-info {padding: 15px;}

    .shipping-gridrow .row .col-lg-8 {flex: 0 0 100%;max-width: 100%;}

    .order-info-row .title {width: 110px;}
    .order-info-row .content {width: calc(100% - 110px);}

    .product-order-table.custom-table .table td .name-col .image {width: 100px;}
    .product-order-table.custom-table .table td .name-col .content {width: calc(100% - 100px);}

    .card-states-box .states-info .count {white-space: wrap !important;}
}

@media (max-width: 1366px) {
    .card-block-box {padding: 20px;}
    .profile-col { padding: 20px;}
    .t-card-primary .card-box-header {padding-left: 20px; padding-right: 20px;}
    .t-card-primary .card-box-body { padding: 20px; }
    .language-row .language-col { max-width: 45%; }
    .days-group-control .days-select-col { max-width: 140px; }
    .days-group-control > span { padding: 0 15px;}
    .team-profile-detail .card-box-body { padding: 15px; }
    .team-rightcol .card-box-body { padding: 15px; }
    .team-profile-row .profile-imgcol { width: 150px; height: 150px; }
    .team-profile-row .profile-rightcol { padding-left: 15px; width: calc(100% - 150px); }

    .earning-report-stats-block .state-box h3 {font-size: 22px;}

    .uploaded-imagecol .upload-options { font-size: 14px;}
    .uploaded-imagecol .upload-options .image-edit { margin-right: 15px;}

    .application-status-list {padding: 20px; border-radius: 10px;}

    .wallet-balance-block .balance-info h3 {font-size: 20px;}
    .wallet-balance-block .balance-info h1 {margin: 0; font-size: 34px;}

    .shipping-filters-list {margin-left: 15px;}
    .promotions-settings .date-timerow, .addv-detail-section .date-timerow {padding-right: 0;}
}

@media (max-width: 1199px) {
    .navbar-toggler {display: inline-flex;}
    .navigation-bar {margin-bottom: 30px;}
    .aside-navbar {left: -275px;}
    .aside-navbar.mobile-menu-open {left: 0;z-index: 1040;}

    .page-content-wrapper {margin-left: 0; padding: 30px 15px;}
    .notification-list-group .user-type {padding-left: 10px;}

    .aside-navbar-box .site-logo a {max-width: 170px; margin-left: 0;}
    .aside-navbar .aside-navbar-box .close-aside {display: inline-flex;}

    .orders-track-list .order-track-block, .orders-track-list .order-track-block .order-stats-item {margin-bottom: 15px; padding-bottom: 15px;}
    .crm-stat-box {margin-bottom: 15px;}
    .crm-statistics .card-block-box {padding-bottom: 0;}
    .profile-col .profile-img {width: 140px; height: 140px;}

    .team-list-table tr td:last-child {padding-right: 0 !important;}

    .onboarding-step-block .content-right {padding: 30px;}
    .onboarding-step-block .steps-left {padding: 40px;}
    .onboarding-step-block .steps-list ul > li {padding: 20px; margin-bottom: 30px;}
    .onboarding-step-block .steps-list ul > li:after {left: 32px;}

    .ob-step-form, .dashboard-account-section .onboarding-step-block .ob-step-form {padding-left: 0;}

    .account-dashboard-box .card-box-img {height: 200px;}

    .project-details-info .head-info .title-info .content h3 {font-size: 20px;}

    .product-sku-select .control-select {flex: 0 0 50%; max-width: 50%;}

    .reg-acc-list .bank-account-details .pay-request .user-info .name {font-size: 18px;}
    .reg-acc-list .bank-account-details .pay-request .user-info .acc-number {font-size: 16px;}

    .upload-doc-wrapper .doc-info-wrap .doc-info ul > li {padding-right: 7px; margin-right: 7px;}

    .md-drppicker {min-width: 700px;}
    .card-box-table .head-search.head-search-between .head-search-box {max-width: 400px;}
    .invoice-only .invoice-billing-row.full-content .item-block .item-info {flex: 0 0 50% !important; max-width: 50% !important; padding-bottom: 20px;}

    .middle-content-container {max-width: 100%;}

    .md-drppicker.drops-down-right {left: auto !important; right: 0 !important;}
}

@media (min-width: 992px) and (max-width: 1199px) {
    .sales-analytics-block .col-lg-7, .sales-analytics-block .col-lg-5 {flex: 0 0 50%; max-width: 50%;}
}

@media (max-width: 1024px) {
    .onboarding-step-block .steps-left {width: 300px; padding: 30px 20px;}
    .onboarding-step-block .steps-list ul > li > a {padding-left: 35px;}
    .onboarding-step-block .content-right {width: calc(100% - 300px);}
    .ob-step-form .profile-pic .text {padding-right: 15px;}

    .ob-step-form .field-row .left-title {width: 120px;}
    .ob-step-form .field-row .right-fields {width: calc(100% - 120px);}

    .delivery-group-row > .form-group {flex: 0 0 100% !important; max-width: 100% !important;}
}

@media (min-width: 767px) and (max-width: 991px) {
    .sales-analytics-block .col-lg-7, .sales-analytics-block .col-lg-5 {flex: 0 0 100%; max-width: 100%;}
}


@media (max-width: 991px) {
    .auth-content-box .card-box {padding: 30px !important;}
    .onboarding-step-block .uploaded-documents-list > li > .uploaded-doc-info {min-height: auto;}
    .fm-upload-block .upload-files-box {min-height: auto;}

    .navigation-bar {padding: 15px;}
    .navbar-toggler {margin: 0;}
    .head-search-box {margin-left: 10px;}
    .notification-list-group > li {padding: 0 15px;}
    .user-type .avatar-img {width: 40px; height: 40px;}
    .user-type .content .name {font-size: 16px; line-height: 1;}
    .user-type .content {margin-right: 15px;}
    .card-states-box .states-info {margin-bottom: 20px;}
    .crm-statistics .card-block-box .card-box-body {padding-top: 20px;}

    .account-settings-gridrow .left-col.profile-left-col, .account-settings-gridrow .left-col {flex: 0 0 35%; max-width: 35%;}
    .account-settings-gridrow .middle-col {flex: 0 0 65%; max-width: 65%;}
    .account-settings-gridrow .last-col {flex: 0 0 65%; max-width: 65%; margin-left: 35%;}
    .days-group-control .days-select-col { max-width: 110px; }
    .days-group-control > span { padding: 0 10px;}
    .team-rightcol .head-search-box { max-width: 100%; }
    .team-profile-detail .form { max-width: 100%;}
    .image-prevrow .thumbnail-row .add-showcase {position: relative; margin: 10px 0 0 0; top: auto; right: auto;}
    .image-prevrow .thumbnail-row .add-showcase a {width: 35px; height: 35px;}
    /* .sort-control.multiple-sort-dropdown {width: calc(100% - 350px);} */
    .card-box-table .head-search.head-search-between .head-search-box { max-width: 350px; margin: 0;}
    .uploaded-imagecol .uploaded-image { height: auto;}

    .profile-col .profile-img {width: 120px; height: 120px;}
    .profile-col .profile-name h2 {font-size: 26px;}
    .profile-col .profile-name p {font-size: 16px;}
    .profile-col .trn-block h4 {font-size: 18px;}
    .profile-col .trn-block p {font-size: 14px;}

    .image-prevrow .image-prevleft-col .large-image {height: auto;}
    .image-prevrow .image-prevleft-col {width: 100%;}
    .image-prevrow .image-prevright-col {width: 100%; padding: 15px 0 0 0;}

    .price-package-group {flex-wrap: wrap;}
    .price-package-group .form-control {max-width: 100%;}
    .price-package-group .total {max-width: 100%; padding: 10px 0;}
    .price-package-group.total-package {max-width: 100%;}
    .price-package-group .percent-input-group {width: 100%;}
    .price-package-group .percent-input-group .form-control {width: 100%;}
    .price-package-group.total-package {padding: 12px 15px; width: calc(100% - 175px);}
    .price-package-group.total-package .total span {margin-right: 10px;}
    .price-package-group.total-package .total {padding: 0; text-align: left;}

    .card-box-table .head-search.head-search-between {flex-wrap: wrap;}
    .coupon-type-tabs {width: 100%; margin-bottom: 15px; justify-content: space-between;}
    .sort-control.multiple-sort-dropdown {margin-bottom: 15px;}

    .head-search-with-btn {width: 100%; flex: 0 0 100%; max-width: 100%; margin: 15px 0 0 0; justify-content: space-between;}
    .head-search-with-btn .head-search-box {margin: 0;}

    .sales-analytics-data ul > li {padding: 0 10px;}

    .upload-product-row .upload-box {flex: 0 0 100%; max-width: 100%; margin-bottom: 20px;}
    .upload-product-row .upload-box:last-child {margin-bottom: 0;}
    .upload-product-row .upload-box label {min-height: auto;}

    .invoice-billing-row .item-block {width: 100%; padding: 0; margin-bottom: 30px;}
    .invoice-billing-row .barcode-block.qrcode {text-align: left;}
    .invoice-billing-row .item-block .item-info {flex: 0 0 50%; max-width: 50%;}
    .invoice-billing-row .item-block .item-info h4 {margin-bottom: 15px;}
    .invoice-billing-row .item-block .item-info h3 {margin-bottom: 10px;}

    .filter-selection .select-item {flex: 1 !important; max-width: fit-content !important;}
    .filter-record-div .filter-content {padding: 30px;}

    .auth-content-box .password-reset-done {margin: 30px 0;}
    .filter-dd-menu .head-search-box {margin: 0;}
    .report-template-modal .modal-body {padding: 30px;}

    .dropdown-menu.payout-info-box {margin-top: 10px;margin-left: 0;top: 100% !important;right: 0 !important;transform: none !important;left: auto !important;}
    .dropdown-menu.payout-info-box::before {display: none;}

    .head-search-box-date {max-width: 100%; width: 100%;}

    .support-chat-sidebar {width: 100%; min-height: auto; height: auto;}
    .support-chat-block .card-box {min-height: auto;}
    .support-chat-content {width: 100%; margin: 24px 0 0 0;}

    .head-search-filter {margin-bottom: 20px !important;}
    .table-filter-action .justify-content-end {justify-content: flex-start !important;}
}

@media (max-width: 767px) {
    .onboarding-step-block .steps-left .main-logo {margin-bottom: 30px;}
    .onboarding-step-block .steps-left .main-logo > a {max-width: 200px;}
    .onboarding-step-block .steps-left {width: 100%; border-radius: 0 0 20px 20px; padding: 30px 15px;}
    .onboarding-step-block .content-right {width: 100%; padding: 30px 15px;}
    .onboarding-step-block .steps-list ul {display: flex; flex-direction: row; width: 100%;}
    .onboarding-step-block .steps-list ul > li {padding: 15px; align-items: center; margin: 0; flex: 0 0 33.33%; max-width: 33.33%; display: inline-flex;}
    .onboarding-step-block .steps-list ul > li:after {display: none;}
    .onboarding-step-block .top-text {margin-bottom: 30px;}
    .onboarding-step-block .top-text h2 {font-size: 24px; line-height: 30px;}
    .onboarding-step-block .top-text p {font-size: 14px;}

    .aside-navbar {padding: 25px 20px;}
    .aside-navbar-box .site-logo {margin-bottom: 25px;}
    .aside-navigation-list > ul > li > a, .bottom-nav .logout-link > a {font-size: 16px;}
    .aside-navigation-list > ul > li > a .icon, .bottom-nav .logout-link > a .icon {margin-right: 10px;}
    .has-sublist .sublist > li > a::before {width: 8px; height: 8px; margin-top: -4px;}
    .has-sublist .sublist > li > a {font-size: 14px; padding-left: 50px;}
    .has-sublist .sublist > li > a::before {left: 35px;}
    .help-needed .icon {width: 20px; margin-right: 15px;}
    .help-needed .content h5 {font-size: 16px;}
    .help-needed .content p {font-size: 14px;}

    .auth-content-box .card-box .sec-main-title {margin: 20px 0;}
    .auth-form-group .check-fgot-group {margin-bottom: 30px;}
    .forgot-pwd-link {margin: 15px 0 30px 0;}
    .reset-pwd-img {margin-bottom: 30px;}
    .reset-account-block .btn {margin: 30px 0 15px 0;}

    .head-search-box .form-control {height: 45px; padding: 10px 55px 10px 15px;}
    .head-search-box .search-btn {width: 45px;}

    .notification-list-group > li {position: static;}
    .notification-item .notify-menu {min-width: 100%; left: 0; right: 0;}
    .notification-item .notify-menu.open::before {margin: 0 auto; left: 0; right: 0; display: none;}

    .card-block-box .card-title {font-size: 20px !important;}
    .card-block-box .title-desc p {font-size: 14px;}
    .card-states-box .states-info .count {font-size: 24px; line-height: 1.5;}
    .card-states-box {padding: 15px;}
    .card-block-box, .card-box-table {padding: 15px;}

    .card-box-body {padding: 15px;}

    .product-list-table .table td {padding: 10px;}
    .custom-table .table thead th, .custom-table .table td {padding: 12px 10px !important; font-size: 14px;}

    .best-seller-box {min-height: auto;}
    .total-best-sell .amount {font-size: 22px;}
    .crm-stat-box .icon {width: 30px; height: 30px;}
    .crm-stat-box .content {width: calc(100% - 30px); padding-left: 10px;}
    .crm-stat-box .content h4 {font-size: 16px; line-height: 22px;}

    .lead-graph-chart .total-growth h1 {font-size: 20px; line-height: 26px;}
    .statistic-budget-report .stat-chart-area {width: 100%; padding: 0 0 20px 0; margin-bottom: 20px; border-right: 0; border-bottom: 1px solid rgba(75, 70, 92, 0.12);}
    .statistic-budget-report .budget-chart-area {width: 100%; padding: 0;}

    .profit-graph-chart .total-growth h4 {font-size: 16px;}
    .reports-list-item {margin-bottom: 20px;}
    .reports-list-item .left .icon {width: 30px; height: 30px;}
    .reports-list-item .left .content {width: calc(100% - 30px); padding-left: 10px;}
    .earning-stat-info .earning-graph {padding: 0 5px;}

    .table-action {display: block;}
    .table-action > li > a {padding: 0 3px;}
    .table-action > li > a img {height: 12px;}

    .review-generated-graph .wave-chart {position: relative; margin: -15px;}
    .store-analytics-card .card-title {font-size: 24px;}
    .store-analytics-card .card-block-box .card-box-body {padding-top: 20px;}

    .order-visit-block .col-block .title .icon {width: 30px; height: 30px;}
    .order-visit-block .col-block .title h6 {padding-left: 10px;}
    .order-visit-block .col-block h4 {font-size: 18px;}

    .earning-reports-graph .details {width: 100%; padding: 0 0 30px 0;}
    .earning-reports-graph .chart {width: 100%;}
    .earning-reports-graph .details .amount h2, .tracker-reports-graph .details .amount h2 {font-size: 20px;}
    .earning-report-stats-block .state-box {flex: 0 0 100%; max-width: 100%; margin-bottom: 30px;}
    .earning-report-stats-block .state-box:last-child {margin-bottom: 0;}
    .earning-report-stats-block .state-box .state-type {margin-bottom: 10px;}
    .earning-report-stats-block .state-box h3 {font-size: 20px; margin-bottom: 10px;}
    .tracker-reports-graph {flex-wrap: wrap;}
    .tracker-reports-graph .details {width: 100%; padding-right: 0; padding-bottom: 15px;}
    .tracker-reports-graph .chart {margin: 0 auto; text-align: center;}
    .project-table .table td .name-col .content {width: 100%; padding: 10px 0 0 0;}
    .avatar-list > li {width: 30px; height: 30px;}
    .avatar-list > li+li {margin-left: -15px;}

    .page-main-title h2 {font-size: 24px;}
    .account-settings-gridrow .left-col.profile-left-col, .account-settings-gridrow .left-col {max-width: 100%; flex: 0 0 100%; margin-bottom: 30px;}
    .account-settings-gridrow .middle-col {max-width: 100%; flex: 0 0 100%;}
    .account-settings-gridrow .last-col {max-width: 100%; flex: 0 0 100%; margin-left: 0;}
    .t-card-primary .card-box-header {padding: 10px 15px;}
    .t-card-primary .card-box-header .card-title {font-size: 18px;}
    .t-card-primary .card-box-body {padding: 15px;}
    .profile-col .deactivate-col {margin-top: 30px;}
    .vertical-nav ul li > a {padding: 10px 20px;}
    .bottom-btnrow .btn {margin-left: 10px;}

    .new-coupon-block .form { padding-left: 0px; }
    /* .sort-control.multiple-sort-dropdown {width: calc(100% - 300px);} */
    .card-box-table .head-search.head-search-between .head-search-box { max-width: 300px; }

    .package-btnrow > li > a {padding: 10px;}
    .image-prevrow {margin-bottom: 30px;}
    .color-add-block h4 {font-size: 18px; margin-bottom: 15px;}
    .switch-control-box {margin: 30px -10px 0 -10px;}
    .switch-control-box .switch-control {padding: 0 10px;}
    .add-btncol.mt-5 {margin-top: 30px !important;}
    .add-btncol .btn {padding: 10px 15px;}
    .preview-col .content .heading {margin-bottom: 20px;}
    .preview-col .content .heading h3 {font-size: 20px;}

    .process-track-order .order-track-info::before {width: 30px; height: 30px; margin-top: -15px;}
    .process-track-order .order-track-info {padding: 30px 0 0 0;}

    .product-order-table.custom-table .table td .name-col .image {width: 100px; height: auto;}
    .product-order-table.custom-table .total-order {min-width: auto;}

    .crm-transaction-block .sort-control .t-primary-btn {margin-left: 20px;}

    .earn-percentage {margin: 0;}
    .earn-percentage h3 {font-size: 20px;}
    .earn-percentage h3 span {margin-left: 5px;}

    .sales-analytics-data ul > li {padding: 0 15px;}

    .welcome-user-content .card-block-box .card-title {margin-bottom: 15px;}
    .welcome-user-content .card-block-box h4 {font-size: 18px; margin-bottom: 10px;}
    .welcome-user-content .card-block-box .title-desc p {line-height: 1.5;}

    .account-application-status .card-block-box .card-box-header p {font-size: 14px; line-height: 1.5;}
    .application-status-list {padding: 15px; border-radius: 10px;}

    .account-dashboard-box .card-box-img {height: auto;}
    .account-dashboard-box .card-box-body h4 {margin-bottom: 10px;}
    .account-dashboard-box .card-box-body p {font-size: 14px; line-height: 1.5;}

    .fm-upload-block h6 {font-size: 16px;}
    .upload-product-row .upload-box label {padding: 15px; font-size: 16px;}

    .project-details-info .head-info .title-info {width: 100%;}
    .project-details-info .head-info .title-info .content {padding-right: 0;}
    .project-details-info .head-info .date-info {width: 100%; margin-top: 15px;}
    .project-details-info .head-info .date-info [class*="date-"] {text-align: left;}

    .cross-sell-filter-product .btn {min-width: auto;}

    .product-sku-select .control-select {flex: 0 0 100%; max-width: 100%; margin-bottom: 20px;}
    .product-sku-select .control-select:last-child {margin-bottom: 0;}

    .add-new-ticket-block .form-control {padding: 15px 20px;}
    .ticket-chat-thread .thread-block .date, .ticket-chat-thread .thread-block .title {margin-bottom: 15px;}

    .shipping-info-row {margin-bottom: 0;}
    .shipping-info-row .address-info {max-width: 100%; width: auto; margin-bottom: 30px;}
    .shipping-info-row .order-info {max-width: 100%; width: 100%;}

    .shipping-info-row .order-info h2 {font-size: 24px;}
    .shipping-info-row .order-info .order-table {margin: 15px 0 0;}

    .invoice-billing-row .item-block .item-info {flex: 0 0 100%; max-width: 100%; margin: 15px 0;}
    .data-transfer-form {margin: 30px 0 0 0;}
    .setting-group-block .edit-form-row {padding-left: 0;}
    .setting-group-block .setting-title {margin-bottom: 30px;}
    .setting-group-block {padding-bottom: 30px; margin-bottom: 30px;}

    .filter-record-div {margin: 15px 0 30px 0;}
    .filter-record-div .filter-btn {margin: 0;}
    .filter-record-div .filter-content::before {left: 15px; right: auto; top: -7px;}
    .filter-record-div .filter-content {padding: 15px;}
    .filter-selection .select-item {flex: 0 0 100% !important; max-width: 100% !important; margin-bottom: 20px;}
    .filter-selection .select-item:last-child {margin-bottom: 0;}

    .more-wallet-info {margin-bottom: 20px;}
    .more-wallet-info h6 {margin-bottom: 10px;}
    .wallet-balance-block .balance-info h3 {font-size: 18px;}
    .wallet-stats-box .card-box .card-box-body {padding: 15px; margin-bottom: 20px;}
    .wallet-balance-block .balance-info h1 {margin: 0; font-size: 30px; line-height: 40px;}

    .reg-acc-list .bank-account-details {padding: 20px; margin-right: 30px;}
    .reg-acc-list .bank-account-details .bank-logo {margin-bottom: 20px;}
    .reg-acc-list .bank-account-details .pay-request {margin: 0 -20px -20px; padding: 20px;}

    .coupon-type-tabs {margin-bottom: 15px; flex-wrap: wrap;}
    .delivery-time-group .delivery-estimate-field span.to {padding: 0 8px;}

    .profile-enter-new-email {padding: 0;}
    .profile-enter-new-email .otp-form-group {padding: 15px;}

    .date-range-pick {width: 100%; max-width: 100%; flex: 0 0 100%; margin: 15px 0 0 0;}
    .template-btn-group > .t-primary-btn-outline {padding-left: 10px; padding-right: 10px;}
    .filter-dd-menu .list-of-filter-option {padding-left: 0; padding-bottom: 0; padding-right: 0;}
    .filter-dd-menu {right: auto; left: 0; min-width: 315px;}
    .filter-dd-menu::before {left: 16px; right: auto; display: none;}
    .filter-btn-modal .filter-dd-menu::before {left: auto; right: 40px;}

    .filter-btn-modal .filter-dd-menu {left: auto; right: 0;}
    .filter-dd-menu .bottom-btnrow {flex-wrap: wrap;}
    .filter-dd-menu .bottom-btnrow .btn {flex: 0 0 100%; max-width: 100%; margin: 10px 0;}

    .template-checkbox-group {padding: 25px;}
    .template-checkbox-group > ul > li {margin: 10px 0;}
    .template-checkbox-group .c-checkbox, .add-rate-modal .c-radiobox {padding-left: 25px;}

    .t-card-primary .card .card-title {font-size: 16px;}
    .shipping-gridrow .t-card-primary .card {padding: 0; margin-bottom: 30px;}

    .shipping-gridrow .t-card-primary {margin-bottom: 30px;}
    .element-box .element-icon {width: 40px; height: 40px;}
    .element-box .element-content {padding-left: 15px;}

    .page-main-title .btn-back {width: 30px; height: 30px; padding: 7px; margin-right: 5px;}

    .shipping-zone-block .zonal-title .title, .shipping-zone-block .zonal-title .create-zone {font-size: 16px;}

    .add-rate-modal .radio-group {margin-bottom: 30px;}
    .add-rate-modal .c-radiobox {margin-bottom: 15px;}
    .add-rate-modal .add-condition-block .inner-field-box {padding: 0;}
    .add-rate-modal .add-condition-block .inner-field-box .row {margin: 0 -15px;}
    .add-rate-modal .add-condition-block .inner-field-box .row  [class*="col-"] {padding: 0 15px;}

    .prod-sel-modal .new-coupon-block, .add-coupon-info-modal .new-coupon-block .form-rows:last-child .form, .prod-sel-modal .new-coupon-block .form-rows:last-child .form {padding-left: 0;}
    .auth-form-group .form-group .form-control {padding: 9px 15px;}
    .auth-form-group .form-group.form-floating .required-note {padding: 0;}
    .form-floating label {left: 10px; top: 12px;}
    .form-floating .form-control:focus~label, .form-floating .form-control:not(:placeholder-shown)~label, .form-floating .form-select~label {-webkit-transform: translateY(-18px) translateX(0);-moz-transform: translateY(-18px) translateX(0);-ms-transform: translateY(-18px) translateX(0);transform: translateY(-18px) translateX(0);}

    .upload-doc-wrapper .doc-info-wrap {padding-left: 0;}
    .upload-doc-wrapper .doc-info-wrap .attached-item-icon {width: 25px;}
    .upload-doc-wrapper .doc-info-wrap .doc-info {width: calc(100% - 40px);}

    .md-drppicker {min-width: 600px;}
    .md-drppicker .ranges {flex: 0 0 100%; max-width: 100% !important;}
    .md-drppicker .calendar {flex: 0 0 50%; max-width: 50% !important;}

    .config-scope-block .collapse-title {padding: 14px 65px 14px 20px;}
    .config-scope-block .collapse-title h4 {font-size: 18px;}

    .config-scope-block .collapse-content {padding: 20px;}
    .config-selection .selection-mode > a {padding-right: 15px;}
    .config-selection ul > li {padding: 12px;}
    .config-selection .c-checkbox label::before, .config-selection .c-checkbox label::after {width: 18px; height: 18px;}
    .config-selection .c-checkbox label {margin: 0; line-height: 1;}

    .payout-request-body .payout-status {position: relative; top: auto; right: auto; margin-top: 15px; min-width: auto;}

    .dropdown-menu.payout-info-box {left: 0 !important; right: auto !important;}
    .head-search-box-date .head-search-box {max-width: 60% !important; width: 100%;}
    .head-search-box-date .date-range-pick {max-width: 30%; width: 100%; padding-left: 20px; margin: 0 !important;}

    .head-search-filter .label-title {padding-right: 15px;}

    .chat-window-modal .ticket-chat-thread .thread-block {width: 90%;}
    .file-attached-group .file-attached-card {flex: 0 0 144px; max-width: 144px; width: 100%;}
}

@media (max-width: 639px) {
    .navigation-bar .head-search-box {display: none;}

    .card-box-table .head-search.head-search-between { display: block;}
    .card-box-table .head-search.head-search-between .head-search-box { max-width: 100%; }
    .sort-control.purple-dropdown { max-width: 200px; margin-bottom: 15px; }

    .crm-transaction-block .sort-control {width: 100%; flex-wrap: nowrap; margin-bottom: 15px;}

    .coupon-type-tabs {width: 100%; margin-bottom: 15px; padding: 0;}

    .date-range-pick {flex: 0 0 100%; max-width: 100%; margin-top: 15px; margin-left: 0; display: flex; flex-wrap: wrap;}
    .md-drppicker::before {display: none;}
    .md-drppicker {min-width: 100%; left: 0 !important; right: 0 !important; overflow: hidden;}
    .md-drppicker .ranges {flex: 0 0 100%; max-width: 100% !important; padding: 20px 20px 0 20px;}
    .md-drppicker .calendar {flex: 0 0 100%; max-width: 100% !important;}
    .md-drppicker .calendar-table {overflow-x: scroll; width: 100%;}

    .table-filter-action ul > li {flex: 0 0 100%; padding-bottom: 15px; padding-right: 0; max-width: 100%;}
    .table-filter-action .btn {min-width: 100%;}

    .sort-control.multiple-sort-dropdown {max-width: 100%; width: 100%;}

    .invoice-only .invoice-billing-row.full-content .item-block .item-info {flex: 0 0 100% !important; max-width: 100% !important; padding-bottom: 0;}
}

@media (max-width: 575px) {
    .auth-content-box .card-box .sec-main-title {font-size: 22px;}
    .verify-email-block p {margin-bottom: 30px;}
    .otp-form-group {margin-bottom: 30px;}
    .otp-input-control, .otp-verify-modal .otp-input-control {gap: 0 10px;}
    .otp-input-control .form-control {width: 45px; height: 45px; font-size: 18px;}
    .onboarding-step-block .steps-list ul {flex-wrap: wrap;}
    .onboarding-step-block .steps-list ul > li {padding: 10px; border-radius: 5px;}
    .onboarding-step-block .steps-list ul > li > a {font-size: 16px; padding-left: 30px;}
    .onboarding-step-block .steps-list ul > li > span {width: 21px; height: 21px; font-size: 14px;}
    .onboarding-step-block .steps-list ul > li > span:before {width: 17px; height: 17px;}
    .submit-row.bottom-btnrow {margin-top: 30px;}
    .submit-row.bottom-btnrow .btn {margin-left: 15px;}

    .ob-step-form .field-row .right-fields .field-two-col .form-group {flex: 0 0 100%; max-width: 100%;}
    .ob-step-form .field-row .right-fields .field-two-col .form-group {margin-bottom: 15px;}
    .ob-step-form .field-row .right-fields .field-two-col .form-group:last-child {margin-bottom: 0;}

    .ob-step-form .field-row {margin-bottom: 20px;}
    .ob-step-form .field-row .left-title {width: 100%; margin-bottom: 10px;}
    .ob-step-form .field-row .right-fields {width: 100%;}
    .ob-step-form .profile-pic .text {width: 100%; margin-bottom: 15px; padding-right: 0;}
    .ob-step-form .profile-pic .upload-view {width: 100%;}
    .ob-step-form .profile-pic .upload-view .profile-picture {width: 100px; height: 100px;}
    .ob-step-form .profile-pic .upload-view .profile-picture .profile-edit {right: 0;}
    .ob-step-form .profile-pic .drag-box {width: 100%;}
    .ob-step-form .profile-pic .text .btn-action-group {right: 0; position: relative; display: flex; justify-content: flex-end; margin: 15px 0 0;}

    .page-content-wrapper {padding: 15px;}
    .notification-badge {width: 10px; height: 10px;}
    .notification-item .notification-badge {margin: -5px}
    .best-seller-box .total-best-sell {flex-direction: column; justify-content: flex-start; align-items: flex-start;}
    .total-best-sell .amount {margin-bottom: 15px;}
    .edit-form-row .form-group .left-title, .edit-form-row .form-group.large .left-title, .data-transfer-form .edit-form-row .form-group .left-title {width: 100%; margin: 0 0 8px 0;}
    .edit-form-row .form-group .right-field, .edit-form-row .form-group.large .right-field, .data-transfer-form .edit-form-row .form-group .right-field {width: 100%;}
    .edit-form-row .form-group .right-field .date-control-group {max-width: 100%; }
    .edit-form-row .form-group .right-field .socials-right {padding-right: 45px; }
    .language-row {flex-wrap: wrap;}
    .language-row .language-col {max-width: 100%; }
    .language-row .del-lang {margin: 0 15px 15px 15px !important; background: #EC5453; border-radius: 10px;}
    .language-row .del-lang img { padding: 8px; filter: brightness(0) invert(1);}
    .days-group-control .days-select-col {max-width: 45%;}
    .days-group-control > span {width: 10%; text-align: center; padding: 0;}
    .team-profile-row .profile-imgcol {margin: 0 auto 15px auto;}
    .team-profile-row .profile-rightcol {width: 100%; padding: 0;}
    .team-profile-row .profile-rightcol .content {margin-bottom: 20px; text-align: center;}
    .team-profile-row .profile-rightcol .content h3 {margin-bottom: 15px;}
    .team-profile-row .profile-rightcol .list-content {display: flex; flex-wrap: wrap; }
    .team-profile-row .profile-rightcol .list-content li {width: 50%; margin: 0;}
    .team-profile-row .profile-rightcol .list-content li .content {margin-bottom: 0; text-align: left; }
    .team-profile-detail .form .form-group .left-title {width: 100%; margin: 0 0 8px 0; }
    .team-profile-detail .form .form-group .right-field {width: 100%; }

    .traffic-analytics ul > li {flex: 0 0 100%; max-width: 100%;}
    .edit-form-row .form-group .right-field .gender-row {margin-top: 0;}

    .custom-modal .modal-header {padding: 15px;}
    .custom-modal .modal-body {padding: 20px 15px;}
    .custom-modal .modal-header h4 {font-size: 16px;}
    .add-teammate-modal .form .form-group .form-control {padding: 15px;}
    .add-teammate-modal .form .form-group .select2-container--default .select2-selection--single .select2-selection__rendered {padding-left: 15px;}

    .add-package-modal .package-form-row {padding: 0;}

    .new-coupon-block .form .form-group .left-title { width: 100%; margin: 0 0 8px 0; }
    .new-coupon-block .form .form-group .right-field { width: 100%; }
    .new-coupon-block .form .form-group .enable-check .right-field { width: auto;}
    .edit-form-row .form-group.publish-date .left-title,
    .edit-form-row .form-group.publish-date .right-field { width: 100%; }

    .page-main-title {flex-direction: column; align-items: flex-start;}
    .page-main-title h2 {padding-right: 0;}
    .multiple-links {margin-top: 15px; flex-wrap: nowrap; gap: 0 10px; width: 100%;}
    .multiple-links .btn {margin: 0; flex: 0 0 50%; max-width: 50%;}
    .t-primary-btn.t-primary-btn-small img {margin-right: 5px;}
    .edit-form-row .form-group textarea.form-control {height: auto;}

    .package-btnrow > li {flex: 0 0 100%; max-width: 100%; margin-bottom: 15px;}
    .package-btnrow > li:last-child {margin-bottom: 0;}

    .switch-control-box .switch-control {flex: 0 0 100%; max-width: 100%; margin-bottom: 20px;}
    .switch-control-box .switch-control:last-child {margin-bottom: 0;}
    .image-prevrow .thumbnail-row ul li {flex: 0 0 50%; max-width: 50%;}
    .image-prevrow .thumbnail-row ul li .thumbnail {height: auto;}

    .price-package-group.total-package {width: 100%; border-radius: 10px;}
    .currency-input-group, .days-group-control .currency-input-group {max-width: 100%;}
    .days-group-control .currency-input-group {margin-left: 15px;}

    .colour-details-row .relevent-img {height: auto;}

    .sort-control.multiple-sort-dropdown .sortby-name {margin-right: 0; margin-bottom: 15px;}
    .sort-control.multiple-sort-dropdown .dropdown-row {margin: 0 -10px;}
    .sort-control.multiple-sort-dropdown .dropdown-row .dropdown-col {width: 50%; padding: 0 10px;}
    .sort-control.multiple-sort-dropdown .dropdown-row .dropdown-col.category-col {width: 100%;}

    .card-box-header {flex-direction: column; align-items: flex-start;}
    .card-box-header .card-title {padding-right: 0; padding-bottom: 10px;}

    .process-track-order .order-track-info::before {width: 26px; height: 26px; margin-top: -13px;}
    .process-track-order > li::after, .process-track-order > li::before {height: 2px;}
    .process-track-order > li.active .order-track-info h6, .process-track-order > li.completed .order-track-info h6 {font-size: 14px;}

    .order-info-block .order-info-box .image {width: 80px; height: auto;}
    .order-info-block .order-info-box .content {width: calc(100% - 80px); padding-left: 15px;}
    .order-info-row .title {width: 25%;}
    .order-info-row .content {width: calc(100% - 25%);}

    .onboarding-step-block .drag-box.error .require-note {margin: 5px 0 10px 0;}

    .task-progress-info .progress-info {width: 100%; padding-right: 0; margin-bottom: 15px;}

    .request-invoice-ticket .head-info .personal-info {width: 100%; padding-bottom: 15px;}
    .request-invoice-ticket .head-info .ordernumber {width: 100%; display: block;}
    .request-invoice-ticket .head-info .personal-info > ul > li {padding: 0; margin: 0 0 10px 0; display: flex;}
    .request-invoice-ticket .head-info .personal-info > ul > li::after {display: none;}

    .request-invoice-ticket .head-info .personal-info .amount-paid strong {margin: 0;}

    .data-transfer-form .edit-form-row .form-group .sm-control {max-width: 100%;}

    .page-main-title.title-breadcrumbs .icon-seprator {width: 20px;}

    .wallet-balance-block .balance-info {width: 100%; padding: 0 0 20px 0;}
    .wallet-balance-block .balance-info h3 {font-size: 16px; line-height: 22px;}
    .wallet-balance-block .balance-info h1 {font-size: 22px; line-height: 34px;}
    .wallet-balance-block .balance-info h1 .currency {font-size: 22px; margin-right: 5px;}
    .wallet-balance-block .payout-request {width: 100%;}
    .reg-acc-list .bank-account-details {flex: 0 0 100%;max-width: 100%;}

    .reg-acc-list .bank-account-details .pay-request .user-info {width: 100%; padding: 0 0 20px 0;}
    .reg-acc-list .bank-account-details .pay-request .pay-request-btn {width: 100%;}

    .beneficiary-account-details {padding: 15px;}
    .beneficiary-account-details .account-info {padding: 0;}
    .beneficiary-account-details .account-info ul > li {flex-wrap: wrap;}
    .beneficiary-account-details .account-info ul > li .left-label {margin: 0 0 5px 0; width: 100%;}
    .beneficiary-account-details .account-info ul > li .right-label {margin: 0; width: 100%;}

    .varient-type-group .varient-info {width: 100%; padding-right: 0; margin-bottom: 15px;}
    .coupon-type-tabs .shipping-filters-list {width: 100%;flex: 0 0 100%;max-width: 100%; margin-top: 15px; margin-left: 0;}
    .shipping-filters-list ul > li {flex: 1; display: block;}

    .report-template-modal .modal-body {padding: 20px 15px;}

    .template-checkbox-group {padding: 20px;}
    .template-checkbox-group > ul {max-height: 350px; overflow-y: auto;}
    .template-checkbox-group > ul > li {flex: 0 0 50%; max-width: 50%;}

    .upload-group-new .profile-picture {width: 100px;height: 100%;margin: 0 auto 15px auto;}
    .upload-group-new
    .upload-group-new .upload-area {padding: 0;width: 100%;}

    .profile-enter-new-email .bottom-btnrow .btn, .otp-verify-modal .bottom-btnrow .btn {min-width: auto;}
    .head-search-box-date  {flex-wrap: wrap;}
    .head-search-box-date .head-search-box {max-width: 100% !important; width: 100%;}
    .head-search-box-date .date-range-pick {max-width: auto; width: auto; padding-left: 0; margin: 20px 0 0 0 !important;}

    .notifications-list-section .page-main-title {margin-bottom: 30px;}
    .notification-filter ul.tabs > li {margin-right: 5px;}
    .notification-filter ul.tabs > li > a {padding: 6px 10px; font-size: 14px;}

    .support-chat-content .card-box-header {padding: 20px;}
    .assigned-ticket {margin-top: 10px;}

    .sup-ticket-info .content .img-icon {width: 26px; height: 26px;}
    .sup-ticket-info .content .content-info {width: calc(100% - 26px);}

    .chatbox-chat-group .chatbox-card, .chatbox-chat-group .chatbox-card {max-width: 90%;}
    .chatbox-chat-group .chatbox-card .avatar-icon {width: 26px; height: 26px;}
    .chatbox-card .request-box {padding: 15px; gap: 15px 0;}
    .chatbox-chat-group .chatbox-card .chatbox-body {padding-left: 42px;}
    .chatbox-chat-group .chatbox-card.sender .chatbox-body {padding-right: 42px;}

    .reply-control-box .reply-control {padding: 12px 55px; height: 50px;}
    .reply-control-box .send-btn, .reply-control-box .add-items {width: 30px; height: 30px; margin-top: -15px;}
    .reply-control-box .send-btn img, .reply-control-box .add-items img {width: 16px;}

    .head-search-filter {flex-wrap: wrap;}
    .head-search-filter .filter-tag > li .filter-item .name {padding-right: 10px; width: calc(100% - 10px);}
    .sort-control.multiple-sort-dropdown {flex-wrap: wrap;}
    .sort-control.multiple-sort-dropdown .dropdown-row {flex-wrap: wrap;}

    .fc .fc-scrollgrid table.fc-col-header th {padding: 5px;}
    .fc-scrollgrid-sync-table td {padding: 0;}
    .fc-theme-standard td .fc-daygrid-day-frame {padding: 0;}

    .fc-event-card .name-info {margin-bottom: 16px;}

    .fc-custom-month-selection {position: relative; margin-bottom: 15px;}
    .fc .fc-toolbar {justify-content: flex-start;}
}

@media (max-width: 479px) {
    .onboarding-step-block .steps-list ul > li {flex: 0 0 100%; max-width: 100%; margin-bottom: 10px;}
    .notification-list-group > li {padding: 0 10px;}
    .notification-list-group > li.notification-item > a img {max-height: 20px; max-width: 20px;}
    .notification-item .notification-badge {margin: -3px !important;}

    .notification-item > a.active::after, .notification-item.email a.active::after {width: 35px; height: 35px; top: -8px; left: -8px;}

    .user-type .content {margin-right: 10px;}
    .user-type .content .name {font-size: 14px;}
    .user-type .content .acc-type {font-size: 12px;}
    .user-type .avatar-img {width: 35px; height: 35px;}

    .team-profile-row .profile-rightcol .list-content li {width: 100%; margin-bottom: 15px; }

    .dimension-input-group {gap: 0 10px;}
    .head-search-with-btn {flex-wrap: wrap; justify-content: flex-start;}
    .head-search-with-btn .head-search-box {margin-bottom: 15px;}
    .head-search-with-btn .t-primary-btn-small {margin: 0;}

    .order-info-block .order-info-box .image {width: 100%; height: auto;}
    .order-info-block .order-info-box .content {width: 100%; padding-left: 0; padding-top: 15px;}
    .order-info-row {margin-bottom: 15px;}
    .order-info-row .title  {width: 100%;}
    .order-info-row .content {width: 100%; padding-left: 0; padding-top: 15px;}

    .sales-analytics-data ul {flex-direction: column; align-items: flex-start;}
    .sales-analytics-data ul > li {padding: 10px 0; width: 100%;}
    .sales-analytics-data ul > li::after {width: 100%; height: 1px; left: 0; right: 0; bottom: 0; top: auto;}
    .sales-analytics-data ul > li:first-child {padding-top: 0;}

    .sales-analytics-data ul > li .amount {font-size: 18px;}

    .dimension-input-group {flex-wrap: wrap;}
    .dimension-input-group .measurement-field-control {width: 100%; max-width: 100%; flex: 0 0 100%; margin-bottom: 15px;}
    .dimension-input-group .measurement-field-control:last-child {margin-bottom: 0;}

    .date-timerow .date-timecol, .add-coupon-info-modal .date-timerow .date-timecol, .prod-sel-modal .date-timerow .date-timecol, .promotions-settings .date-timerow .date-timecol, .addv-detail-section .date-timerow .date-timecol {flex: 0 0 100%;max-width: 100%;}
    .date-timerow .date-control-group {margin-bottom: 15px;}

    .select-varient-filter ul {flex: 0 0 100%; max-width: 100%; margin-bottom: 10px;}

    .project-details-info .head-info .title-info .image {width: 45px; height: 45px;}
    .project-details-info .head-info .title-info .content {padding-left: 15px; width: calc(100% - 45px);}
    .project-details-info .head-info .title-info .content h3 {font-size: 16px; line-height: 24px;}

    /* .filter-dd-menu {min-width: 100%;} */
    .filter-btn-modal .filter-dd-menu::before {left: auto;right: 10px;}

    .date-range-pick {justify-content: space-between;}

    .dropdown-menu.payout-info-box {min-width: 300px;}
    .payout-info-box .account-info {padding: 15px;}
    .payout-info-box .account-info ul > li {flex-wrap: wrap;}
    .payout-info-box .account-info ul > li .left-label {flex: 0 0 100%; min-width: 100%; max-width: 100%;}
    .payout-info-box .account-info ul > li .right-content {flex: 0 0 100%; max-width: 100%;}
    .payout-info-box .request-note {flex-direction: column; align-items: flex-start;}

    .notification-info {flex-wrap: wrap; padding: 15px 0;}
    .notification-info .details {width: 100%;}
    .notification-info .details > .img-icon {width: 30px; height: 30px;}
    .notification-info .details > .content {width: calc(100% - 30px); padding-left: 10px;}
    .notification-info .details > .content > p {font-size: 14px;}
    .notification-info .time-stamp {font-size: 12px; width: 100%; padding: 15px 0 0 0; text-align: left;}

    .chat-window-modal .ticket-chat-thread .thread-block {padding: 15px;}
}

@media (max-width: 375px) {
    .otp-form-group {padding: 0;}

    .type-info-control .btn  {width: 100%; margin: 0 0 15px 0;}
    .type-info-control .btn:last-child {margin-bottom: 0;}

    .shipping-info-row .order-info .order-table .order-content .left, .bill-info-table .bill-content .left {width: 100%;}
    .shipping-info-row .order-info .order-table .order-content .right, .bill-info-table .bill-content .right {width: 100%;}

    .delivery-time-group .delivery-estimate-field .estimate-field {max-width: 100%; width: 100%;}
    .template-btn-group {flex: 0 0 100%; max-width: 100%; margin: 0 0 15px 0; display: block; position: relative;}
    .template-btn-group > .btn {width: 100%;}

    .template-checkbox-group > ul > li {flex: 0 0 100%; max-width: 100%;}

    .filter-dd-menu {min-width: 300px;}

    .dropdown-menu.payout-info-box {min-width: 250px;}
    .payout-info-box .payout-info-detail .payout-title {flex: 0 0 100%; width: 100%; padding: 0 0 10px 0;}
}
